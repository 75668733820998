import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { isArrayNotEmpty } from '@nl/lib'
import { RootState } from '../../redux/reducers'
import { getProvider } from '../providers'
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { clearInteraction } from '../../redux/actions'

export const usePageAnalytics = () => {
    const analyticsProvider = getProvider()
    const commonPageEvents = useSelector((state: RootState) => state.analytics?.commonPageEvents)
    const dispatch = useDispatch()
    useEffect(() => {
        if (isArrayNotEmpty(commonPageEvents)) {
            commonPageEvents.forEach(analyticsEvent =>
                analyticsProvider.push(analyticsEvent as unknown as AnalyticsRecordInterface),
            )
            dispatch(clearInteraction())
        }
    }, [commonPageEvents, dispatch, analyticsProvider])
}
