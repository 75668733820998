import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { checkDataLength } from '@nl/lib'
import brazeService from '../../services/brazeService/braze.service'
import { BrazeInitType } from './PageInit.type'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'

/** BrazeInit to initiate Braze Web SDK, to track a user between App and mobile browser, permitting us to allow continuity between experiences.
 * @param {BrazeInitType} props
 * @return {null}
 */
const BrazeInit: React.FC<BrazeInitType> = ({ ...props }) => {
    const { apiKey, sdkEndpointUrl } = props
    const userProfileData = useSelector(userProfileDataSelector)
    const isLoggedIn = checkDataLength(userProfileData)
    const loyaltyId = userProfileData?.loyalty?.id || ''

    /**
     * useEffect to initialize braze web sdk
     */
    useEffect(() => {
        if (apiKey && sdkEndpointUrl) {
            // initialize the SDK
            brazeService.init(apiKey, sdkEndpointUrl)
        }
    }, [apiKey, sdkEndpointUrl])

    /**
     *  optionally show all in-app messages without custom handling
     */
    useEffect(() => {
        window?.braze?.automaticallyShowInAppMessages()
    })

    /**
     * useEffect to set logged in user id
     */
    useEffect(() => {
        // get current stored user id
        const currentUserId = brazeService.getUserId()
        if (isLoggedIn && window?.braze && currentUserId !== loyaltyId) {
            // set logged in user ID
            window?.braze?.changeUser(loyaltyId)
        } else {
            window?.braze?.changeUser(currentUserId)
        }
    }, [isLoggedIn, loyaltyId])

    // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
    useEffect(() => {
        window?.braze?.openSession()
    })

    return null
}

BrazeInit.propTypes = {
    apiKey: PropTypes.string.isRequired,
    sdkEndpointUrl: PropTypes.string.isRequired,
}

export default BrazeInit
export { BrazeInit }
