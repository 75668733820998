export const FETCH_PRODUCT_DETAILS_REQUEST_SUCCESS = 'FETCH_PRODUCT_DETAILS_REQUEST_SUCCESS'
export const FETCH_PRODUCT_DETAILS_REQUEST_FAILURE = 'FETCH_PRODUCT_DETAILS_REQUEST_FAILURE'
export const FETCH_PRODUCT_SKU_DATA = 'FETCH_PRODUCT_SKU_DATA'
export const SET_SELECTED_VARIANT = 'SET_SELECTED_VARIANT'
export const SET_SELECTED_FIRST_VARIANT = 'SET_SELECTED_FIRST_VARIANT'
export const SET_SELECTED_SECOND_VARIANT = 'SET_SELECTED_SECOND_VARIANT'
export const SET_SELECTED_PRODUCT_CODE = 'SET_SELECTED_PRODUCT_CODE'
export const SET_SELECTED_CURRENT_PRICE = 'SET_SELECTED_CURRENT_PRICE'
export const SET_SELECTED_THIRD_VARIANT = 'SET_SELECTED_THIRD_VARIANT'
export const SET_SELECTED_QUANTITY = 'SET_SELECTED_QUANTITY'
export const SET_SELECTED_IMAGE_URL = 'SET_SELECTED_IMAGE_URL'
export const SET_SELECTED_VARIANT_ID = 'SET_SELECTED_VARIANT_ID'
export const SET_SELECTED_FIRST_VARIANT_ID = 'SET_SELECTED_FIRST_VARIANT_ID'
export const SET_SELECTED_SECOND_VARIANT_ID = 'SET_SELECTED_SECOND_VARIANT_ID'
export const SET_SELECTED_THIRD_VARIANT_ID = 'SET_SELECTED_THIRD_VARIANT_ID'
export const SET_STICKY_BUYBOX_CTA_CLICKED = 'SET_STICKY_BUYBOX_CTA_CLICKED'
export const TOGGLE_ADD_TO_CART_CTA = 'TOGGLE_ADD_TO_CART_CTA'
export const SET_IF_ALL_VARIANT_SELECTED = 'SET_IF_ALL_VARIANT_SELECTED'
export const SET_FBT_PRODUCT_CODE = 'SET_FBT_PRODUCT_CODE'
export const SET_BUY_BOX_PROPS = 'SET_BUY_BOX_PROPS'
export const SET_OUT_OF_STOCK = 'SET_OUT_OF_STOCK'
export const RESET_PROD_SKU_DATA = 'RESET_PROD_SKU_DATA'
export const SET_REAR_SELECTED_QUANTITY = 'SET_REAR_SELECTED_QUANTITY'
export const SET_FRONT_SELECTED_QUANTITY = 'SET_FRONT_SELECTED_QUANTITY'
export const FETCH_FBT_PRODUCT_SKU_DATA = 'FETCH_FBT_PRODUCT_SKU_DATA'
export const FETCH_VARIANTS_PRODUCT_SKU_DATA = 'FETCH_VARIANTS_PRODUCT_SKU_DATA'
export const FETCH_PRODUCT_SKU_REQUEST_FAILURE = 'FETCH_PRODUCT_SKU_REQUEST_FAILURE'
export const FETCH_FBT_PRODUCT_SKU_REQUEST_FAILURE = 'FETCH_FBT_PRODUCT_SKU_REQUEST_FAILURE'
export const FETCH_VARIANTS_PRODUCT_SKU_REQUEST_FAILURE = 'FETCH_VARIANTS_PRODUCT_SKU_REQUEST_FAILURE'
export const SET_TIRE_OR_WHEEL_SHOP_WITH_NO_VEHICLE = 'SET_TIRE_OR_WHEEL_SHOP_WITH_NO_VEHICLE'
export const SET_SKU_PRESENT_IN_URL = 'SET_SKU_PRESENT_IN_URL'
export const SET_OUT_OF_STOCK_IN_CURRENT_STORE = 'SET_OUT_OF_STOCK_IN_CURRENT_STORE'
export const SET_IS_PRODUCT_DATA_AVAILABLE = 'SET_IS_PRODUCT_DATA_AVAILABLE'
export const SET_IS_PRICE_AVAILABILITY_API_DONE = 'SET_IS_PRICE_AVAILABILITY_API_DONE'
export const SET_IS_CART_FLYOUT_OPEN = 'SET_IS_CART_FLYOUT_OPEN'
export const SKU_NOT_FOUND = 'SKU_NOT_FOUND'
export const SET_IS_VEHICLE_CHANGED = 'SET_IS_VEHICLE_CHANGED'
export const FETCH_SIZE_CHART_DATA = 'FETCH_SIZE_CHART_DATA'
export const FETCH_SIZE_CHART_DATA_REQUEST_FAILURE = 'FETCH_SIZE_CHART_DATA_REQUEST_FAILURE'
export const SET_BRAND_LIST_FETCH_SUCCESS = 'SET_BRAND_LIST_FETCH_SUCCESS'
export const SET_SELECTED_PART_NUMBER = 'SET_SELECTED_PART_NUMBER'
export const RESET_SELECTED_PART_NUMBER = 'RESET_SELECTED_PART_NUMBER'
export const SET_PRODUCT_LIST_AB_TEST = 'SET_PRODUCT_LIST_AB_TEST'
export const SET_IS_HELIUM_INFLATION_SELECTED = 'SET_IS_HELIUM_INFLATION_SELECTED'
export const SET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS_LOADING =
    'SET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS_LOADING'
export const SET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS_DATA = 'SET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS_DATA'
export const SET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS_ERROR = 'SET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS_ERROR'
export const RESET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS = 'RESET_APPOINTMENT_AVAILABILITY_DATE_TIME_SLOTS'
export const SET_HELIUM_INFLATION_GLOBAL_TOGGLE = 'SET_HELIUM_INFLATION_GLOBAL_TOGGLE'
