import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CertonaInitialization } from '../../certona/certona.service'
import { CertonaResponseType } from '../../certona/certona.type'
import { pageTypes } from '../../config'
import { storeCertonaRecommendationData } from '../../redux/actions'
import appCacheService from '../../utils/appCacheService'
import getPageType from '../../utils/getPageType'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { getCategoriesFromURL } from '../../utils/PLP/getCategoriesFromURL'
import { getOrderDetailsForCertona } from '../../utils/Certona/getOrderDetailsForCertona'
import { retrieveProductCode } from '../../utils/Certona/retrieveProductCode'
import { getCategoriesList } from '../Vehicles/Vehicle.helper'
import { MagicNumber } from '../../analytics/analytics.type'
import { FilteredCartData } from '../../redux/models/cart.interface'
import {
    isAuthFlowExecutedSelector,
    userProfileDataSelector,
    signOutSuccessSelector,
} from '../../redux/selectors/userProfile.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { orderDetailsDataSelector } from '../../redux/selectors/orderDetails.selectors'
import { productSelector } from '../../redux/selectors/product.selectors'
import { cartItemsDataSelector } from '../../redux/selectors/cart.selectors'
import { wishlistItemsSelector } from '../../redux/selectors/wishlist.selectors'
import { categoryApiCompletedSelector, categoryIdDataSelector } from '../../redux/selectors/categoryIdData.selectors'
import { productCardDataSelector } from '../../redux/selectors/productData.selectors'
import { docLoadStatusSelector } from '../../redux/selectors/docLoadStatus.selectors'
import { orderSelector } from '../../redux/selectors/orderItemsDetails.selectors'
import { isGigyaLoadedSelector } from '../../redux/selectors/gigyaScreenSetData.selectors'

export const CertonaInit: React.FC = () => {
    const dispatch = useDispatch()
    const { productData, isOOSCurrentStore, isProductDataAvailable, productSkuData } = useSelector(productSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const isGigyaLoaded = useSelector(isGigyaLoadedSelector)
    const signOutSuccess = useSelector(signOutSuccessSelector)
    const cartItemsData = useSelector(cartItemsDataSelector)
    const wishlistItems = useSelector(wishlistItemsSelector)
    const order = useSelector(orderSelector)
    const productCardData = useSelector(productCardDataSelector)
    const categoryData = useSelector(categoryIdDataSelector)
    const categoryApiCompleted = useSelector(categoryApiCompletedSelector)
    const currentPageType = getPageType()
    const docLoadStatus = useSelector(docLoadStatusSelector)
    const orderDetailsData = useSelector(orderDetailsDataSelector)
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)

    const certonaCallRef = useRef(0)

    const customerId = useMemo(
        () => (checkDataLength(userProfileData) ? userProfileData?.loyalty?.id : ''),
        [userProfileData],
    )

    /**
     * Callback for the certona response.
     */
    const storeCertonaData = useCallback(
        (data: CertonaResponseType) => {
            docLoadStatus && dispatch(storeCertonaRecommendationData(data))
        },
        [dispatch, docLoadStatus],
    )

    const categoryPageConditions = useCallback(
        (categoryPages: string[]): boolean => {
            return categoryPages.includes(currentPageType) && checkDataLength(categoryData)
        },
        [categoryData, currentPageType],
    )

    // useEffect to call certoana on store change
    useEffect(() => {
        if (selectedPreferredStoreId) {
            certonaCallRef.current = MagicNumber.ZERO
        }
    }, [selectedPreferredStoreId])

    // call certona only once when  certona object is ready
    const triggerRestrictedCertona = useCallback(() => {
        certonaCallRef.current = MagicNumber.ONE
        CertonaInitialization.triggerCertona()
    }, [])

    /*
     * trigger the recommendation if the user logs in with customer id or logs out
     * customerid attribute is only applicable to specific page types pdp/account/home/wishlist/cart
     */
    useEffect(() => {
        if (window.certona && window.certona.customerid !== customerId) {
            window.certona = { ...window.certona, customerid: customerId }
            triggerRestrictedCertona()
        }
    }, [customerId, triggerRestrictedCertona])

    /*
     * This is needed for certona to work correctly for empty scenarios
     * window.onload is only been used to initialize the certona object and does not impact component rendering
     * It is a workaround since there's no initial state for guest user in wishlist
     */
    useEffect(() => {
        const { wishlist, cart } = pageTypes
        switch (currentPageType) {
            case wishlist:
                if (checkDataLength(wishlistItems.products)) {
                    CertonaInitialization.init(storeCertonaData, {
                        ItemId: retrieveProductCode(wishlistItems.products, true),
                        customerId: customerId,
                    })
                    triggerRestrictedCertona()
                }
                break
            case cart:
                if (checkDataLength(cartItemsData.cart)) {
                    CertonaInitialization.init(storeCertonaData, {
                        ItemId: retrieveProductCode(cartItemsData.cart?.orderEntries, true),
                        customerId: customerId,
                    })
                    triggerRestrictedCertona()
                }
        }
    }, [wishlistItems, cartItemsData, currentPageType, triggerRestrictedCertona, customerId, storeCertonaData])

    useEffect(() => {
        certonaCallRef.current = MagicNumber.ZERO
        // This useEffect needs to be triggered only when profile data changes
    }, [userProfileData])

    /**
     * @method getOrderData  : Gets order data based on order page
     * @returns {FilteredCartData} returns object
     */
    const getOrderData = useCallback(() => {
        if (currentPageType === pageTypes.orderDetails && checkDataLength(orderDetailsData)) {
            return orderDetailsData
        } else if (currentPageType === pageTypes.orderConfirmation && checkDataLength(order)) {
            return order
        }
    }, [order, orderDetailsData, currentPageType])

    // Initialize the certona i.e create object and add callback to the response and parse the response.
    // eslint-disable-next-line complexity
    useEffect(() => {
        const {
            productPage,
            searchPage,
            store,
            eventLanding,
            eventListing,
            categoryPages,
            orderConfirmation,
            orderDetails,
            digitalFlyer,
            homePage,
            promoListing,
            brandLandingPage,
            brandListingPage,
            noSearchPage,
            allBrandsPage,
            errorPage,
            emailOfferActivation,
            account,
            weeklyOffer,
            accountDasboard,
        } = pageTypes

        if (
            certonaCallRef.current === 0 &&
            window.callCertona &&
            (isAuthFlowExecuted || !isGigyaLoaded || (productPage === currentPageType && signOutSuccess))
        ) {
            if (productPage === currentPageType && !!checkDataLength(productData)) {
                const { pCode } = extractPCodeFromUrl()
                CertonaInitialization.init(storeCertonaData, {
                    categories: getCategoriesList(productData?.breadcrumbList, true),
                    ItemId: pCode,
                    customerId: customerId,
                    outOfStock: !!isOOSCurrentStore,
                })
                triggerRestrictedCertona()
            } else if (searchPage === currentPageType && checkDataLength(productCardData)) {
                CertonaInitialization.init(storeCertonaData, {
                    exItemId: retrieveProductCode(productCardData?.products),
                    pageType: productCardData?.products.length > 0 ? searchPage : noSearchPage,
                })
                triggerRestrictedCertona()
            } else if (store === currentPageType) {
                const storeIdFromURL = new URLSearchParams(window.location.search).get('storeId') as string
                const currentSelectedStore = appCacheService.preferredStoreId.get()
                CertonaInitialization.init(storeCertonaData, {
                    storeId: storeIdFromURL ?? currentSelectedStore,
                })
                triggerRestrictedCertona()
            } else if (
                (orderConfirmation === currentPageType || orderDetails === currentPageType) &&
                checkDataLength(getOrderData())
            ) {
                CertonaInitialization.init(storeCertonaData, {
                    ...getOrderDetailsForCertona(getOrderData() as FilteredCartData),
                    customerId,
                })
                triggerRestrictedCertona()
            } else if (eventLanding === currentPageType || eventListing === currentPageType) {
                CertonaInitialization.init(storeCertonaData, {
                    // TODO: Send Page Name
                    event: '',
                })
                triggerRestrictedCertona()
            } else if (categoryPageConditions(categoryPages) && categoryApiCompleted) {
                CertonaInitialization.init(storeCertonaData, {
                    categories: getCategoriesFromURL(categoryData.breadcrumbList).getCategoryIds(),
                    categoryId: categoryData.id,
                })
                triggerRestrictedCertona()
            } else if (
                account === currentPageType ||
                weeklyOffer === currentPageType ||
                accountDasboard === currentPageType ||
                homePage === currentPageType ||
                emailOfferActivation === currentPageType
            ) {
                CertonaInitialization.init(storeCertonaData, {
                    customerId: customerId,
                })
                triggerRestrictedCertona()
            } else if (brandListingPage === currentPageType || brandLandingPage === currentPageType) {
                const urlArr = window?.location?.href?.split('/')
                const brand = urlArr?.[urlArr.length - MagicNumber.ONE]?.split('.')?.[0]
                if (brand) {
                    CertonaInitialization.init(storeCertonaData, {
                        brand,
                    })
                    triggerRestrictedCertona()
                }
            } else if (
                currentPageType === '' ||
                currentPageType === digitalFlyer ||
                currentPageType === promoListing ||
                currentPageType === allBrandsPage ||
                currentPageType === errorPage
            ) {
                CertonaInitialization.init(storeCertonaData, {})
                triggerRestrictedCertona()
                const emptyCertona = { resonance: { schemes: [] } }
                // pages like promolist, customer-service and other static pages are not configured with certona, so we are dispatching separately
                dispatch(storeCertonaRecommendationData(emptyCertona))
            }
        }
    }, [
        storeCertonaData,
        productData,
        userProfileData,
        categoryPageConditions,
        currentPageType,
        categoryData,
        order,
        productCardData,
        triggerRestrictedCertona,
        isAuthFlowExecuted,
        customerId,
        isOOSCurrentStore,
        isProductDataAvailable,
        signOutSuccess,
        productSkuData,
        dispatch,
        orderDetailsData,
        getOrderData,
        isGigyaLoaded,
        categoryApiCompleted,
    ])

    return null
}
