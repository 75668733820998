import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    getOrderConfirmationDetails,
    performShippingAddressValidation,
    placeOrderFromConfirmationPage,
} from '../../../redux/actions/orderConfirmation.action'
import getQueryString from '../../../utils/getQueryString'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { analyticsInteraction } from '../../../redux/actions/pageAnalytics.action'
import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import { thirdPartyOrderConfirmationAnalytics } from '../../../analytics/components/orderConfirmationAnalytics'
import { commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import { IFeatureFlag, IFedErrors, IGlobalLinks } from '../../../redux/models/commonContent.interface'
import { isAuthFlowExecutedSelector } from '../../../redux/selectors/userProfile.selectors'
import {
    clickToPayAddressValidationErrorSelector,
    clickToPayAddressValidationSelector,
    placeOrderConfirmationErrorSelector,
} from '../../../redux/selectors/orderItemsDetails.selectors'
import InitTruefitOrderReport from './InitTruefitOrderReport'
import { orderIdParameter } from '../OrderConfirmation.constant'
import appCacheService from '../../../utils/appCacheService'
import { paymentInformationConst } from '../../Checkout/PaymentInformation/cardTypeConstants'
import { ClickToPayAddressValidationError } from '../../../redux/models/cart.interface'
import { isClickToPaySTHShippingAddressError, isFullPageError } from '../../Checkout/Checkout.helper'

/**
 * OrderConfirmationPage to invoke order confirmation api
 * @returns {JSX.Element} OrderConfirmationPageInit component
 */
const OrderConfirmationPageInit: React.FC = () => {
    const dispatch = useDispatch()
    const placeOrderConfirmationError = useSelector(placeOrderConfirmationErrorSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const {
        featureFlag = {} as IFeatureFlag,
        globalLinks = {} as IGlobalLinks,
        fedErrors = {} as IFedErrors,
    } = useSelector(commonContentAvailableSelector)
    const clickToPayValidationData = useSelector(clickToPayAddressValidationSelector)
    const clickToPayAddressValidationError = useSelector(clickToPayAddressValidationErrorSelector)
    const { enableTmx, enableSignifyd }: IFeatureFlag = featureFlag
    const { checkoutPageLink, cartPageLink } = globalLinks

    const [isApiInvoked, setIsApiInvoked] = useState(false)
    const masterpassUsed = 'true' === getQueryString(window.location.search, 'masterpass')
    const isClickToPaySTHCart = 'captured' === getQueryString(window.location.search, 'status')
    const cartID = getQueryString(window.location.search, 'cartId')

    /**
     * method to invoke
     * place order api for click to pay BOPIS
     * shipping address validation api for click to pay STH
     */
    const invokePlaceOrderAPI = useCallback((): void => {
        dispatch(
            placeOrderFromConfirmationPage(cartID, masterpassUsed && !isClickToPaySTHCart, true, {
                enableTmx: enableTmx,
                enableSignifyd: enableSignifyd,
            }),
        )
    }, [cartID, dispatch, enableSignifyd, enableTmx, isClickToPaySTHCart, masterpassUsed])

    /**
     * useCallback function to check if click to pay sth validation error is valid
     * @param {ClickToPayAddressValidationError} clickToPayErrorData
     * @return {boolean}
     */
    const isClickToPaySTHValidationErrors = useCallback(
        (clickToPayErrorData: ClickToPayAddressValidationError): boolean => {
            return (
                isClickToPaySTHShippingAddressError(clickToPayErrorData.errorCode) ||
                clickToPayErrorData.errorCode === paymentInformationConst.clickToPaySTHDeliveryFeeUpdated
            )
        },
        [],
    )

    /**
     * Function to redirect to given page with given error code
     * @param {string} pageLink page link
     * @param {string} errorCode error code
     * @returns {void}
     */
    const redirectToPageWithErrorCode = (pageLink: string, errorCode: string): void => {
        if (pageLink) {
            window.location.href = encodeURI(
                `${pageLink}${errorCode ? `?${paymentInformationConst.thirdPartyPaymentErrorKey}=${errorCode}` : ''}`,
            )
        }
    }

    useEffect(() => {
        const windowGigyaObj = window.gigya
        if (isAuthFlowExecuted || (!windowGigyaObj && !appCacheService.gigyaJWTToken.get())) {
            const {
                event: { interaction },
                eventParameters: {
                    action: { thirdPartyPaymentCompleted },
                    category: { payment3rdParty },
                    labels: { masterpass },
                },
            } = analyticsAttributes
            if (cartID) {
                thirdPartyOrderConfirmationAnalytics()
                dispatch(analyticsInteraction(masterpass, '', interaction, thirdPartyPaymentCompleted, payment3rdParty))
                // OCCP-17325: masterpass=true if redirected from masterpass flow. if masterpass is used, send isNewCard=true when invoking placeOrder api.
                //             at the moment, only masterpass payment flow results in a redirect, so the isNewCard flag takes the same value as masterpassUsed (this may change in the future)
                if (!isApiInvoked) {
                    setIsApiInvoked(true)
                    if (isClickToPaySTHCart) {
                        dispatch(performShippingAddressValidation(cartID))
                    } else {
                        invokePlaceOrderAPI()
                    }
                }
            } else {
                const orderId = getQueryString(window.location.search, orderIdParameter)
                dispatch(getOrderConfirmationDetails(orderId))
            }
        }
    }, [
        isAuthFlowExecuted,
        dispatch,
        enableTmx,
        enableSignifyd,
        invokePlaceOrderAPI,
        isApiInvoked,
        cartID,
        isClickToPaySTHCart,
    ])

    useEffect(() => {
        if (checkDataLength(clickToPayValidationData)) {
            if (!checkDataLength(clickToPayValidationData.error)) {
                invokePlaceOrderAPI()
            } else if (!!clickToPayValidationData.error) {
                if (isClickToPaySTHValidationErrors(clickToPayValidationData.error)) {
                    let redirectURI = `${checkoutPageLink}?${paymentInformationConst.clickToPaySTHValidationErrorKey}=${clickToPayValidationData.error.errorCode}`
                    if (
                        clickToPayValidationData.error.errorCode ===
                        paymentInformationConst.clickToPaySTHCanadaPostSuggestionError
                    ) {
                        redirectURI =
                            redirectURI +
                            `&${paymentInformationConst.clickToPayCanadaPostAction}=${clickToPayValidationData.canadaPostAddress.nextAction}&${paymentInformationConst.clickToPayCanadaPostId}=${clickToPayValidationData.canadaPostAddress.id}`
                    }
                    window.location.href = encodeURI(redirectURI)
                } else {
                    redirectToPageWithErrorCode(cartPageLink, paymentInformationConst.masterPassError475)
                }
            }
        }
    }, [cartPageLink, checkoutPageLink, clickToPayValidationData, invokePlaceOrderAPI, isClickToPaySTHValidationErrors])

    useEffect(() => {
        if (checkDataLength(placeOrderConfirmationError) && placeOrderConfirmationError?.data?.errCode) {
            const placeOrderErrorCode = placeOrderConfirmationError.data.errCode
            if (isClickToPaySTHCart) {
                let redirectLink = cartPageLink
                let errorCode =
                    paymentInformationConst.ctfsError410 === placeOrderErrorCode
                        ? paymentInformationConst.masterPassError476
                        : paymentInformationConst.masterPassError475
                if (isFullPageError(placeOrderErrorCode)) {
                    redirectLink = fedErrors[`error${placeOrderErrorCode}`]
                    errorCode = ''
                }
                redirectToPageWithErrorCode(redirectLink, errorCode)
            } else {
                redirectToPageWithErrorCode(checkoutPageLink, placeOrderErrorCode)
            }
        } else if (checkDataLength(clickToPayAddressValidationError)) {
            redirectToPageWithErrorCode(cartPageLink, paymentInformationConst.masterPassError475)
        }
    }, [
        placeOrderConfirmationError,
        checkoutPageLink,
        clickToPayAddressValidationError,
        cartPageLink,
        isClickToPaySTHCart,
        fedErrors,
    ])

    return <InitTruefitOrderReport />
}

export default OrderConfirmationPageInit
