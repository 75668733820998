import { AnyAction, Dispatch, Store } from 'redux'
import { RootState } from '../reducers'
import { ProductDataType, ProductDataTypeObj } from '../models/productData.interface'
import { fetchProductCardDataSuccessAction } from '../actionCreators'
import { FETCH_PRODUCT_SUCCESS } from '../types/products/productData.actionTypes.constant'
import { FETCH_SPONSORED_ADS_SUCCESS } from '../types/products/sponsoredAds.actionTypes.constant'
import criteoService from '../../services/criteoService'
import { ModifiedCriteoResponse } from '../../services/criteoService/criteo.interface'
import { criteoIgnoredFilters, PREFIX } from '../../config'

interface IMergeSearchAdResultMiddleware {
    (store: Store<RootState, AnyAction>): (next: Dispatch<AnyAction>) => (action: AnyAction) => void
}

/**
 * Counts the number of columns in the grid view by examining the number of items in the first row.
 * @returns {number} - The number of columns in the grid. Returns 0 if the grid view is not found or is empty.
 */
const countGridColumns = (): number => {
    const gridView = document.querySelector(`.${PREFIX}-product-card`)?.querySelector(`.${PREFIX}-product__grid-view`)
    if (!gridView) return 0

    const htmlCollection = gridView.children
    if (htmlCollection.length === 0) return 0

    const items = Array.from(htmlCollection) as HTMLElement[]

    const currentTop = items[0].offsetTop

    const firstRow = items.filter(item => item.offsetTop === currentTop)

    return firstRow.length
}

/**
 * Merges grid ads and product cards into a single array, ensuring ads are
 * distributed according to the number of columns in the grid and removing
 * duplicates before merging.
 * @param {ProductDataTypeObj[]} inGridAds - Array of ad objects to be placed in the grid. (Sponsored)
 * @param {ProductDataTypeObj[]} organicProducts - Array of product card objects to be placed in the grid. (Organic Products)
 * @returns {ProductDataTypeObj[]} - The merged array of ads and product cards.
 */
const mergeData = (inGridAds: ProductDataTypeObj[], organicProducts: ProductDataTypeObj[]): ProductDataTypeObj[] => {
    const numberOfColumns = countGridColumns()

    const products = organicProducts.filter(product => !product.isSponsored)
    const totalAds = inGridAds.length

    let result: ProductDataTypeObj[] = []

    if (totalAds >= numberOfColumns) {
        if (numberOfColumns === 4) {
            // Insert all in the first row if there are enough columns
            result = [...inGridAds.reverse(), ...products]
        } else if (numberOfColumns === 3) {
            // Insert 3 ads in the first row and 1 ad in the fourth row
            const firstRowAds = inGridAds.slice(0, numberOfColumns).reverse()
            const remainingAds = inGridAds.slice(numberOfColumns).reverse()

            const beforeFourthRow = products.slice(0, 8)
            const afterFourthRow = products.slice(8)
            result = [...firstRowAds, ...beforeFourthRow, ...remainingAds, ...afterFourthRow]
        } else if (numberOfColumns === 2) {
            // Insert 2 ads in the first row and 2 ads in the fourth row
            const firstRowAds = inGridAds.slice(0, numberOfColumns).reverse()
            const remainingAds = inGridAds.slice(numberOfColumns).reverse()

            const beforeFourthRow = products.slice(0, 4)
            const afterFourthRow = products.slice(4)
            result = [...firstRowAds, ...beforeFourthRow, ...remainingAds, ...afterFourthRow]
        }
    } else {
        // Insert fewer ads than the number of columns available
        const columnsLeftAfterAds = numberOfColumns - totalAds
        const firstFew = products.slice(0, columnsLeftAfterAds > 0 ? columnsLeftAfterAds : 0)
        const afterAds = products.slice(columnsLeftAfterAds > 0 ? columnsLeftAfterAds : 0)
        const adsReversed = inGridAds.reverse()
        result = [...firstFew, ...adsReversed, ...afterAds]
    }

    return result
}

/**
 * Checks if any filters or range facets are selected in the payload except ignored filters
 * @param {ProductDataType} payload - The product data containing facets and filter selections.
 * @param {string[]} ignoredFilters - Array of filter IDs to ignore.
 * @returns {boolean} - True if any filters or range facets are selected, otherwise false.
 */
const isAnyFilterSelected = (payload: ProductDataType, ignoredFilters: string[]) => {
    const isAnyFacetSelected = payload.facets?.some(item => item.selected && !ignoredFilters?.includes(item.id))
    const isAnyRangeFacetSelected = payload.rangeFacets?.some(
        item => item.selected && !ignoredFilters?.includes(item.id),
    )

    return (
        payload.inStockAtMyStore?.selected ||
        payload.saleItem?.selected ||
        isAnyFacetSelected ||
        isAnyRangeFacetSelected
    )
}

/**
 * Middleware that merges products and sponsored ads when certain actions are dispatched, ensuring ads are merged unless filters are selected.
 * @param {object} store - The Redux store instance.
 * @returns {Function} - The next middleware function.
 */
export const mergeProductsAndSponsoredAdsMiddleware: IMergeSearchAdResultMiddleware = store => next => action => {
    const {
        commonContent,
        productCardData: { productCardData, productCardLoading },
        sponsoredAds,
        userProfile,
        categoryIdData,
    } = store.getState()

    if (
        !commonContent?.commonContentAvailable?.featureFlag?.enableCriteoDirectServer ||
        (categoryIdData?.isFitmentRequired && userProfile?.vehicle?.isTireOrDefaultVehicleSet)
    ) {
        return next(action)
    }

    if (action.type === FETCH_PRODUCT_SUCCESS && sponsoredAds.inGrid.products.length) {
        const originalPayload = action.payload as ProductDataType

        // Abort merging if one or more filters are selected
        if (isAnyFilterSelected(originalPayload, criteoIgnoredFilters)) {
            return next(action)
        }

        const modifiedAction = {
            ...action,
            payload: {
                ...originalPayload,
                products: mergeData(
                    criteoService.convertCriteoData(sponsoredAds.inGrid.products),
                    originalPayload.products,
                ),
            } as ProductDataType,
        }

        return next(modifiedAction)
    }

    if (action.type === FETCH_SPONSORED_ADS_SUCCESS && productCardData.products && !productCardLoading) {
        const {
            placements: { inGrid },
        } = action.payload as ModifiedCriteoResponse

        const sponsoredAdsProduct = criteoService.convertCriteoData(inGrid.products)

        const productCardsWithInGridAds = {
            ...productCardData,
            products: mergeData(sponsoredAdsProduct, productCardData.products),
        } as ProductDataType

        store.dispatch(fetchProductCardDataSuccessAction(productCardsWithInGridAds))
    }

    return next(action)
}
