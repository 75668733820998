import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../redux/reducers'
import { FullPageSpinner, SpinnerStyle, Animation, iconSizes, SpinnerTypes } from '@nl/lib'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'

/**
 * function to render Full page spinner
 * @returns {JSX.Element} FullPageSpinnerComponent component
 */
export const FullPageSpinnerComponent = (): JSX.Element => {
    const { general, accessibility } = useSelector(commonContentAvailableSelector)
    const { showSpinner, spinnerCounter } = useSelector((state: RootState) => state.spinner)

    let spinnerStyle = SpinnerStyle.DEFAULT
    let spinnerValue = {}

    if (showSpinner.type === SpinnerTypes.TRIANGLE) {
        spinnerStyle = SpinnerStyle.SPLIT_TRAILING
        spinnerValue = {
            type: ['ct-lock', 'ct-triangle'],
            animation: Animation.FLIP,
            size: iconSizes.Large,
        }
    }

    return (
        <FullPageSpinner
            style={spinnerStyle}
            value={spinnerValue}
            showSpinner={showSpinner.show}
            spinnerCounter={spinnerCounter}
            a11yLoadingSpinnerLabel={accessibility?.a11yLoadingSpinnerLabel}
            globalSpinnerColor={general?.globalSpinnerColor}></FullPageSpinner>
    )
}

export default FullPageSpinnerComponent
