import { CriteoParams } from './criteo.interface'
import { CriteoEventType } from './criteoService.constants'

/**
 * Appends multiple key-value pairs to a URLSearchParams object.
 * @param {URLSearchParams} params - The URLSearchParams object to append to.
 * @param {Array<[string, string | number | undefined]>} keyValues - An array of key-value pairs to append.
 * @returns {URLSearchParams} The updated URLSearchParams object.
 */
const appendParams = (
    params: URLSearchParams,
    keyValues: Array<[string, string | number | undefined]>,
): URLSearchParams => {
    keyValues.forEach(([key, value]) => {
        if (value) {
            params.append(key, value.toString())
        }
    })
    return params
}

/**
 * @param {CriteoParams} params - The criteo API parameters
 * @returns {string} The url params containing all the custom information
 */
export const appendCustomCriteoParams = (params: CriteoParams): string => {
    const { eventType, isMobile } = params
    const deviceType = isMobile ? 'mobile' : 'desktop'
    const customParams = new URLSearchParams()
    customParams.append('event-type', eventType)

    switch (eventType) {
        case CriteoEventType.VIEW_SEARCH_RESULT:
            appendParams(customParams, [
                [
                    'page-id',
                    params.isNullSearch
                        ? `viewNullSearchResult_API_${deviceType}`
                        : `viewSearchResult_API_${deviceType}`,
                ],
                ['keywords', params.keywords],
                ['page-number', params.pageNumber],
            ])
            break

        case CriteoEventType.VIEW_CATEGORY:
            appendParams(customParams, [
                ['page-id', `viewCategory_API_${deviceType}`],
                ['category', params.category],
                ['page-number', params.pageNumber],
            ])
            break

        case CriteoEventType.VIEW_ITEM:
            appendParams(customParams, [
                ['page-id', `viewItem_API_${deviceType}`],
                ['parent-item', params.parentItem],
                ['price', params.price],
                ['availability', params.isAvailable ? '1' : '0'],
                ['category', params.category],
            ])
            break

        default:
            break
    }

    // breakdown URLSearchParam into an array and join them into one string to prevent unnecessary encoding of the parameters
    const customParamsArray = Array.from(customParams.entries()).map(([key, value]) => `${key}=${value}`)

    return customParamsArray.join('&')
}
