import { ActionType, getType } from 'typesafe-actions'

import { RecommendationResponseDataDTO, RecommendationsErrorResponse } from '../models/recommendations.interface'
import {
    fetchRecommendationsDataSuccessAction,
    fetchRecommendationsAtcStatus,
    fetchRecommendationsDataErrorAction,
    setRecommendationsDataLoadingAction,
    removeSpecificSchemaFromRecommendationData,
} from '../actionCreators'
import * as actions from '../actionCreators/recommendationsData.actionCreators'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType
export interface RecommendationsDataState {
    recommendationsData: RecommendationResponseDataDTO[]
    recommendationDataError: RecommendationsErrorResponse | null
    recommendationsAtcClicked: boolean
    recommendationDataLoading: boolean
}

export const initialState: RecommendationsDataState = {
    recommendationsData: [] as RecommendationResponseDataDTO[],
    recommendationDataError: null,
    recommendationsAtcClicked: false,
    recommendationDataLoading: false,
}

/**
 * function to remove specific schema id from recommendation redux state.
 * This is needed to refresh Recommendations data. (ex:-CartAccessoriesFlyout scenario where we are calling same schema id for different products and getting different pcodes.)
 * @param {RecommendationsDataState} state
 * @param {string} payload
 * @returns {RecommendationResponseDataDTO[]}
 */
const deleteSpecificSchemaFromRecommendation = (
    state: RecommendationsDataState,
    payload: string,
): RecommendationResponseDataDTO[] => {
    return state.recommendationsData?.filter(schemeItem => {
        return !(payload === schemeItem.scheme)
    })
}

export const recommendationsDataReducer = (
    state: RecommendationsDataState = initialState,
    action: Action,
): RecommendationsDataState => {
    switch (action.type) {
        case getType(fetchRecommendationsDataSuccessAction): {
            return {
                ...state,
                recommendationsData: action.payload,
                recommendationDataError: null,
                recommendationDataLoading: false,
            }
        }
        case getType(fetchRecommendationsAtcStatus): {
            return { ...state, recommendationsAtcClicked: action.payload }
        }
        case getType(fetchRecommendationsDataErrorAction): {
            return { ...state, recommendationDataError: action.payload, recommendationDataLoading: false }
        }
        case getType(setRecommendationsDataLoadingAction): {
            return { ...state, recommendationDataLoading: action.payload }
        }
        case getType(removeSpecificSchemaFromRecommendationData): {
            return {
                ...state,
                recommendationsData: deleteSpecificSchemaFromRecommendation(state, action.payload),
                recommendationDataError: null,
                recommendationDataLoading: false,
            }
        }
        default:
            return state
    }
}
