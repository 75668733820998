export const AOA_SAVE_CONTACT_INFO = 'AOA_SAVE_CONTACT_INFO'

export const AOA_SAVE_DATE_INFO = 'AOA_SAVE_DATE_INFO'
export const AOA_SAVE_TIME_INFO = 'AOA_SAVE_TIME_INFO'
export const AOA_SAVE_DROP_OFF_OPTION_INFO = 'AOA_SAVE_DROP_OFF_OPTION_INFO'

export const AOA_SAVE_BOOKING_RESPONSE = 'AOA_SAVE_BOOKING_RESPONSE'
export const AOA_SAVE_LOCATION_INFO = 'AOA_SAVE_LOCATION_INFO'
export const AOA_CLEAR_BOOKING_DATA = 'AOA_CLEAR_BOOKING_DATA'
export const AOA_CLEAR_DATE_TIME_DATA = 'AOA_CLEAR_DATE_TIME_DATA'
export const AOA_CLEAR_SERVICES_DATA = 'AOA_CLEAR_SERVICES_DATA'

export const AOA_SAVE_SERVICE_RESPONSE = 'AOA_SAVE_SERVICE_RESPONSE'
export const AOA_UPDATE_AUTOMOTIVE_SERVICES = 'AOA_UPDATE_AUTOMOTIVE_SERVICES'
export const AOA_CURRENT_BOOKING_FORM_STEP = 'AOA_CURRENT_BOOKING_FORM_STEP'
export const AOA_SAVE_PREVIOUSLY_SELECTED_SERVICES = 'AOA_SAVE_PREVIOUSLY_SELECTED_SERVICES'
export const AOA_CLEAR_PREVIOUSLY_SELECTED_SERVICES = 'AOA_CLEAR_PREVIOUSLY_SELECTED_SERVICES'
export const AOA_SAVE_OPENED_STORE_LOCATOR_MODAL = 'AOA_SAVE_OPENED_STORE_LOCATOR_MODAL'
export const AOA_SAVE_CONTACT_INFO_EDIT_MODE = 'AOA_SAVE_CONTACT_INFO_EDIT_MODE'

export const AOA_SAVE_HAS_STARTED_AOA_FLOW = 'AOA_SAVE_HAS_STARTED_AOA_FLOW'

export const AOA_SAVE_ETIRE_ORDER_INFO = 'AOA_SAVE_ETIRE_ORDER_INFO'
export const AOA_SAVE_VEHICLE_ORDER_INFO = 'AOA_SAVE_VEHICLE_ORDER_INFO'
export const SET_SHOW_SKELETON = 'SET_SHOW_SKELETON'

export const SET_AOA_ERROR = 'SET_AOA_ERROR'
export const SET_AOA_IS_LOADING = 'SET_AOA_IS_LOADING'

export const AOA_SAVE_ADDITIONAL_COMMENTS = 'AOA_SAVE_ADDITIONAL_COMMENTS'
export const AOA_RESET_DATE_TIME_INFO = 'AOA_RESET_DATE_TIME_INFO'
