import { PREFIX } from '../../config'

export enum toastTypeConst {
    postalCode = 'postalCode',
    pickupInformation = 'pickupInformation',
    general = 'general',
    successFees = 'successFees',
    changeShippingFee = 'changeShippingFee',
    CTMoneyError = 'CTMoneyError',
    CTMoneySuccess = 'CTMoneySuccess',
    deliveryModeChanged = 'deliveryModeChanged',
    contact = 'contactError',
    ToggleOffByUser = 'toggleOffByUser',
    shippingFailure = 'shippingFailure',
    deliveryToPOBoxesFailure = 'deliveryToPOBoxesFailure',
    shippingFeeApplied = 'shippingFeeApplied',
    giftCardErrors = 'giftCardErrors',
}

export enum drawerFillStatus {
    contactInfoDrawer = 'contact',
    shippingInfoDrawer = 'shipping',
    pickupInfoDrawer = 'pickup',
    paymentInfoDrawer = 'payment',
}
export const trueString = 'true'
export const inactiveClassName = 'inactive-bar'
export const gcBarClassName = 'giftcard-bar'
export const rewardsBarCardClassName = 'rewards-card'
export const rewardsCardClassName = `${PREFIX}-reward-card`
export const gcBarClassNameInactive = `${gcBarClassName} ${PREFIX}-${gcBarClassName}--inactive`
export const paymentClassName = `${PREFIX}-payment-information`

export const successStatus = 200
export const checkoutContactUrl = 'updateContactCheckout'
export const checkoutDeliveryUrl = 'updateDeliveryCheckout'
export const checkoutPickupUrl = 'updatePickupCheckout'
export const checkoutPaymentUrl = 'updatePaymentCheckout'
export const PlaceOrderErrorCode = {
    hystrixError: 'error00271',
    inSufficientInventoryError: 'error03054',
    cartEmptyError: 'error03037',
    cardMessagePathError: 'error03000',
}
export const inflationAppointmentErrorCode = '03100'
export const inflationAppointmentPickupTogetherErrorCode = '03200'
export enum pickupAllItemsTogetherEligibleType {
    ELIGIBLE = 'ELIGIBLE',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export const RedeemCTMoneyURL = 'redeemCTMoney'

export const NO_ERROR = 'No Error'
export const ProfileURL = 'profile'
export const PaymentDetailsURL = 'paymentDetails'

// attribute name to access the old car banner id from AEM for Gigya
export const gigyaBannerId = 'gigyaBannerId'
export const isCheckoutSignInRedirect = 'isCheckoutSignInRedirect'
export const returnURL = 'returnURL'
