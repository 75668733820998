import React from 'react'
import { useSelector } from 'react-redux'
import { PromoMessages } from '@nl/lib'
import { PromoMessagesComponentProps } from './PromoMessagesComponent.type'
import { getSelectedFirstVariantValues } from '../BuyBox.helper'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { accessibilitySelector } from '../../../redux/selectors/commonContent.selectors'

/**
 * Component to return PromoMessages
 * @param {PromoMessagesComponentProps} props PromoMessagesComponent props
 * @returns {JSX.Element | null} returns PromoMessages
 */
const PromoMessagesComponent: React.FC<PromoMessagesComponentProps> = (
    props: PromoMessagesComponentProps,
): JSX.Element | null => {
    const {
        variantDetails,
        promoEnabled,
        promoRestrictedBrands,
        sizeChartBrand,
        isProductLevel,
        flyoutVariantsInfoData,
    } = props

    const generalProductData = useSelector(productSelector)
    const { productData, selectedFirstVariantId, productSkuData } = flyoutVariantsInfoData || generalProductData
    const { a11yCloseIconLabel: closeIconLabel, a11yTooltipIcon } = useSelector(accessibilitySelector)

    const selectedFirstVariantValues = getSelectedFirstVariantValues(
        variantDetails,
        selectedFirstVariantId,
        productData,
    )

    const productPromoMessages = productData?.promoMessages?.[0]?.label
        ? productData?.promoMessages
        : selectedFirstVariantValues?.promoMessages
    const productRestrictedPromoMessages = productData?.restrictedPromoMessages?.[0]?.label
        ? productData?.restrictedPromoMessages
        : selectedFirstVariantValues?.restrictedPromoMessages
    const skuPromoMessages = productSkuData?.skus?.[0]?.promoMessages
    const skuRestrictedPromoMessages = productSkuData?.skus?.[0]?.restrictedPromoMessages
    const promoRestrictedBrandLabels = promoRestrictedBrands?.map(product => product.brand?.toLocaleLowerCase())
    const isPromoRestrictedBrand = promoRestrictedBrandLabels?.includes(sizeChartBrand.toLocaleLowerCase())
    const isPromoEnabled = promoEnabled ?? false
    const isRenderPromoMessages = !isPromoRestrictedBrand && isPromoEnabled

    return isRenderPromoMessages ? (
        <PromoMessages
            isProductLevel={isProductLevel}
            productPromoMessages={productPromoMessages}
            productRestrictedPromoMessages={productRestrictedPromoMessages}
            skuPromoMessages={skuPromoMessages}
            skuRestrictedPromoMessages={skuRestrictedPromoMessages}
            a11yTooltipIcon={a11yTooltipIcon}
            a11yCloseIconLabel={closeIconLabel}
            isModalPresent={!!flyoutVariantsInfoData}
        />
    ) : null
}

export default PromoMessagesComponent
