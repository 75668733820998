import { useEffect, useMemo } from 'react'
import usePredefinedSku from '../../components/BuyBox/usePredefinedSku'
import extractPCodeFromUrl from './extractPCodeFromUrl'
import { useDispatch } from 'react-redux'
import { setIsSKUPresentInUrlAction } from '../../redux/actionCreators/product.actionCreators'

export interface useExtractPCodeFromUrlType {
    pCode: string
    skuCode: string
    colorCode: string
    staggeredSkus: string
    allSelectedSkus: string[]
}

/**
 * Hook to fetch & return pCode from url
 * @returns {useExtractPCodeFromUrlType} pCode
 */
export const useExtractPCodeFromUrl = (): useExtractPCodeFromUrlType => {
    const dispatch = useDispatch()
    // get the initial sku code
    const predefinedSku = usePredefinedSku()
    // codes for url
    const extractedCodesFromUrl = extractPCodeFromUrl()
    const { pCode, skuCode, colorCode, staggeredSkus } = useMemo(
        () => Object.assign(extractedCodesFromUrl, { skuCode: extractedCodesFromUrl?.skuCode || predefinedSku }),
        [predefinedSku, extractedCodesFromUrl],
    )

    /**
     * This useEffect is warrantee that Redux marker isSKUPresentInUrl is in correct state when BuyBox has selected sku from URL
     */
    useEffect(() => {
        if (skuCode) {
            dispatch(setIsSKUPresentInUrlAction(true))
        }
    }, [skuCode, dispatch])

    const allSelectedSkus = useMemo(
        () => [...(staggeredSkus?.split(',') || []), skuCode].filter(Boolean),
        [skuCode, staggeredSkus],
    )
    return { pCode, skuCode, colorCode, staggeredSkus, allSelectedSkus }
}

export default useExtractPCodeFromUrl
