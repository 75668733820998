import SimpleHeaderCheckout from '../components/SimpleHeaderCheckout'
import { initAnalytics } from '../analytics'
import authoredTextBlock from '../components/AuthoredTextBlock'
import accordionClick from '../components/AccordionClick'
import SlickCarouselComponent from '../components/SlickCarouselComponent'
import privacyCharter from '../components/PrivacyCharter'
import footerCollapseControl from '../components/FooterCollapseControl'
import pageLoad from '../components/PageLoad/pageLoad'
import { Banner } from '../components/Banner/Banner'
import { countdownBanner } from '../components/CountdownBanner/countdownBanner'
import { AspotCarouselComponent } from '../components/ AspotCarouselComponent/AspotCarouselComponent'
import { HeadbandBannerComponent } from '../components/HeadbandBannerComponent/HeadbandBannerComponent'
import { FeaturedListImage } from '../components/FeaturedListImage/FeaturedListImage'
import { featuredList } from '../components/FeaturedList/featuredList'
import { FeaturedListEducation } from '../components/FeaturedListEducation/FeaturedListEducation'
import { FeaturedListTallTiles } from '../components/FeaturedListTallTiles/FeaturedListTallTiles'

export const staticFunctionMap: {
    [key: string]: {
        method: (element?: Element, params?: Record<string, string>) => void
        param?: Record<string, string>
    }
} = {
    'privacy-charter': { method: privacyCharter },
    'footer-links': { method: footerCollapseControl },
    'nl-authored-text-block': { method: authoredTextBlock },
    'nl-simple-header-checkout': { method: SimpleHeaderCheckout },
    'cmp-accordion__header': { method: accordionClick },
    'carousel-authored': { method: SlickCarouselComponent, param: { slickClass: 'regular' } },
    'aspot-carousel': { method: AspotCarouselComponent, param: { slickClass: 'regular' } },
    'nl-headband-banner': { method: HeadbandBannerComponent },
    'nl-featured-list-image': { method: FeaturedListImage },
    'nl-featured-list-education': { method: FeaturedListEducation },
    'nl-featured-list-tall-tiles': { method: FeaturedListTallTiles },
    'aem-analytics-init': { method: initAnalytics },
    'nl-featured-list': { method: featuredList },
    'nl-banner': { method: Banner },
    'odp-page': { method: pageLoad },
    'nl-countdownbanner': { method: countdownBanner },
}
