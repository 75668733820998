import { pagesForLiteProfileCall, userProfileData } from '../components/GigyaScreen/gigya.constants'
import { StoredUserProfileData, UserProfileData } from '../redux/models/user.profile.interface'
import { getCurrentDateTime } from '../utils/getDateTime'
import { checkDataLength } from '@nl/lib'
import getPageType from '../utils/getPageType'
import localStorageService from '../utils/localStorageService'

/**
 * Function to check if User Profile data should be updated in localStorage
 * @param {boolean | undefined} isLiteProfileCallVal Param if LiteProfileCall should trigger
 * @returns {boolean} true/false
 */
export const shouldUpdateUserAccountData = (isLiteProfileCallVal: boolean | undefined): boolean => {
    const storedUserDataString = localStorage.getItem('userProfileData')
    const storedUserData = storedUserDataString ? (JSON.parse(storedUserDataString) as StoredUserProfileData) : {}

    const currentTime = getCurrentDateTime().getTime()
    if (currentTime >= storedUserData?.uExpTime) {
        return true
    }

    return !isLiteProfileCallVal
}

/**
 * Function to check if json is valid
 * @param {string} str string value
 * @returns {boolean} return true/false
 */
export const isValidJson = (str: string): boolean => {
    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
}

/**
 * Function to check if user is visited for the first time and no user data is present
 * @param {StoredUserProfileData} storedUserData stored user data
 * @returns {boolean} return true/false
 */
export const checkIsFirstVisitOrUserDataPresent = (storedUserData: StoredUserProfileData): boolean => {
    const currentPageType = getPageType()
    return Boolean(pagesForLiteProfileCall?.includes(currentPageType) && checkDataLength(storedUserData))
}

/**
 * Function to sync redux userProfileData with saved local userProfileData value
 * @param {UserProfileData} data stored user data
 * @param {StoredUserProfileData} storedUserData stored user data
 * @returns {UserProfileData} updated response object
 */
export const syncReduxStorageValue = (
    data: UserProfileData,
    storedUserData: StoredUserProfileData,
): UserProfileData => {
    const uIsWithBenefits = storedUserData?.uIsWithBenefits

    const result: UserProfileData = {
        ...data,
        balance: storedUserData?.uCtm,
        isLoyalAccountLinked: storedUserData?.uIsLytAccntLinked,
        triangleType: {
            rewardsCardType: storedUserData?.uTrnType?.rewardsCardType,
            rewardsPercentage: storedUserData?.uTrnType?.rewardsPercentage,
        },
        linkedFlag: storedUserData?.uLknFlag,
        UID: storedUserData?.uId,
        addresses: {
            postalCode: storedUserData?.uPsCode,
        },
    }

    if (uIsWithBenefits) {
        result.loyalty = {
            cardNumber: storedUserData?.uIsLytAccntLinked,
            tsSubscriptionStatus: storedUserData?.uTsSubStatus,
            id: data?.id,
        }
    }

    return result
}

/**
 * Function to store user profile Data in Local storage
 * @param {UserProfileData} userData userProfileData object
 * @param {number} expiryHours expiry time
 * @returns {void}
 */
export const storeUserDataWithExpiry = (userData: UserProfileData, expiryHours: number): void => {
    const { firstName, balance, UID, triangleType, linkedFlag, loyalty, addresses } = userData
    const isLoyaltyUser = checkDataLength(loyalty)
    const isLoyalAccountLinked = !!loyalty?.cardNumber

    const currentDayDetails = new Date()
    const expiryTime = currentDayDetails.getTime() + expiryHours * 60 * 60 * 1000
    const userAccountData = {
        uFn: firstName,
        uCtm: balance,
        uId: UID,
        uTrnType: triangleType,
        uLknFlag: linkedFlag,
        uTsSubStatus: loyalty?.tsSubscriptionStatus,
        uExpTime: expiryTime,
        uIsWithBenefits: isLoyaltyUser,
        uIsLytAccntLinked: isLoyalAccountLinked,
        uPsCode: addresses?.postalCode,
    }
    localStorageService.setItem(userProfileData, JSON.stringify(userAccountData))
}
