import { BreadcrumbLinkData, iPrice } from './productData.interface'
import { StoreWithAvailability } from './storeDetails.interface'
import { Price } from './cart.interface'
import {
    BuyboxProps,
    ProductDataForABTest,
    SaleCut,
    TriangleBenefits,
} from '../../../../app/src/components/BuyBox/BuyBox.type'
import { MeasureDescriptionType, TableDataType } from '../../../../app/src/components/BuyBox/SizeChart/SizeChart.type'
import { RoadRating } from '@nl/lib/src/components/ProductGridView/ProductGrid.types'
import { PriceMessage, RebateData, MediaImages, CriticalFitment } from '@nl/lib'
import { ExpressDeliveryType } from '../../components/BuyBox/SwitchFulfillmentOptions/SwitchFulfillmentOptions.type'
import {
    AppointmentAvailabilityDateTimeSlotsError,
    AppointmentAvailabilityDateTimeSlotsData,
} from './appointmentAvailability.interface'

export interface FeatureUnitDTO {
    name: string
    symbol: string
    unitType: string
}

export interface FeatureValuesDTO {
    value: string
}

export interface FeaturesDTO {
    code: string
    comparable: boolean
    featureUnit: FeatureUnitDTO
    featureValues: FeatureValuesDTO[]
    name: string
    range: boolean
}

export interface ProductSpecifications {
    label: string
    value: string
    code: string
    visibility?: boolean
    position?: number
}

export interface TireWheelSpecifications {
    id: string
}

export interface PriceDTO {
    currencyIso: string
    formattedValue: string
    priceType?: string
    value: number
    priority: number
    ctcPriceType: string
}

export interface MinMaxPriceDTO {
    currencyIso: string
    value: number
}

export interface PriceRangeDTO {
    maxPrice: MinMaxPriceDTO
    minPrice: MinMaxPriceDTO
}

export interface ParentVariantCategoryDTO {
    hasImage: boolean
    name: string
    priority: number
}

export interface VariantOptionQualifiersDTO {
    image: {
        url: string
    }
}

export interface VariantOptionDTO {
    code: string
    variantOptionQualifiers: VariantOptionQualifiersDTO[]
}
export interface VariantValueCategoryDTO {
    name: string
    sequence: number
}

export type FeatureBulletsDTO = {
    description: string
}

export interface ManualLinkDTO {
    url: string
}

export interface mediaDTO {
    altText: string
    code: string
    damPath: string
    isListingThumbnailImage: boolean
    isPrimary: boolean
    mediaType: string
    order: string
    url: string
    videoImageThumbnailURL: string
}

export interface ItemAvailability {
    Corporate?: {
        Quantity: number
        MinOrderQty: number
        orderableFromDC: boolean
        MinETA?: string
        MaxETA?: string
        bopisETA: BopisETA
        sthETA: SthETA
    }
    quantity: number
    storeShelfLocation: string | null
}

export interface BopisETA {
    MinETA: string
    MaxETA: string
}

export interface SthETA {
    MinETA: string
    MaxETA: string
}

export interface PromoMessageType {
    label?: string | null
    tooltip?: string | null
}

export interface RestrictedPromoMessageType {
    label?: string | null
    tooltip?: string | null
}

export interface TriangleBenefitList {
    triangleSelectBenefits?: TriangleSelectBenefitsType
    triangleBenefits?: TriangleBenefits
}
export interface ProductResponseData extends TriangleBenefitList {
    badges: string[]
    // below 2 are not available in API response but used in code
    breadcrumbs?: { goto: [] }
    url: string
    type: string
    baseOptions?: []
    code: string
    longDescription: string
    shortDescription: string
    images: MediaImages[]
    name: string
    averageRating: number
    numberOfReviews: number
    potentialPromotions: []
    priceRange: PriceRangeDTO
    ecoFee: iPrice
    ecoFeeIncluded: boolean
    restrictionMessage?: string
    skus: ProductSku[] // variantOptions has been replaced with skus as per CDS interface
    options: ProductOption[] // variantMatrix has been replaced with options as per CDS interface
    variantType: string
    featureBullets: FeatureBulletsDTO[]
    medias?: mediaDTO
    brand: ProductBrandDTO
    productManualLink: ManualLinkDTO[]
    productPrices: PriceDTO[]
    serviceInstallationMessage: string
    specifications: ProductSpecifications[]
    warrantyMessage: string
    homeService?: boolean
    active?: boolean
    sellable?: boolean
    messageCode?: string
    orderable?: boolean
    isBulk?: boolean
    fulfillment: FulfillmentDTOP
    rating: number
    ratingsCount: number
    itemVariants: VariantGroup[]
    isStoreEligibleForCurbside: boolean
    corporateStatus: CorporateStatus
    maxPurchasableQty?: number
    originalPrice: Price
    currentPrice: Price
    displayWasLabel: boolean
    discount?: Price
    language?: string
    videoList: VideoListType[]
    breadcrumbList: BreadcrumbLinkData[]
    hreflangLinkData?: HrefLangLinkData[]
    categories: string[]
    autoCorrectQuery?: string
    didYouMean?: string[]
    tireCategory?: string
    isTire?: boolean
    priceMessage?: PriceMessage[]
    productWheelType?: string
    categoryLevel?: string
    fitmentTypeCode?: string
    partNumber?: string
    merchCategories?: string[]
    roadRating?: RoadRating
    rebate?: RebateData
    feeMessages?: FeeMessage[]
    installationEligible?: boolean
    assemblyRequired?: boolean
    repairOrderRequired?: boolean
    saleEndDate: string | null | undefined
    isUrgentLowStock: boolean
    canonicalUrl: string
    primarySellingChannel: string
    hotDealThreshold: number
    limitedTimeThreshold: number
    lowStockThreshold: number
    promoMessages: PromoMessageType[]
    restrictedPromoMessages: RestrictedPromoMessageType[]
    totalCurrentPrice: Price
    totalOriginalPrice: Price
    shippingEstimate?: string
    saleCut?: SaleCut
    styleCodes?: string[]
    isSizeChartAvailable?: boolean
    trueFitEligible?: boolean
    isBopisOnly: boolean
    isOnSale?: boolean
    pdpUrl: string
    heliumInflationAddToCartEnabled?: boolean
    isCustomBadge?: boolean
    isMultiSku?: boolean
    loyalty?: loyaltyBadge
}

/**
 * Interface for FeeMessage
 */
export interface FeeMessage {
    feeDisclaimerTitle: string
    feeDisclaimerMessage: string
    feeTitle: string
    type: string
    value: number
}

export interface HrefLangLinkData {
    href: string
    hreflang: string
    url?: string
}

export interface VideoListType {
    url: string
    category: string
    title: string
    description: string
    thumbnail?: string
}
export interface ProductBrandDTO {
    label: string
    url: string
}
export interface ProductSku {
    code: string
    active: boolean
    sellable: boolean
    isBulk: boolean
    orderable: boolean
    originalPrice: Price
    options?: ProductOption[]
    currentPrice: Price
    displayWasLabel: boolean
    ecoFee?: number | null
    badges: string[]
    specifications: ProductSpecifications[]
    url: string
    optionIds: number[] | string[]
    maxPurchasableQty?: number
    storeShelfLocation?: string
    fulfillment: FulfillmentDTOP
    variantProductPrices?: PriceDTO[]
    discount?: Price
    restrictionMessage?: string
    partNumber: string
    feeValue: number
    priceMessage: PriceMessage[]
    promoMessages: PromoMessageType[]
    restrictedPromoMessages: PromoMessageType[]
    isFrontTire?: boolean
    saleEndDate: string | null | undefined
    isUrgentLowStock: boolean
    canonicalUrl: string
    primarySellingChannel: string
    rebate?: RebateData
    priceValidUntil: string
    isOnlineOnly: boolean
    formattedCode?: string
    saleCut?: SaleCut
    selectedProduct?: boolean
    variantStatus?: string
    fitment?: CriticalFitment
    repairOrderRequired?: boolean
    isEligibleForExpressDelivery?: boolean
    corporateStatus?: CorporateStatus
    videoList: VideoListType[]
    isOnSale?: boolean
    isEndlessAisle?: boolean
    isEcommOnly?: boolean
    isBopisOnly?: boolean
    supportedStoreServiceTypes?: InflationStoreServiceType[]
    bundleTemplates?: InflationBundleTemplates[]
    warrantyMessage?: string
    loyalty?: loyaltyBadge
}

/**
 * Interface for loyalty badge
 */
export interface loyaltyBadge {
    Bonus?: LoyaltyBonus
}

export interface LoyaltyBonus {
    DealNumber: string
    DealYear: string
    EndDate: string
    OfferCode: string
    OfferDesc: string
    OfferRewardValue: string
    OfferRewardValueSetOfFour: string
    OfferRewardValueSetOfTwo: string
    OfferRule: string
    RewardPercentage: string
    StartDate: string
}
export interface InflationStoreServiceType {
    type: string
    values: InflationServiceType[]
}

export interface InflationServiceType {
    referenceType: string
    storeServicePrice: number | null
    priceIncStoreService: number | null
}

export interface InflationBundleTemplates {
    id: string
    name: string
}

export interface ProductSkusData {
    skus: ProductSku[]
}

export type ProductSkusObject = {
    skus?: {
        fulfillment?: FulfillmentDTOP
    }
}

export interface FulfillmentDTOP {
    code: string
    storePickUp: StorePickUpInterface
    shipToHome: {
        enabled: boolean
        messageCode?: string
        quantity: number // This is not present in new CDS interface
        etaEarliest: string
        etaLatest: string
        shippingEstimate?: string
    }
    expressDelivery: ExpressDeliveryType
    availability: ItemAvailability
    originalPrice: Price
    currentPrice: Price
    maxPurchasableQty: number
}

export interface StorePickUpInterface {
    enabled: boolean
    source: StoreWithAvailability | null // This is not present in new CDS interface
    etaEarliest: string
    etaLatest: string
}

export interface ProductOption {
    descriptor: string
    display: string // Colour/Couleur
    mediaSet?: HybrisMedia[] // images, video, pdf, etc.
    values?: ProductOptionValue[]
    variantOrder?: number
}

export interface ProductOptionVariance extends Omit<ProductOption, 'values'> {
    values: string[]
}

export interface HybrisMedia {
    altText: string
    mediaType: string
    isListingThumbnailImage: string
    url: string
    displayPriority?: number
    thumbnail?: string
}

export interface ProductOptionValue {
    value: string // Red, Blue, Green etc.
    id: string // '100'
    skuCodes: string[] // ["1872500", "1872504"]
    mediaSet?: HybrisMedia[]
    styleCodes?: string[]
    // Below attributes are not present in new CDS interface
    quantity: number
    sequence: number
    isLeaf: boolean
    currentPrice?: Price
    originalPrice?: Price
    saleCut?: SaleCut
    badges?: string[]
    displayWasLabel?: boolean
    priceMessage?: PriceMessage[]
    promoMessages?: PromoMessageType[]
    restrictedPromoMessages?: RestrictedPromoMessageType[]
    saleEndDate?: string
    variantProductDataOptionsValue?: string
    variantProductDataOptionsLabel?: string
    displayNameForVariants?: string
    isUnavailableDueToStock?: boolean
}

export enum CorporateStatus {
    AVAILABLE = 'ACT',
    FULLY_DISCONTINUED = 'FD',
    DISCONTINUED_WHEN_OUT = 'DWO',
    SEASONALLY_DISCONTINUED = 'SD',
    TEMPORARY_DISCONTINUED = 'TD',
}
export interface VariantGroup {
    specifications: Specification[]
    items: Variant[]
}

interface Specification {
    label: string
    value: string
}

export interface Variant {
    sku: string
    active: boolean
    sellable: boolean
    orderable: boolean
    price: number
    timeTestedPrice: number | null
    ecoFee: iPrice
    feeTitle: string
    badges: string[]
    itemDimensions: string[]
    inventory: number
    storeShelfLocation: string
    maxPurchasableQty: number
}

export interface ProductResponseErrorDTO {
    data: string | null
    status: number | null
}

export interface StickyBuyBoxCTARequestPayload {
    event: MouseEvent | null
    isClicked: boolean
    clickedCTAName?: string
    isToastDisplayed?: boolean
}

export interface SizeChartDataType {
    title?: string
    measure?: MeasureDescriptionType[]
    tables: TableDataType[]
    rowTitleAvailable: boolean
}
export type SelectedVariantIdType = string | number

export interface ProductState {
    productData: ProductResponseData
    productSkuData: ProductSkusData
    imageSelectedUrl: string
    selectedCurrentPrice: Price
    selectedFirstVariant: string
    selectedFirstVariantDisplayName?: string
    selectedSecondVariant: string
    selectedProductCode: string
    selectedThirdVariant: string
    selectedStoreId: string
    selectedFirstVariantId: SelectedVariantIdType
    selectedSecondVariantId: SelectedVariantIdType
    selectedThirdVariantId: SelectedVariantIdType
    isStickyBuyboxCTAClicked: StickyBuyBoxCTARequestPayload
    showAddToCartOnStickyBuyBox: boolean
    isAllVariantSelected: boolean
    fbtProductCode: string[]
    buyBoxPropsState: BuyboxProps
    setOf4Label?: string
    isOutOfStock: boolean
    quantitySelected: number
    rearQuantitySelected: number
    frontQuantitySelected: number
    fbtProductSkuData: ProductSkusData
    variantsProductSkuData: ProductSkusData
    productErrorResponse?: ProductResponseErrorDTO
    isTireOrWheelShopWithNoVehicle: boolean
    isSKUPresentInUrl?: boolean
    isProductDataAvailable?: boolean
    isPriceAvailabilityApiDone?: boolean
    isVehicleChanged?: boolean
    isOOSCurrentStore?: boolean | null
    isCartFlyoutOpen: boolean
    longDescription?: string
    seodescription?: string
    seoDescription?: string
    seoTitle?: string
    name?: string
    displayName?: string
    featureBullets?: string
    noIndex?: boolean
    noFollow?: boolean
    sizeChartData?: SizeChartDataType
    skuNotFound?: boolean
    brandList: BrandValues[]
    selectedPartNumber?: string
    productListForABTestProps?: ProductDataForABTest
    isEcommOnly?: boolean
    isEndlessAisle?: boolean
    isHeliumInflationSelected?: boolean
    appointmentAvailabilityDateTimeSlotsLoading: boolean
    appointmentAvailabilityDateTimeSlots: AppointmentAvailabilityDateTimeSlotsData | null
    appointmentAvailabilityDateTimeSlotsError: AppointmentAvailabilityDateTimeSlotsError | null
    isPriceCallCompletedForSwatches?: boolean
    isATCHeliumInflationToggleOffError?: boolean
}

export interface TriangleSelectBenefitsType {
    tsBrand?: boolean
    tsBrandDiscount: number
    tsEverydayDiscount: number
    tsBrandDiscountValue: number
    tsEverydayDiscountValue: number
}

export interface PriceBenefitsType {
    price: Price
    tSelectBenefits?: TriangleSelectBenefitsType
}
export interface BrandValues {
    value: string
    text: string
}

export interface SelectedVariant {
    variantLevel: string
    selectedVariant: string
    selectedFirstVariantDisplayName?: string
}

export interface SelectedVariantID {
    variantLevel: string
    selectedVariantID: SelectedVariantIdType
}
