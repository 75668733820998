import React from 'react'
import { useSelector } from 'react-redux'
import { ProductCardsContainerProps } from '../ShopTheLookComponent.type'
import { AkamaiImagePolicies } from '../../../akamaiPolicy/akamaiPolicy.service'
import { ProductCard, libUtils, appendQueryParamsToUrl, getAccessibilityId } from '@nl/lib'
import { commonContentSelector, commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import { IAccessibility, IProduct } from '../../../redux/models/commonContent.interface'
import { ProductCardsShoppableData } from '../../../redux/models/recommendations.interface'
import { PREFIX } from '../../../config'
import { RecommendationResponseDataDTO, ProductCardType } from '../../../redux/models/recommendations.interface'
import { constantValues } from '../ShopTheLookComponent.constant'
import { RootState } from '../../../redux/reducers'
import ProductCardsSkeleton from './ProductCardsSkeleton'
import { ProductDataTypeObj } from '../../../redux/models/productData.interface'

const componentClassName = `${PREFIX}-product-cards-container`

/**
 * ProductCardsContainer
 * @param props : ProductCardsContainerProps
 * @returns : JSX.Element
 */
const ProductCardsContainer: React.FC<ProductCardsContainerProps> = ({ ...props }): JSX.Element => {
    const { productListCode, productsList, productCardClickHandler, productCardCount, sectionTitle } = props
    const { recommendationsData: recommendationRootState } = useSelector(
        (state: RootState) => state.recommendationsData,
    )

    const productCardAllData =
        recommendationRootState.find((productData: RecommendationResponseDataDTO) => productData.scheme === props.title)
            ?.productData || []
    const productCardData: ProductCardType[] = []
    productListCode.forEach(product => {
        const filteredProductData = productCardAllData.find(productData => productData.code === product.toUpperCase())
        filteredProductData && productCardData.push(filteredProductData)
    })

    const language = libUtils.getLanguage()
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { accessibility = {} as IAccessibility, product: commonContentProduct = {} as IProduct } =
        useSelector(commonContentAvailableSelector)

    const {
        a11yClickToReadFootnote,
        a11yTooltipIcon,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yCloseIconLabel,
    } = accessibility

    const {
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        thresholdValue,
    } = commonContentProduct

    const productProps = {
        ratingsAndReview: true,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: thresholdValue,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    /**
     * On Product Card Click open pdp page for passed product Code
     * @param {React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>} event event
     * @param {ProductCardType|ProductDataTypeObj} productData productData
     * @param {number} idx product index to pass to parent
     * @returns {void}
     */
    const cardClickHandler = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
        productData: ProductCardType | ProductDataTypeObj,
    ): void => {
        const product = productsList.find(item => item.productCode.toUpperCase() === productData.code)
        const type = product?.analyticsTrackingId || ''
        productCardClickHandler(productData, type)
    }

    const productList = (productItem: ProductCardsShoppableData, index: number) => {
        const imageDataComponentName = 'product-card'

        const analyticsUrlParams = {
            loc: constantValues.analyticsLocValueForShopTheLookComponent,
        }

        const filteredBadges =
            productItem.isOnSale && productItem.badges
                ? productItem.badges.filter(badge => (constantValues.acceptedBadges as string[]).includes(badge))
                : []

        return (
            <ProductCard
                product={productItem}
                url={appendQueryParamsToUrl(productItem.url, analyticsUrlParams)}
                key={index}
                productProps={productProps}
                idx={index}
                cardType="grid"
                title={productItem.title}
                type={productItem.type}
                images={productItem.images}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                showRatingSection
                badges={filteredBadges}
                badgePriorities={commonContentAvailable.badges}
                language={language}
                currentPrice={productItem.currentPrice}
                imageDataComponentName={imageDataComponentName}
                returnPolicy={AkamaiImagePolicies.returnPolicy}
                thresholdValue={thresholdValue}
                productDataId={productItem.code}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                a11yCloseIconLabel={a11yCloseIconLabel}
                a11yTooltipIcon={a11yTooltipIcon}
                enableBadges={true}
                showSaleClearanceBadge={true}
                rating={productItem.rating}
                ratingsCount={productItem.ratingsCount}
                brand={productItem.brand}
                isOnSale={productItem.isOnSale}
                productCardClick={(event, product) => cardClickHandler(event, product)}
                accessibilityId={getAccessibilityId(props.title, productItem?.skus?.[0]?.code || productItem.code)}
                sectionTitle={sectionTitle}
            />
        )
    }

    return productCardData?.length > 0 ? (
        <div className={`${PREFIX}-product__grid-view`}>
            <div className={`${componentClassName} ${PREFIX}-${props.componentWidthType}`}>
                {productCardData.map((product, index) => (
                    <div
                        className={`${PREFIX}-product-card`}
                        data-testid="product-card-component"
                        key={product.code}
                        aria-label={`${index} of ${productCardCount}`}>
                        {productList(product, index)}
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <ProductCardsSkeleton
            productCardCount={props.productCardCount}
            componentWidthType={`${props.componentWidthType}`}></ProductCardsSkeleton>
    )
}

export default ProductCardsContainer
