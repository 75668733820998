import { ErrorTypes } from '../config'
import { CartResponseErrorDTO } from '../redux/models/cart.interface'
import { getEstimatedTimeOfSendingProduct, magicNumber } from '@nl/lib'

/**
 * check express delivery error codes if UnavailableExpressEntry or MixedHomeDeliveryModes errors are present
 * @param {CartResponseErrorDTO} placeOrderError
 * @return {boolean}
 */
export const checkExpressDeliveryErrCodes = (placeOrderError: CartResponseErrorDTO): boolean => {
    const errCodes = placeOrderError?.data?.errors
    return (
        errCodes?.includes(ErrorTypes.HybrisErrorTypes.UnavailableExpressEntry) ||
        errCodes?.includes(ErrorTypes.HybrisErrorTypes.MixedHomeDeliveryModes)
    )
}

/**
 * function for getting actual date, if date is overdue, function returns date + 1 day
 * @param {boolean} isOrderInOverdue
 * @param {string} date
 * @return {string}
 */
export const getExpressDeliveryDate = (isOrderInOverdue: boolean, date: string): string => {
    const deliveryDate = new Date(date)
    return isOrderInOverdue
        ? new Date(
              deliveryDate.getTime() +
                  magicNumber.TWENTYFOUR * magicNumber.SIXTY * magicNumber.SIXTY * magicNumber.THOUSAND,
          ).toLocaleString()
        : date
}

/**
 * function for getting estimated time of sending a product if order in time is overdue function returns 24h 0m
 * @param {boolean} isOrderInOverdue
 * @param {string} estimatedTimeOfSending - estimated time of sending a product
 * @param {string} storeTimeZone
 * @return {number[]}
 */
export const getOrderInTime = (
    isOrderInOverdue: boolean,
    estimatedTimeOfSending: string,
    storeTimeZone: string,
): number[] => {
    return isOrderInOverdue
        ? [magicNumber.TWENTYFOUR, magicNumber.ZERO]
        : getEstimatedTimeOfSendingProduct(estimatedTimeOfSending, storeTimeZone)
}
