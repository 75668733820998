import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ProductCardsContainerProps } from '../ShoppableComponent.type'
import { AkamaiImagePolicies } from '../../../akamaiPolicy/akamaiPolicy.service'
import { ProductCard, libUtils } from '@nl/lib'
import { commonContentSelector, commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import { IAccessibility, IProduct } from '../../../redux/models/commonContent.interface'
import { ProductCardsShoppableData } from '../../../redux/models/recommendations.interface'
import { PREFIX } from '../../../config'
import { RecommendationResponseDataDTO } from '../../../redux/models/recommendations.interface'
import { constantValues } from './../ShoppableComponent.constant'
import { RootState } from './../../../redux/reducers'
import ProductCardsSkeleton from './ProductCardsSkeleton'
import { getAccessibilityId } from '../../../../../lib/src/utils/getAccessibilityId'
import { appendQueryParamsToUrl } from '../../../../../lib/src/utils'

const componentClassName = `${PREFIX}-product-cards-container`

/**
 * ProductCardsContainer
 * @param props : ProductCardsContainerProps
 * @returns : JSX.Element
 */
const ProductCardsContainer: React.FC<ProductCardsContainerProps> = ({ ...props }): JSX.Element => {
    const { recommendationsData: recommendationRootState } = useSelector(
        (state: RootState) => state.recommendationsData,
    )

    const productCardData =
        recommendationRootState.find((productData: RecommendationResponseDataDTO) => productData.scheme === props.title)
            ?.productData || []

    const language = libUtils.getLanguage()
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { accessibility = {} as IAccessibility, product: commonContentProduct = {} as IProduct } =
        useSelector(commonContentAvailableSelector)

    const {
        a11yClickToReadFootnote,
        a11yTooltipIcon,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yCloseIconLabel,
    } = accessibility

    const {
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        thresholdValue,
    } = commonContentProduct

    const productProps = {
        ratingsAndReview: false,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: thresholdValue,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    const productList = (productItem: ProductCardsShoppableData, index: number) => {
        const imageDataComponentName = 'product-card'

        const analyticsUrlParams = {
            loc: constantValues.analyticsLocValueForShoppableComponent,
        }

        const filteredBadges =
            productItem.isOnSale && productItem.badges
                ? productItem.badges.filter(badge => (constantValues.acceptedBadges as string[]).includes(badge))
                : []

        return (
            <ProductCard
                product={productItem}
                url={appendQueryParamsToUrl(productItem.url, analyticsUrlParams)}
                key={index}
                productProps={productProps}
                idx={index}
                cardType="grid"
                title={productItem.title}
                type={productItem.type}
                images={productItem.images}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                showRatingSection={true}
                badges={filteredBadges}
                badgePriorities={commonContentAvailable.badges}
                language={language}
                currentPrice={productItem.currentPrice}
                imageDataComponentName={imageDataComponentName}
                returnPolicy={AkamaiImagePolicies.returnPolicy}
                thresholdValue={thresholdValue}
                productDataId={productItem.code}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                a11yCloseIconLabel={a11yCloseIconLabel}
                a11yTooltipIcon={a11yTooltipIcon}
                enableBadges={true}
                showSaleClearanceBadge={true}
                rating={productItem.rating}
                ratingsCount={productItem.ratingsCount}
                brand={productItem.brand}
                isOnSale={productItem.isOnSale}
                accessibilityId={getAccessibilityId(
                    props.title,
                    productItem?.skus?.[0]?.code ? productItem?.skus?.[0]?.code : productItem.code,
                )}
            />
        )
    }

    return productCardData && productCardData.length > 0 ? (
        <div className={`${PREFIX}-product__grid-view`}>
            <div className={`${componentClassName} ${PREFIX}-${props.componentWidthType}`}>
                {productCardData.map((product, index) => (
                    <div className={`${PREFIX}-product-card`} data-testid="product-card-component" key={index}>
                        {productList(product, index)}
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <ProductCardsSkeleton
            productCardCount={props.productCardCount}
            componentWidthType={`${props.componentWidthType}`}></ProductCardsSkeleton>
    )
}

ProductCardsContainer.propTypes = {
    componentWidthType: PropTypes.string.isRequired,
    productCardCount: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
}

export default ProductCardsContainer
