import appCacheService from '../utils/appCacheService'
import { CertonaInitialization } from '../certona/certona.service'
import { getEnvironment } from '../environments'
import { CertonaConstants, libUtils } from '@nl/lib'

/**
 * Trigger resonance call with customize param
 * @param {string} itemId
 * @param {string} event
 * @param {boolean} isCertonaRecItemAdded
 * @returns {void}
 */
export function customizeCertonaInitialization(itemId: string, event: string, isCertonaRecItemAdded = false): void {
    CertonaInitialization.triggerCertona({
        event: event,
        environment: getEnvironment(),
        recommendations: true,
        itemid: itemId,
        isCertonaRecItemAdded: isCertonaRecItemAdded,
        filter: {
            storeid: appCacheService.preferredStoreId.get() || '',
            currencycode: CertonaConstants.CURRENCY,
            language: libUtils.getLanguage(),
            fitmentproducts: false,
        },
    })
}

export default customizeCertonaInitialization
