import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { QuantitySelector, isArrayNotEmpty, libUtils, replaceStrWithDynamicVal } from '@nl/lib'

import { PREFIX } from '../../../../config'
import HandleToast from '../../../BuyBox/HandleToast/HandleToast'
import {
    addProductToCartGuest,
    setStickyBuyboxCTAClicked,
    setIfAllVariantsSelected,
    setStickyBuyBarATCSpinner,
} from '../../../../redux/actions'
import localStorageService from '../../../../utils/localStorageService'
import { analyticsAttributes } from '../../../../globalConstants'
import { AddProductToCartGuestRequestDTO } from '../../../../redux/models/cart.interface'
import { checkDataLength } from '../../../Accounts/Addresses/checkDataLength'
import { createOrderEntriesPayload, getFulfillmentMethod, getIsBulk, isBopis } from '../../../BuyBox/BuyBox.helper'

import { MagicNumber } from '../../../../analytics/analytics.type'
import { storeDetailsDataSelector } from '../../../../redux/selectors/storeDetails.selectors'
import { addRemovePayLoad, WishlistProduct } from '../../../../redux/models/wishlist.interface'
import { wishlistItemsSelector } from '../../../../redux/selectors/wishlist.selectors'
import { commonContentSelector } from '../../../../redux/selectors/commonContent.selectors'
import { IAccessibility } from '../../../../redux/models/commonContent.interface'

import ATCWishlistBtnsComponent from '../../../BuyBox/ATCWishlistBtnsComponent/ATCWishlistBtnsComponent'
import { cartStateSelector } from '../../../../redux/selectors/cart.selectors'
import { addModalAttribute } from '@nl/lib/src/utils'

import { miniPDPFlyoutDetailsSelector } from '../../../../redux/selectors/miniPDPFlyoutData.selectors'
import { ATCQuantityWishlistProps } from './ATCQuantityWishlist.type'
import { closeMiniPDPFlyoutAction, isMiniPDPAtcButtonClicked } from '../../../../redux/actionCreators'
import { isObjectNotEmpty } from '../../../../utils/isObjectNotEmpty'
import { addToCartAnalytics } from '../../../../analytics/components/addToCartAnalytics'

/**
 *
 * @param props
 */
const ATCQuantityWishlist: React.FC<ATCQuantityWishlistProps> = (props: ATCQuantityWishlistProps) => {
    const {
        minQuantity,
        freeShippingEnabled,
        freePickUpInStoreLabel,
        goToPdpButtonLabel,
        variantNotSelectedMessage,
        isAddToCartClicked,
        setIsAddToCartClicked,
        isAddToWishlistClicked,
        setIsAddToWishlistClicked,
        setIsVariantSelected,
        isShipToHome,
        unavailableFulfillmentSection,
        isOOSAtStoreForPickUp,
        selectedFulfillment,
        bopisUnavailable,
        isExpressDelivery,
        isExpressDeliveryEligible,
        isExpressDeliveryEnabledForProduct,
        isSelectAStoreButtonAvailable,
        inStockAtNearbyStores,
        onlineOrdering,
        isBopisOnlySkuWithStoreBopisUnavailable,
        isBopisOnlyUnavailableForPickupInStock,
        isProductDiscontinued,
        isOutOfStockInAllStore,
        isStoreSelected,
        maxQuantityAvailable,
        inStockAtDC,
        quantityExceeded,
        availableForATC,
        qtySelectorErrorMsg,
        setQtySelectorErrorMsg,
        maxPurchasableLimitLabel,
        limitMsgValue,
        showErrorMsgForVariantSelection,
        setShowErrorMsgForVariantSelection,
        isNoSkuFindStoreClicked,
        setIsNoSkuFindStoreClicked,
        qtySelectorInput,
        setQtySelectorInput,
        enableFulfilmentSelection,
    } = props

    const dispatch = useDispatch()
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { productDataList, currentCode } = useSelector(miniPDPFlyoutDetailsSelector)
    const { variantsInfo, priceInfo, fulfillmentInfo } = productDataList[currentCode] || {}
    const {
        productData,
        selectedProductCode,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        productSkuData,
        isAllSkusOOS,
        isAllVariantsSelected,
    } = variantsInfo || {}
    const { productDataBySku, isCurrentPriceAvailable } = priceInfo
    const { currentPrice } = productDataBySku || {}
    const { isLimitedPDP, isRestrictedPDP, selectedStoreQty, inStockInSelectedStore, isProductSellable } =
        fulfillmentInfo

    const [showUnavailableFulfillmentToast, setShowUnavailableFulfillmentToast] = useState(false)
    const [shouldShowSelectStoreToast, setShouldShowSelectStoreToast] = useState(false)
    const [toastErrorMessage, setToastErrorMessage] = useState('')

    // getting entire wishlist Data
    const wishlistItems = useSelector(wishlistItemsSelector)

    const { addToCartErrorData, cartModifications, finalCartModifications, cartLimitItemsFailure } =
        useSelector(cartStateSelector)

    const { selectedStoreName } = useSelector(storeDetailsDataSelector)

    const { accessibility = {} as IAccessibility } = commonContentAvailable

    const divRef = useRef<HTMLDivElement>(null)

    const [addToCartErrorMessage, setAddToCartErrorMessage] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)

    /** Check if GUID is available (Cart already created) then call add product to cart api otherwise call create cart api  */
    const addProductToCartAPI = useCallback(() => {
        const cartGUID = localStorageService.getItem('cart-guid') as string
        const deliveryMethod = !enableFulfilmentSelection || isShipToHome

        // TODO: payload creation should move to service
        const cartData: AddProductToCartGuestRequestDTO = {
            guid: cartGUID,
            entries: {
                orderEntries: createOrderEntriesPayload(
                    libUtils.getNumberOrDefault(qtySelectorInput),
                    selectedProductCode,
                    productData,
                    undefined,
                    libUtils.getStringOrDefault(''),
                    deliveryMethod,
                    isExpressDelivery,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    productSkuData,
                    undefined,
                    false,
                    false,
                ),
            },
        }

        const isBulk = getIsBulk(productData, selectedProductCode)
        dispatch(
            addProductToCartGuest(
                cartData,
                false,
                false,
                true,
                undefined,
                undefined,
                isBulk,
                undefined,
                undefined,
                false,
                false,
                false,
            ),
        )
    }, [
        selectedProductCode,
        enableFulfilmentSelection,
        isShipToHome,
        qtySelectorInput,
        dispatch,
        productData,
        productSkuData,
    ])

    const addCartClicked = useCallback(
        // eslint-disable-next-line complexity
        (addCartMouseEvent?: React.MouseEvent<Element, MouseEvent> | MouseEvent) => {
            addCartMouseEvent && addModalAttribute(addCartMouseEvent as MouseEvent)
            setIsAddToCartClicked(true)
            setIsAddToWishlistClicked(false)
            setIsNoSkuFindStoreClicked(false)
            let isStoreSelectedValue = true

            if (!isAllVariantsSelected) {
                setShowErrorMsgForVariantSelection(true)
            }

            if (enableFulfilmentSelection) {
                if (unavailableFulfillmentSection() || !availableForATC) {
                    setShowUnavailableFulfillmentToast(true)
                }

                // Check if store is selected from fulfillment section
                if (!inStockInSelectedStore && inStockAtNearbyStores()) {
                    isStoreSelectedValue = !!selectedStoreName
                }

                if (
                    (isBopis(selectedFulfillment, freePickUpInStoreLabel) &&
                        !bopisUnavailable &&
                        isOOSAtStoreForPickUp() &&
                        availableForATC &&
                        isAllVariantsSelected) ||
                    (!freeShippingEnabled &&
                        isExpressDelivery &&
                        isExpressDeliveryEligible() &&
                        isExpressDeliveryEnabledForProduct() &&
                        selectedStoreQty === 0)
                ) {
                    setShouldShowSelectStoreToast(true)
                }
            }

            // TODO: validation logic can be improved to be more readable. recommend performing all validation checks before call to addProductToCartAPI
            // OCCP-14639: override quantity
            if (
                isAllVariantsSelected &&
                isCurrentPriceAvailable &&
                !unavailableFulfillmentSection() &&
                !quantityExceeded &&
                availableForATC &&
                (!!selectedStoreName || inStockInSelectedStore || (inStockAtDC && isStoreSelectedValue))
            ) {
                setShowSpinner(true)
                addProductToCartAPI()
                setIsAddToCartClicked(false)
                dispatch(isMiniPDPAtcButtonClicked(true))
            }

            // Below Dispatch is used to reset the redux variables.
            dispatch(
                setStickyBuyboxCTAClicked({
                    event: null,
                    isClicked: false,
                    clickedCTAName: '',
                }),
            )
        },
        [
            dispatch,
            quantityExceeded,
            selectedStoreName,
            inStockInSelectedStore,
            inStockAtDC,
            addProductToCartAPI,
            freePickUpInStoreLabel,
            selectedStoreQty,
            isCurrentPriceAvailable,
            freeShippingEnabled,
            availableForATC,
            isAllVariantsSelected,
            bopisUnavailable,
            isExpressDelivery,
            enableFulfilmentSelection,
            isExpressDeliveryEligible,
            isExpressDeliveryEnabledForProduct,
            isOOSAtStoreForPickUp,
            isStoreSelected,
            selectedFulfillment,
            setIsAddToCartClicked,
            setIsAddToWishlistClicked,
            unavailableFulfillmentSection,
            setIsNoSkuFindStoreClicked,
            setShowErrorMsgForVariantSelection,
        ],
    )

    /* Check If All Variant Selected For Frequently Brought Together Component */
    useEffect(() => {
        dispatch(setIfAllVariantsSelected(isAllVariantsSelected))
        setIsVariantSelected(isAllVariantsSelected)
    }, [dispatch, setIsVariantSelected, isAllVariantsSelected])

    useEffect(() => {
        if (isObjectNotEmpty(cartModifications)) {
            const cartGUID = localStorageService.getItem('cart-guid')
            const fulfillmentType = getFulfillmentMethod(isShipToHome, isExpressDelivery)
            addToCartAnalytics(
                finalCartModifications,
                cartGUID,
                analyticsAttributes.eventParameters.location.miniPDP,
                analyticsAttributes.eventParameters.labels.miniPdpAddToCart,
                false,
                false,
                false,
                false,
                undefined,
                undefined,
                fulfillmentType ?? '',
                '',
            )
            dispatch(closeMiniPDPFlyoutAction())
        }
        setShowSpinner(false)
    }, [addToCartErrorData, cartModifications, cartLimitItemsFailure])

    /**
     * useEffect to set sticky buybar ATC spinner state
     */
    useEffect(() => {
        dispatch(setStickyBuyBarATCSpinner(showSpinner))
    }, [dispatch, showSpinner])

    // Run the code when the data changes,
    const isAddedToWishlist = useMemo(() => {
        if (checkDataLength(wishlistItems)) {
            // For the first time it is undefined when the page load.
            const wishListProductList = wishlistItems.products
            let exists = false

            // Condition to avoid some function error
            if (isArrayNotEmpty(wishListProductList)) {
                exists = wishListProductList.some((wishListProduct: WishlistProduct) => {
                    return wishListProduct.code === (selectedProductCode || productData.code)
                })
            }
            return exists
        }

        return false
    }, [wishlistItems, selectedProductCode, productData])

    const getIsShipToHome = useMemo(() => {
        return enableFulfilmentSelection ? isShipToHome : true
    }, [enableFulfilmentSelection, isShipToHome])

    // Object to be used for analytics
    const { location, labels } = analyticsAttributes.eventParameters

    const productObject: addRemovePayLoad = {
        pcode: productData.code,
        action: isAddedToWishlist ? labels.removeFromWishlist : labels.addToWishlist,
        sku: selectedProductCode,
        value: `${String(currentPrice?.value)}`,
        location: location.miniPDP,
    }
    /**
     * useEffect to set isVariantSelected if all variant 3 options are selected
     */
    useEffect(() => {
        if (productData?.options?.length === MagicNumber.TWO && selectedFirstVariantId && selectedSecondVariantId) {
            setIsVariantSelected(true)
        }
        if (selectedFirstVariantId && selectedSecondVariantId && selectedThirdVariantId) {
            setIsVariantSelected(true)
        }
    }, [selectedFirstVariantId, setIsVariantSelected, selectedSecondVariantId, selectedThirdVariantId, productData])

    const getAtcPrimaryButtonType = () => (!isAllVariantsSelected ? 'primary' : 'call_to_action')

    const checkIfRenderATCWishlistButton = useCallback((): boolean => {
        const showATCButton = isAllVariantsSelected ? !isOutOfStockInAllStore : true
        const hasAdditionalDeliveryOptions =
            !enableFulfilmentSelection ||
            (onlineOrdering && !isBopisOnlySkuWithStoreBopisUnavailable && !isBopisOnlyUnavailableForPickupInStock)

        return !isAllSkusOOS && hasAdditionalDeliveryOptions && !isLimitedPDP && !isRestrictedPDP && showATCButton
    }, [
        isAllSkusOOS,
        enableFulfilmentSelection,
        isLimitedPDP,
        isRestrictedPDP,
        isAllVariantsSelected,
        isOutOfStockInAllStore,
        isBopisOnlySkuWithStoreBopisUnavailable,
        onlineOrdering,
        isBopisOnlyUnavailableForPickupInStock,
    ])

    const checkIfAllVariantsSelected = useCallback(() => isAllVariantsSelected, [isAllVariantsSelected])
    const checkIsSelectAllOptionToShow = useCallback(() => !isAllVariantsSelected, [isAllVariantsSelected])
    const checkInStockInSelectedStore = useCallback(() => inStockInSelectedStore, [inStockInSelectedStore])
    const checkIsProductDiscontinued = useCallback(() => isProductDiscontinued, [isProductDiscontinued])
    const checkMaxQuantityAvailable = useCallback(() => maxQuantityAvailable, [maxQuantityAvailable])
    const checkInStockAtDC = useCallback(() => inStockAtDC, [inStockAtDC])
    const checkIsRestrictedPDP = useCallback(() => !!isRestrictedPDP, [isRestrictedPDP])
    const checkIsSelectAStoreButtonAvailable = useCallback(
        () => isSelectAStoreButtonAvailable,
        [isSelectAStoreButtonAvailable],
    )

    /**
     * @returns {JSX.Element}
     */
    const renderBuyBoxComponent = (): JSX.Element => {
        return (
            <div id={`${PREFIX}-buy-box`} className={`${PREFIX}-buy-box`} ref={divRef}>
                <div id={`${PREFIX}-buy-box`} className={`${PREFIX}-buy-box__qty--container`}>
                    {checkIfRenderATCWishlistButton() && (
                        <div className={`${PREFIX}-buy-box__qty-selector`}>
                            <QuantitySelector
                                defaultQty={minQuantity}
                                maximumQuantityError={qtySelectorErrorMsg}
                                maxQty={maxQuantityAvailable}
                                quantityExceeded={quantityExceeded}
                                qtySelectorInput={libUtils.getNumberOrDefault(qtySelectorInput)}
                                setQtySelectorInput={setQtySelectorInput as (number: number | string) => void}
                                addToCartErrorData={addToCartErrorMessage}
                                a11yQuantityLabel={accessibility.a11yQuantityLabel}
                                a11yQuantityDecrementLabel={accessibility.a11yQuantityDecrementLabel}
                                a11yQuantityIncrementLabel={accessibility.a11yQuantityIncrementLabel}
                                a11yMinQuantityReached={accessibility.a11yMinQuantityReached}
                                a11yQuantityInputAriaLabel={accessibility?.a11yQuantityInputAriaLabel}
                                disableErrorIcon={false}
                                limitLabel={
                                    limitMsgValue.current
                                        ? replaceStrWithDynamicVal(maxPurchasableLimitLabel, limitMsgValue.current)
                                        : undefined
                                }
                                limitLabelShow={true}
                                a11yUpdatedQuantityText={accessibility.a11yUpdatedQuantityText}
                            />
                        </div>
                    )}
                    <ATCWishlistBtnsComponent
                        a11yUpdatingWishList={props.a11yUpdatingWishList}
                        addToWishlistInactiveProductErrorMsg={props.addToWishlistInactiveProductErrorMsg}
                        a11yRemoveFromWishList={props.a11yRemoveFromWishList}
                        a11yAddToWishList={props.a11yAddToWishList}
                        addToWishListLabel={props.addToWishListLabel}
                        addedToWishListLabel={props.addedToWishListLabel}
                        wishListNonSellableEnabled={props.wishListNonSellableEnabled}
                        addToPackageCTA={props.addToPackageCTA}
                        buyboxButtonLabel={props.buyboxButtonLabel}
                        selectAllOptionsLabel={props.selectAllOptionsLabel}
                        addCartClicked={addCartClicked}
                        isAddedToWishlist={isAddedToWishlist}
                        criticalFitmentGrouped={{ isStaggered: false }}
                        setIsAddToWishlistClicked={setIsAddToWishlistClicked}
                        checkIfAllVariantsSelected={checkIfAllVariantsSelected}
                        setShowErrorMsgForVariantSelection={setShowErrorMsgForVariantSelection}
                        isAutomotive={false}
                        isTireSizePresent={false}
                        homeService={false}
                        setIsVariantSelected={setIsVariantSelected}
                        setIsNoSkuFindStoreClicked={setIsNoSkuFindStoreClicked}
                        scrollOnToastDisplayed={() => false}
                        productObject={productObject}
                        inStockInSelectedStore={checkInStockInSelectedStore}
                        isProductSellable={isProductSellable}
                        isRestrictedPDP={checkIsRestrictedPDP}
                        isDisabledATC={false}
                        isSelectAStoreButtonAvailable={checkIsSelectAStoreButtonAvailable}
                        showSpinner={showSpinner}
                        isOutOfStockInAllStore={!!isOutOfStockInAllStore}
                        isBopisOnlyUnavailableForPickupInStock={isBopisOnlyUnavailableForPickupInStock}
                        isBopisOnlySkuWithStoreBopisUnavailable={isBopisOnlySkuWithStoreBopisUnavailable}
                        getAtcPrimaryButtonType={getAtcPrimaryButtonType}
                        isSelectAllOptionToShow={checkIsSelectAllOptionToShow}
                        needRenderATCWishlistButton={checkIfRenderATCWishlistButton()}
                        flyoutVariantsInfoData={variantsInfo}
                    />
                </div>
                <div className={`${PREFIX}-buy-box-link`}>
                    <a
                        href={productData?.pdpUrl}
                        className={''}
                        aria-label={goToPdpButtonLabel}
                        dap-wac-loc={location.miniPDP}
                        dap-wac-value={goToPdpButtonLabel}>
                        {goToPdpButtonLabel}
                    </a>
                </div>
            </div>
        )
    }

    return (
        <>
            <HandleToast
                isAddedToWishlist={isAddedToWishlist}
                isAddToWishlistClicked={isAddToWishlistClicked}
                isShipToHome={getIsShipToHome}
                isAddToCartClicked={isAddToCartClicked}
                setIsAddToCartClicked={setIsAddToCartClicked}
                setIsAddToWishlistClicked={setIsAddToWishlistClicked}
                isNoSkuFindStoreClicked={isNoSkuFindStoreClicked}
                setIsNoSkuFindStoreClicked={setIsNoSkuFindStoreClicked}
                setToastErrorMessage={setToastErrorMessage}
                toastErrorMessage={toastErrorMessage}
                showErrorMsgForVariantSelection={showErrorMsgForVariantSelection}
                setShowErrorMsgForVariantSelection={setShowErrorMsgForVariantSelection}
                showUnavailableFulfillmentToast={showUnavailableFulfillmentToast}
                setShowUnavailableFulfillmentToast={setShowUnavailableFulfillmentToast}
                checkIfAllVariantsSelected={checkIfAllVariantsSelected}
                setAddToCartErrorMessage={setAddToCartErrorMessage}
                inStockInSelectedStore={checkInStockInSelectedStore}
                shouldShowSelectStoreToast={shouldShowSelectStoreToast}
                setShouldShowSelectStoreToast={setShouldShowSelectStoreToast}
                isProductDiscontinued={checkIsProductDiscontinued}
                variantNotSelectedMessage={variantNotSelectedMessage}
                addToWishlistMessage={props.addToWishlistMessage}
                addToWishlistMessageGuest={props.addToWishlistMessageGuest}
                itemRemovedFromWishlistMsg={props.itemRemovedFromWishlistMsg}
                failureCloseLabel={props.failureCloseLabel}
                quantityExceeded={quantityExceeded}
                getMaxQuantityAvailable={checkMaxQuantityAvailable}
                inStockAtDC={checkInStockAtDC}
                isSeparatedErrorHandling={props.isSeparatedErrorHandling}
                qtySelectorErrorMsg={qtySelectorErrorMsg}
                setQtySelectorErrorMsg={setQtySelectorErrorMsg}
                exceedMaximumSellQuantityMessage={props.exceedMaximumSellQuantityMessage}
                exceedMaximumPurchableMessage={props.exceedMaximumPurchableMessage}
                wishlistMaxLimitMessage={props.wishlistMaxLimitMessage}
                isATCErrorHandling={props.isATCErrorHandling}
                prodOOSErrMsg={props.prodOOSErrMsg}
            />
            {renderBuyBoxComponent()}
        </>
    )
}

export default ATCQuantityWishlist
