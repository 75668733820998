import React from 'react'
import { useSelector } from 'react-redux'
import { PREFIX } from '../../config'
import { string, bool, func, array } from 'prop-types'
import { Button, isArrayNotEmpty } from '@nl/lib'
import wrapIntoPopup from '../../utils/hoc/wrapIntoPopup'

import { ErrorPopupProps } from './ErrorPopup.types'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { IFedErrors } from '../../redux/models/commonContent.interface'

const ErrorPopup: React.FC<ErrorPopupProps> = ({ ...props }) => {
    const {
        onCloseClickHandler,
        errorTitle,
        errorSubtitle,
        errorButtonLabel,
        customButtons,
        isUnwrappedBody,
        closePopupButtonLabel,
    } = props

    const { commonContentAvailable: { fedErrors = {} as IFedErrors } = {} } = useSelector(commonContentSelector)
    const { errorPopupDefaultTitle, errorPopupDefaultSubtitle, errorPopupCloseButtonDefaultText } = fedErrors

    const buttonsRender = isArrayNotEmpty(customButtons) ? (
        customButtons
    ) : (
        <Button type="tertiary" size="medium" onClick={onCloseClickHandler}>
            {errorButtonLabel || errorPopupCloseButtonDefaultText}
        </Button>
    )

    const PopupRenderer: React.FC<{ closePopupButtonLabel?: string }> = () => {
        const title = errorTitle || errorPopupDefaultTitle
        const subtitle = errorSubtitle || errorPopupDefaultSubtitle
        return (
            <div className={`${PREFIX}-error-popup__body`} data-testid="error-popup">
                <div className={`${PREFIX}-error-popup__error-body-container`}>
                    <span className={`${PREFIX}-error-popup__title`}>{title}</span>
                    <span className={`${PREFIX}-error-popup__subTitle`}>{subtitle}</span>
                </div>
                <div className={`${PREFIX}-error-popup__error-buttons-container`}>{buttonsRender}</div>
            </div>
        )
    }

    const ErrorPopupRender = isUnwrappedBody ? PopupRenderer : wrapIntoPopup(PopupRenderer, onCloseClickHandler)

    return <ErrorPopupRender closePopupButtonLabel={closePopupButtonLabel} />
}

ErrorPopup.propTypes = {
    errorTitle: string,
    errorSubtitle: string,
    errorButtonLabel: string,
    onCloseClickHandler: func,
    customButtons: array,
    isUnwrappedBody: bool,
}

export default ErrorPopup
