import React from 'react'
import { string } from 'prop-types'
import { BrowseOnlyModeInformationBannerProps } from './BrowseOnlyInformationBanner.type'
import { PREFIX } from '../../config'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'

const BrowseOnlyInformationBanner: React.FC<BrowseOnlyModeInformationBannerProps> = ({ ...props }) => {
    const isBrowseOnlyEnabled = useSelector((state: RootState) => state.browseOnly.enable)
    const { orientation, bgcolor, infoText } = props

    return isBrowseOnlyEnabled ? (
        <div className={`${PREFIX}-full-width-container ${PREFIX}-browse-only-information-banner ${bgcolor}`}>
            <div className={`${PREFIX}-container ${PREFIX}-row`}>
                <div className={`${PREFIX}-col-sm-12 ${PREFIX}-col-md-12 ${PREFIX}-col-xs-6`}>
                    <div
                        className={`${PREFIX}-browse-only-information-banner ${PREFIX}-browse-only-information-banner--align-${orientation}`}
                        dangerouslySetInnerHTML={{ __html: infoText }}></div>
                </div>
            </div>
        </div>
    ) : null
}

BrowseOnlyInformationBanner.propTypes = {
    orientation: string.isRequired,
    bgcolor: string.isRequired,
    infoText: string.isRequired,
}

export default BrowseOnlyInformationBanner
