import React, { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { checkDataLength, isArrayEmpty, useIsMobile } from '@nl/lib'

import HallOfFameProductGrid from './HallOfFameProductGrid'
import { HallOfFameProps, HeaderProps } from './HallOfFame.types'

import { hallOFameTitle, hallOfFame } from './HallOfFame.classnames'
import { isTirePDP, isWheelPDP } from '../../Vehicles/Vehicle.helper'
import { breadcrumbListSelector, productWheelTypeSelector } from '../../../redux/selectors/categoryIdData.selectors'
import { CATEGORY_LVL_TO_PUT_HALL_OF_FAME_ON } from './HallOfFame.constants'
import { tiresDataSelector } from '../../../redux/selectors/vehicleTires.selectors'
import useHOFProductRow from './useHOFProductRow'

/**
 * @description HallOfFame: Featured Product for L3 categories: tires and wheels
 * @param {HallOfFameProps} props - HallOfFameProps
 * @returns {JSX.Element|null} - HallOfFame component
 */
function HallOfFame({ tires, wheels, productDetailsProps }: HallOfFameProps): JSX.Element | null {
    const productWheelType = useSelector(productWheelTypeSelector)
    const breadcrumbList = useSelector(breadcrumbListSelector)

    const isMobile = useIsMobile()
    const tiresData = useSelector(tiresDataSelector)

    const isTirePLP = isTirePDP(productWheelType)
    const isWheelsPLP = isWheelPDP(productWheelType)
    const isTireSizeSearch = isTirePLP && checkDataLength(tiresData)

    const props = isTirePLP ? tires : isWheelsPLP ? wheels : null

    // Main logic to decide show\hide component and trigger or not API call on changes.
    const showHallOfFame = useMemo(
        () =>
            // permanent toggle check
            props?.displayComponent &&
            // not show for category L1 and L2, pass throw empty breadcrumb should prevent hiding of skeleton before category data is fully load
            (isArrayEmpty(breadcrumbList) || breadcrumbList?.length >= CATEGORY_LVL_TO_PUT_HALL_OF_FAME_ON) &&
            // Hiding on mobile hide checkbox
            (!isMobile || !props?.hideOnMobile) &&
            // Hiding and restoring on selecting tireSizeData
            (!isTireSizeSearch || tires?.displayComponentForTireSizeSearch),
        [props, tires, isMobile, isTireSizeSearch, breadcrumbList],
    )

    const [temporalHide, setTemporalHide] = useState<boolean>(false)

    // additional hiding for empty result what hide only visual aspect but component is still exist to track vehicle changes and able recall
    const setVisibility = useCallback(visible => setTemporalHide(!visible), [setTemporalHide])

    const { HOFProducts, isLoading } = useHOFProductRow(
        props?.listOfProductTilesPositions,
        breadcrumbList,
        Boolean(showHallOfFame),
        setVisibility,
    )

    return showHallOfFame && !temporalHide ? (
        <section data-testid={'hall-of-fame'} className={hallOfFame} hidden={temporalHide}>
            <Header title={props?.componentName || ''} />
            <HallOfFameProductGrid
                products={HOFProducts}
                isLoading={isLoading}
                productDetailsProps={productDetailsProps}
            />
        </section>
    ) : null
}

/**
 * @description HallOfFame header
 * @param {HeaderProps} props - HeaderProps
 * @returns {JSX.Element} - HallOfFame header
 */
const Header = ({ title }: HeaderProps): JSX.Element => {
    return (
        <header>
            <h2 className={hallOFameTitle}>{title}</h2>
        </header>
    )
}

export default HallOfFame
