import { MagicNumber } from './../analytics/analytics.type'
import { BREAKPOINTS, dataComponentName } from '../config'
import { componentPolicies, imageBreakpoints } from './akamaiPolicy.constant'
import { ComponentBreakpoint } from './akamaiPolicy.type'
import { productCardTall, productCardTall2 } from '../../src/globalConstants/global.constant'

export const AkamaiImagePolicies = (window => {
    /**
     * Set Image policy for image with data-component-name
     *
     * @param {HTMLElement} component -
     * @param {string} breakpoint -
     * @return {string} returns string to set as image src
     */
    const _setImagePolicy = (component: HTMLElement, breakpoint: imageBreakpoints): string => {
        const componentBreakpoint = componentPolicies[component.getAttribute(dataComponentName) as string][breakpoint]
        const compSrc = component.getAttribute('src')?.split('?', MagicNumber.ONE)[0] as string

        return `${compSrc}${createImagePolicyStructure(componentBreakpoint)}`
    }

    /**
     *
     * @param componentBreakpoint: {
     *  name: string;
     *  dimension: {
     *      wid?: number;
     *      hei?: number:
     *      qlt?: number
     *      }
     *  }
     * @returns
     */

    const createImagePolicyStructure = (componentBreakpoint: ComponentBreakpoint) => {
        const im = componentBreakpoint.name
        const dimensions = componentBreakpoint.dimension
        let attr = ''
        Object.keys(dimensions).forEach((el: string) => {
            attr = `${attr}&${el}=${String(dimensions[el])}`
        })
        return `?im=${im}${attr}`
    }

    /**
     * Returns policy as per policy type
     * @param {string} policyType
     * @return {string} returns policy
     */

    const returnPolicy = (policyType: string): string => {
        let componentBreakpoint
        if (window.innerWidth < BREAKPOINTS.tabletMaxWidth) {
            componentBreakpoint = componentPolicies[policyType][imageBreakpoints.xs]
        } else {
            componentBreakpoint = componentPolicies[policyType][imageBreakpoints.md]
        }
        return createImagePolicyStructure(componentBreakpoint)
    }

    /**
     * Returns policy as per image type
     * @param {string} policyType
     * @return {string} returns policy
     */
    const extendedReturnPolicy = (policyType: string): string => {
        const breakPointValue = checkDimension(window.innerWidth)
        const componentBreakpoint = componentPolicies[policyType][breakPointValue]
        return createImagePolicyStructure(componentBreakpoint)
    }

    /**
     * Function to return the imageBreakpoints based on resolution
     * @param {number} dim
     * @return {imageBreakpoints}
     */
    const checkDimension = (dim: number): imageBreakpoints => {
        if (dim < BREAKPOINTS.tabletWidth) {
            return imageBreakpoints.xs
        } else if (dim < BREAKPOINTS.desktopMinWidth) {
            return imageBreakpoints.sm
        } else if (dim < BREAKPOINTS.desktopMediumWidth) {
            return imageBreakpoints.mmd
        } else if (dim < BREAKPOINTS.standardDesktopWidth) {
            return imageBreakpoints.smd
        } else if (dim < BREAKPOINTS.wideDesktopWidth) {
            return imageBreakpoints.md
        } else {
            return imageBreakpoints.lg
        }
    }

    /**
     * Apply component specific Akamai policies
     * @param {NodeListOf<HTMLElement>} components
     */
    const _applyPolicies = (components: NodeListOf<HTMLElement>): void => {
        components.forEach((comp: HTMLElement) => {
            if (
                comp.getAttribute(dataComponentName) === productCardTall ||
                comp.getAttribute(dataComponentName) === productCardTall2
            ) {
                const breakPointValue = checkDimension(window.innerWidth)
                comp.setAttribute('src', _setImagePolicy(comp, breakPointValue))
            } else {
                if (
                    componentPolicies[comp.getAttribute(dataComponentName) as string].hasOwnProperty(
                        imageBreakpoints.sm,
                    )
                ) {
                    if (window.innerWidth <= BREAKPOINTS.mobileMaxWidth) {
                        comp.setAttribute('src', _setImagePolicy(comp, imageBreakpoints.xs))
                    } else if (
                        window.innerWidth > BREAKPOINTS.mobileMaxWidth &&
                        window.innerWidth < BREAKPOINTS.desktopMinWidth
                    ) {
                        comp.setAttribute('src', _setImagePolicy(comp, imageBreakpoints.sm))
                    } else {
                        comp.setAttribute('src', _setImagePolicy(comp, imageBreakpoints.md))
                    }
                } else {
                    if (window.innerWidth < BREAKPOINTS.tabletMaxWidth) {
                        comp.setAttribute('src', _setImagePolicy(comp, imageBreakpoints.xs))
                    } else {
                        comp.setAttribute('src', _setImagePolicy(comp, imageBreakpoints.md))
                    }
                }
            }
        })
    }

    const _applyPolicy = (component: HTMLImageElement): void => {
        if (window.innerWidth < BREAKPOINTS.tabletMaxWidth) {
            component.setAttribute('src', _setImagePolicy(component, imageBreakpoints.xs))
        } else {
            component.setAttribute('src', _setImagePolicy(component, imageBreakpoints.md))
        }
    }

    const _init = (components: NodeListOf<HTMLImageElement>): void => {
        _applyPolicies(components)
    }

    return {
        init: _init,
        initIndPolicy: _applyPolicy,
        returnPolicy,
        extendedReturnPolicy,
    }
})(window)
