import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { isArrayNotEmpty } from '@nl/lib'

import HallOfFameService from '../../../services/hallOfFameService'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { tiresDataSelector } from '../../../redux/selectors/vehicleTires.selectors'
import { useDefaultVehicleWithoutImageBlinking } from '../../ProductGridView/ProductCard.hooks'
import { HALL_OF_FAME_PRODUCTS_AMOUNT } from './HallOfFame.constants'
import { HallOfFameProduct, ProductTilePosition } from './HallOfFame.types'
import { getCategoriesList } from '../../Vehicles/Vehicle.helper'
import { CategoryBreadCrumb } from '../../../redux/models/category.interface'

/**
 * @description hook to abstract HOF product fetching
 * @param {listOfProductTilesPositions[]} listOfProductTilesPositions - list of product tiles positions
 * @param {CategoryBreadCrumb[]} breadcrumbList - list of breadcrumbs
 * @param {boolean} showHallOfFame - flag to show hall of fame
 * @param {Function} setVisibility - function to set visibility
 * @returns {Object} HOFProducts - list of hall of fame products, isLoading - flag to show loading
 */
export default function useHOFProductRow(
    listOfProductTilesPositions: ProductTilePosition[] | undefined,
    breadcrumbList: CategoryBreadCrumb[],
    showHallOfFame: boolean,
    setVisibility: (visible: boolean) => void,
): { HOFProducts: HallOfFameProduct[]; isLoading: boolean } {
    /**
     * Object what not change reference when id is not changed - it is necessary to prevent change trigger on image update
     */
    const safeDefaultVehicle = useDefaultVehicleWithoutImageBlinking()

    const selectedTireSize = useSelector(tiresDataSelector)
    const { selectedPreferredStoreId } = useSelector(storeDetailsDataSelector)

    const [HOFProducts, setHOFProducts] = useState<HallOfFameProduct[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const theSameCaseIgnore = useCallback(
        (left?: string | null, right?: string | null) => left?.toUpperCase?.().trim() === right?.toUpperCase?.().trim(),
        [],
    )

    useEffect(() => {
        if (listOfProductTilesPositions && showHallOfFame) {
            setVisibility(true)
            setIsLoading(true)
            const ranksQuery = listOfProductTilesPositions.reduce((acc, { rankingRule, pCode }) => {
                const isFirst = acc.length === 0
                const ranksParameter = rankingRule ? rankingRule : pCode ? pCode : null
                return ranksParameter ? (isFirst ? ranksParameter : `${acc}|${ranksParameter}`) : acc
            }, '')

            const breadcrumbs = getCategoriesList(breadcrumbList, false)

            if (ranksQuery && isArrayNotEmpty(breadcrumbs) && selectedPreferredStoreId) {
                void HallOfFameService.fetchFeaturedProducts(
                    ranksQuery,
                    selectedPreferredStoreId,
                    breadcrumbs,
                    safeDefaultVehicle,
                    selectedTireSize,
                )
                    .then(response => {
                        const { results } = response
                        const mutableResults = [...results]

                        const productsWithRank = listOfProductTilesPositions
                            // Ignore not valid record without both keys: pcode and ranking rule.
                            .filter(({ rankingRule, title }) => rankingRule || title)
                            .map(({ pCode, rankingRule, title }) => {
                                const foundItemIndex = mutableResults.findIndex(item =>
                                    item.rankingRule
                                        ? theSameCaseIgnore(item.rankingRule, rankingRule || pCode)
                                        : theSameCaseIgnore(item.prodId, pCode || rankingRule),
                                )
                                // use each result once
                                return foundItemIndex > -1
                                    ? { ...mutableResults.splice(foundItemIndex, 1)[0], title }
                                    : null
                            })
                            // removing of not found records
                            .filter(Boolean) as HallOfFameProduct[]

                        setVisibility(productsWithRank.length >= HALL_OF_FAME_PRODUCTS_AMOUNT)
                        setIsLoading(false)
                        setHOFProducts(productsWithRank.slice(0, HALL_OF_FAME_PRODUCTS_AMOUNT))
                    })
                    .catch(err => {
                        console.error('Hall of fame call failed -', err)
                        setIsLoading(false)
                        setVisibility(false)
                    })
            }
        }
    }, [
        selectedTireSize,
        safeDefaultVehicle,
        listOfProductTilesPositions,
        selectedPreferredStoreId,
        breadcrumbList,
        theSameCaseIgnore,
        setVisibility,
        showHallOfFame,
    ])
    return { HOFProducts, isLoading }
}
