import React, { useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@nl/lib'
import { PREFIX } from '../../../config'
import { checkIfProductDiscontinuedEOL } from '../BuyBox.helper'
import { getWishListIconComponent, getAddToWishListLabel } from '../Buybox.component'
import { isWheelOrTirePDP } from '../../Vehicles/Vehicle.helper'
import { useIsMobile } from '../../../hooks'
import { toggleAddToCartCTA } from '../../../redux/actions'
import { userProfileVehicleSelector } from '../../../redux/selectors/userProfile.selectors'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { wishlistRemoveSuccessSelector } from '../../../redux/selectors/wishlist.selectors'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { automotivePackageSelector } from '../../../redux/selectors/automotivePackage.selector'
import { IFeatureFlag, IProduct } from '../../../redux/models/commonContent.interface'
import { ATCWishlistBtnsComponentProps } from './ATCWishlistBtnsComponent.type'
import { addRemoveWishList } from '../AddRemoveWishlist'
import useProductCodeForWishlist from '../useProductCodeForWishlist.hook'
import { StickyBuyBoxCTARequestPayload } from '../../../redux/models/product.interface'

/**
 * Component to return ATC and wishlist button
 * @param {ATCWishlistBtnsComponentProps} props ATCWishlistBtnsComponentProps props
 * @returns {JSX.Element | null} if not limited PDP then return ATC and wishlist button else return null
 */
const ATCWishlistBtnsComponent: React.FC<ATCWishlistBtnsComponentProps> = (
    props: ATCWishlistBtnsComponentProps,
): JSX.Element | null => {
    const {
        addCartClicked,
        needRenderATCWishlistButton,
        isAddedToWishlist,
        criticalFitmentGrouped,
        setIsAddToWishlistClicked,
        checkIfAllVariantsSelected,
        setShowErrorMsgForVariantSelection,
        isAutomotive,
        isTireSizePresent,
        setIsVariantSelected,
        setIsNoSkuFindStoreClicked,
        scrollOnToastDisplayed,
        productObject,
        homeService,
        inStockInSelectedStore,
        isProductSellable,
        isRestrictedPDP,
        isDisabledATC,
        isSelectAStoreButtonAvailable,
        showSpinner,
        isOutOfStockInAllStore,
        isBopisOnlyUnavailableForPickupInStock,
        getAtcPrimaryButtonType,
        isSelectAllOptionToShow,
        isBopisOnlySkuWithStoreBopisUnavailable,
        isProductFullyDiscontinued,
        flyoutVariantsInfoData,
    } = props

    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    const generalProductData = useSelector(productSelector)
    const { productData, isStickyBuyboxCTAClicked: { isToastDisplayed } = {} as StickyBuyBoxCTARequestPayload } =
        useMemo(() => flyoutVariantsInfoData || generalProductData, [flyoutVariantsInfoData, generalProductData])
    const { defaultVehicle } = useSelector(userProfileVehicleSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { product: commonContentProduct = {} as IProduct, featureFlag = {} as IFeatureFlag } = commonContentAvailable
    const { enableVehicleInformationOnWishlist, enableWishlistOnPDP } = featureFlag
    const { selectAStoreLabel } = commonContentProduct
    const wishlistRemoveSuccess = useSelector(wishlistRemoveSuccessSelector)
    const { preferredStoreDetails } = useSelector(storeDetailsDataSelector)
    const { onlineOrdering } = preferredStoreDetails
    const { isPackageFlow } = useSelector(automotivePackageSelector)

    const [wishLoadToggle, setWishLoadToggle] = useState(false)
    const [showWishListSpinner, setShowWishListSpinner] = useState(false)

    const wishloadRef = useRef<HTMLDivElement>(null)
    // Used to limit the no of toggleAddToCartCTA to one on page load.
    const ctaDispatchCount = useRef({
        showCount: 0,
        hideCount: 0,
    })

    // Check if product is fully discontinued or discontinue when out
    const isProductDiscontinuedEOL = checkIfProductDiscontinuedEOL(productData.corporateStatus)

    /**
     * Updating the loading message after adding and removing the product in wishlist
     * @returns {void}
     */
    const updatingWishList = (): void => {
        if (wishloadRef.current) {
            wishloadRef.current.innerHTML = wishLoadToggle
                ? `${props.a11yUpdatingWishList ?? ''}.`
                : `${props.a11yUpdatingWishList ?? ''}`
        }
    }

    /**
     * This function returns class for mobile ATC
     * @returns {string} class for mobile ATC
     */
    const getATCMobileClass = (): string => {
        return isMobile ? `${PREFIX}-buy-box__add-cart-wishlist__atc--sticky` : ``
    }

    // Gets product code for wishlist
    const getPCodeForWishlist = useProductCodeForWishlist(isAddedToWishlist)

    /**
     * function returns wishlist btn with or without label
     * @param { boolean | undefined} withLabel defines if label should be rendered
     * @returns {JSX.Element} WishList component
     */
    const getWishListComponent = (withLabel: boolean | undefined = false): JSX.Element => {
        const innerButton = (
            <>
                <span className="sr-only" ref={wishloadRef} aria-live="polite" aria-atomic="true" role="status"></span>
                <button
                    data-qm-allow="true"
                    className={`${
                        isAddedToWishlist && !wishlistRemoveSuccess
                            ? `${PREFIX}-buy-box__add-cart-wishlist--active`
                            : ''
                    } ${PREFIX}-buy-box__wishlist-container ${
                        withLabel ? `${PREFIX}-buy-box__wishlist-container--with-label` : ''
                    } ${PREFIX}-button ${PREFIX}-button--secondary`}
                    onClick={() =>
                        addRemoveWishList(
                            criticalFitmentGrouped,
                            setIsAddToWishlistClicked,
                            setWishLoadToggle,
                            updatingWishList,
                            getPCodeForWishlist.getProductCodeForWishlist,
                            isAddedToWishlist,
                            checkIfAllVariantsSelected,
                            setShowErrorMsgForVariantSelection,
                            isAutomotive,
                            setIsVariantSelected,
                            setIsNoSkuFindStoreClicked,
                            isToastDisplayed,
                            dispatch,
                            scrollOnToastDisplayed,
                            defaultVehicle,
                            isTireSizePresent,
                            enableVehicleInformationOnWishlist,
                            productObject,
                            props.addToWishlistInactiveProductErrorMsg,
                            setShowWishListSpinner,
                        )
                    }
                    aria-label={isAddedToWishlist ? props.a11yRemoveFromWishList : props.a11yAddToWishList}
                    data-testid="wishlist-button">
                    {getWishListIconComponent(isAddedToWishlist && !wishlistRemoveSuccess, showWishListSpinner)}{' '}
                    {withLabel &&
                        getAddToWishListLabel(isAddedToWishlist, props.addToWishListLabel, props.addedToWishListLabel)}
                </button>
            </>
        )

        return withLabel ? <>{innerButton}</> : <span className={`${PREFIX}-ml-sm`}>{innerButton}</span>
    }

    /**
     * function to display wishlist button, ATW should appear ALL the time except Ã¢â‚¬â€œ Home Service, FD/DWO discontinued and store has no inventory
     * @returns {JSX.Element | null} wishlist btn or null
     */
    const renderWishListBtnComp = (): JSX.Element | null => {
        const applicableForWishList =
            enableWishlistOnPDP && !homeService && !(isProductDiscontinuedEOL && !inStockInSelectedStore())
        const withLabel = props.wishListNonSellableEnabled && !isProductSellable
        return applicableForWishList ? (
            <span className={`${PREFIX}-ml-xs`}>{getWishListComponent(withLabel)}</span>
        ) : null
    }

    /**
     * function renders wishlist btn if PDP is restricted
     * @returns {JSX.Element | null} wishlist btn or null
     */
    const isRestrictedPDPComponent = (): JSX.Element | null => {
        const isRestricted = isRestrictedPDP()

        if (isRestricted && ctaDispatchCount.current.hideCount === 0) {
            dispatch(toggleAddToCartCTA(false))
            ctaDispatchCount.current.hideCount = 1
        }

        return isRestricted || isBopisOnlySkuWithStoreBopisUnavailable ? (
            <div className={`${PREFIX}-buy-box__add-cart-wishlist`}>{getWishListComponent(true)}</div>
        ) : null
    }

    /**
     * function returns label for ATC btn
     * @returns {string | undefined} label for ATC btn
     */
    const getATCBtnLabel = (): string | undefined => {
        return isPackageFlow && isWheelOrTirePDP(productData?.productWheelType)
            ? props.addToPackageCTA
            : isSelectAStoreButtonAvailable()
            ? selectAStoreLabel
            : isSelectAllOptionToShow()
            ? props.selectAllOptionsLabel
            : props.buyboxButtonLabel
    }

    if (needRenderATCWishlistButton) {
        return (
            <div className={`${PREFIX}-buy-box__add-cart-wishlist`}>
                <div className={`${PREFIX}-buy-box__add-cart-wishlist__atc ${getATCMobileClass()}`}>
                    {onlineOrdering && (
                        <Button
                            id="add-to-cart"
                            disabled={isDisabledATC}
                            type={getAtcPrimaryButtonType()}
                            onClick={() => {
                                addCartClicked()
                            }}
                            size="large"
                            label={getATCBtnLabel()}
                            showSpinner={showSpinner}></Button>
                    )}
                </div>
                {renderWishListBtnComp()}
            </div>
        )
    } else if (
        (!isRestrictedPDP() && isOutOfStockInAllStore && !isProductFullyDiscontinued) ||
        isBopisOnlyUnavailableForPickupInStock ||
        !onlineOrdering
    ) {
        return (
            <div className={`${PREFIX}-buy-box__add-cart-wishlist`}>
                {!homeService && enableWishlistOnPDP && getWishListComponent(true)}
            </div>
        )
    } else {
        return isRestrictedPDPComponent()
    }
}

export default ATCWishlistBtnsComponent
