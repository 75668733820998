import {
    addVehicleAction,
    setDefaultVehicleAction,
    removeVehicleAction,
    addVehicleListAction,
    updateVehicleAction,
    setURLDefaultVehicleAction,
} from '../redux/actionCreators'
import { getEnvironment } from '../environments'
import store from '../store'
import { setRemovedVehicle } from '../redux/actionCreators/vehiclesData.actionCreators'
import StorageHelper from './storage.helper'
import { isArrayNotEmpty, libUtils, Vehicle, VehicleImage } from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import objShallowCompare from '../utils/objShallowCompare'
import { storageData } from '../globalConstants/global.constant'
import sessionStorageService from '../utils/sessionStorageService'
import { VehicleInformation } from '../redux/models/cart.interface'
import { automotiveVehicleService } from '../services/vehicleSelector'
import { DropdownValueType, SuggestedValueType } from '../redux/models/tireVehicle.interface'
import { radioIds, vehicleSelectorOptions, VehicleTypeIds } from '../components/Vehicles/Vehicles.constant'
import { AddVehicleDataResponse } from '../components/BuyBox/BuyBox.type'

const config = getEnvironment()

/**
 * @class {UserHelper}
 */
export class UserHelper {
    /**
     * This function used to set default vehicle
     * @param {Vehicle} vehicle
     */
    static setDefaultVehicle(vehicle?: Vehicle) {
        store.dispatch(setDefaultVehicleAction(vehicle))
    }

    /**
     * This function used to set default vehicle in session stoarge
     * @param {Vehicle} vehicle
     */
    static setDefaultVehicleInSession(vehicle: Vehicle) {
        sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.defaultVehicle, JSON.stringify(vehicle))
    }

    /**
     * This function used to set default vehicle from the URL
     * @param {Vehicle} vehicle
     */
    static setURLDefaultVehicle(vehicle?: Vehicle) {
        store.dispatch(setURLDefaultVehicleAction(vehicle))
    }

    /**
     * This function used to add a new vehicle
     * @param {Object[]} known
     * @param {string[]} suggestionRequired
     * @param {string} sku
     * @param {Object[]} categories
     * @param {Object} astIdLevel
     * @param {Object[]} optionalParameters
     * @return {Promise<AddVehicleDataResponse>} addVehicleDataResponse
     */
    static addVehicleNextDropdown(
        known: { x: string; q: string }[],
        suggestionRequired: string[],
        sku: string,
        categories?: { x: string; q: string }[],
        astIdLevel?: { x: string; q: string },
        optionalParameters?: { x: string; q: string }[],
    ): Promise<AddVehicleDataResponse> {
        return new Promise<AddVehicleDataResponse>((resolve, reject) => {
            automotiveVehicleService
                .addNewVehicle(known, suggestionRequired, sku, categories, astIdLevel, optionalParameters)
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * This function used to fetch vehicle image
     * @param {any} vehicle
     * @return {Promise<any>} vehicleImage
     */
    static fetchImage(vehicle) {
        return new Promise((resolve, reject) => {
            automotiveVehicleService
                .fetchVehicleImage(vehicle)
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * This function fetches the vehicle image and stores in localstorage and redux
     * @param {Vehicle} vehicle
     */
    static setImageInVehicle(vehicle: Vehicle) {
        UserHelper.fetchImage(vehicle)
            .then((image: { images: VehicleImage[] }) => {
                StorageHelper.storageService
                    .getVehicleList(config.LOCAL_STORAGE_KEYS.userVehicles)
                    .then((vehicleList: Vehicle[]) => {
                        const addedVehicle: Vehicle = vehicleList.find(
                            (vehicleObj: Vehicle) => vehicleObj.id === vehicle.id,
                        )
                        const addedVehicleIndex: number = vehicleList.findIndex(
                            (vehicleObj: Vehicle) => vehicleObj.id === vehicle.id,
                        )
                        addedVehicle.selectedImage = image.images[0]
                        addedVehicle.autoImagesList = image.images
                        vehicleList[addedVehicleIndex] = addedVehicle
                        store.dispatch(addVehicleListAction(vehicleList))
                        StorageHelper.storageService
                            .setVehicleList(config.LOCAL_STORAGE_KEYS.userVehicles, vehicleList)
                            .catch(err => console.error(err))
                    })
                    .catch(err => console.error(err))
            })
            .catch(err => console.error(err))
    }

    /** Using this helper function to wrap complete flow of addVehicle in single function
     * calling multiple action from helper
     * updating local storage
     * @param {Vehicle | Vehicle[]} vehicle
     */
    static async addVehicle(vehicle: Vehicle | Vehicle[]) {
        if (!isArrayNotEmpty(vehicle)) {
            if (!Array.isArray(vehicle)) {
                // OCCP-23556: when adding a vehicle for the first time, store the language which will be used to determine if new vehicleType needs to be fetched
                vehicle.lang = libUtils.getLanguage()
                // Set default
                UserHelper.setDefaultVehicle(vehicle)
                // fetch vehicle image
                this.setImageInVehicle(vehicle)
                sessionStorageService.setItem(
                    config.SESSION_STORAGE_KEYS.lastSelectedVehicleOption,
                    vehicleSelectorOptions.vehicle,
                )
            }
            /**
             * Update redux from local storage to:
             * - It makes consistent all tabs by state.
             * - It restores Redux list of vehicles from storage for vehicle preselection page.
             * Right after this adds new vehicle to restored list and updates the redux store
             * in case restoring vehicle list from local storage fails
             * add new vehicle to redux store any anyway
             */
            try {
                const vehicleList = await StorageHelper.storageService.getVehicleList<Vehicle[]>(
                    config.LOCAL_STORAGE_KEYS.userVehicles,
                )
                const updatedList = isArrayNotEmpty(vehicleList) ? [vehicle, ...vehicleList] : [vehicle]
                store.dispatch(addVehicleListAction(updatedList))
            } catch (err) {
                console.error(err)
                store.dispatch(addVehicleAction(vehicle))
            }
            UserHelper.setDefaultVehicleInSession(vehicle)
        }

        // add to localStorage
        StorageHelper.storageService
            .addVehicle(config.LOCAL_STORAGE_KEYS.userVehicles, vehicle)
            .catch(err => console.error(err))

        if (Array.isArray(vehicle) && vehicle.length === 0) {
            sessionStorageService.setItem(
                config.SESSION_STORAGE_KEYS.lastSelectedVehicleOption,
                vehicleSelectorOptions.noVehicle,
            )
        }

        if (!isArrayNotEmpty(JSON.parse(localStorage.getItem(storageData.vehicles)))) {
            const visitedPLP = { visited: 'false', timestamp: new Date() }
            StorageHelper.storageService
                .setVisitedPLP(radioIds.visitedVehiclePLP, visitedPLP)
                .catch(err => console.error(err))
        }
    }

    /**
     * This function used to change current vehicle
     * @param {VehicleInformation} vehicleInformation
     */
    static changeCurrentVehicle({ body, make, model, options, year }: VehicleInformation) {
        StorageHelper.storageService
            .getVehicleList(config.LOCAL_STORAGE_KEYS.userVehicles)
            .then((vehicleList: Vehicle[]) => {
                vehicleList.unshift(
                    vehicleList.splice(
                        vehicleList.findIndex((vehicle: Vehicle) => {
                            const correctVehicleInfo = { option: options, body, make, model, year }
                            const currentVehicleInfo = {
                                make: vehicle.autoAttributes?.make,
                                year: vehicle.autoAttributes?.year,
                                model: vehicle.autoAttributes?.model,
                                body: vehicle.autoAttributes?.body,
                                option: vehicle.autoAttributes?.option,
                            }
                            return objShallowCompare(currentVehicleInfo, correctVehicleInfo)
                        }),
                        MagicNumber.ONE,
                    )[0],
                )
                StorageHelper.storageService
                    .addVehicle(config.LOCAL_STORAGE_KEYS.userVehicles, vehicleList)
                    .catch(err => console.error(err))
            })
            .catch(err => console.error(err))
    }

    /**
     * This function used to remove vehicle
     * @param {Vehicle} vehicle
     */
    static removeVehicle(vehicle: Vehicle) {
        // remove Vehicle from redux
        store.dispatch(removeVehicleAction(vehicle))
        store.dispatch(setRemovedVehicle(vehicle.baseVehicleId))
        StorageHelper.storageService
            .removeVehicle(config.LOCAL_STORAGE_KEYS.userVehicles, vehicle)
            .then(() => {
                StorageHelper.storageService
                    .getVehicleList(config.LOCAL_STORAGE_KEYS.userVehicles)
                    .then((vehiclesList: Vehicle[]) => {
                        if (!vehiclesList || vehiclesList.length === 0) {
                            sessionStorageService.removeItem(config.SESSION_STORAGE_KEYS.lastSelectedVehicleOption)
                            sessionStorageService.removeItem(config.SESSION_STORAGE_KEYS.defaultVehicle)
                        }
                    })
                    .catch(err => console.error(err))
            })
            .catch(err => console.error(err))
    }

    // Call multiple api when shop by tire size is selected
    /**
     * function to call multiple api when shop by tire size is selected
     * @param { string }  tireWidth
     * @param { string }  tireAspect
     * @param { boolean } fullSizeRequired
     * @return { Promise<Record<string, unknown>> }
     */
    static getSelectionWidth(
        tireWidth?: string,
        tireAspect?: string,
        fullSizeRequired?: boolean,
    ): Promise<Record<string, unknown>> {
        const selectedTireService =
            tireWidth && tireAspect
                ? automotiveVehicleService.tireSizesDiameter(tireWidth, tireAspect, fullSizeRequired)
                : tireWidth && !tireAspect
                ? automotiveVehicleService.tireSizesAspectRatio(tireWidth)
                : automotiveVehicleService.tireSizesWidth()
        return new Promise((resolve, reject) => {
            selectedTireService
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * function to get dropdown list
     * @param {string} id
     * @param {string[]} values
     * @return {Promise<Record<string, unknown>>}
     */
    static fetchVehicleDropdownOptions(id: string, values: string[]): Promise<DropdownValueType[]> {
        return new Promise((resolve, reject) => {
            automotiveVehicleService
                .fetchVehicleDropdownList(id, values)
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * This function used to get vehicle dropdown options
     * @param {SuggestedValueType[]} suggestions
     * @param {string} dropdownName
     * @param {boolean} isVehicleConfigIds
     * @param {DropdownValueType[]} data
     * @return {Record<string, unknown>[] | undefined} dropdownOptions
     */
    static getVehicleDropdownOptions(
        suggestions: SuggestedValueType[],
        dropdownName: string,
        isVehicleConfigIds?: boolean,
        data?: DropdownValueType[],
    ): Record<string, unknown>[] | undefined {
        let dropdownOptions
        suggestions.forEach(item => {
            if (item.id === dropdownName) {
                dropdownOptions = item.values.map((value: string, index: number) => {
                    return { label: value, id: isVehicleConfigIds ? Number(data[index].id) : index }
                })
                if (item.id === VehicleTypeIds.year) {
                    dropdownOptions.sort(
                        (leftOption, rightOption) => Number(rightOption.label) - Number(leftOption.label),
                    )
                }
            }
        })
        return dropdownOptions
    }

    /**
     * This function used to update vehicle
     * @param {Vehicle} vehicle
     * @param {number} vehicleId
     * @param {Vehicle[]} list
     * @param {string} vehicleConfigIds
     */
    static updateVehicle(vehicle: Vehicle, vehicleId: number, list: Vehicle[], vehicleConfigIds: string) {
        UserHelper.setDefaultVehicle(vehicle)
        const vehicleIndex = list?.findIndex(vehicleObj => vehicleObj.id === vehicleId)
        if (vehicleIndex !== MagicNumber.MINUS_ONE) {
            list[vehicleIndex].autoAttributes = vehicle.autoAttributes
            const vehicleConfig: unknown[] = []
            vehicleConfigIds?.split(',').forEach(ele => {
                const attribute = Object.keys(vehicle.autoAttributes).find(item => item === ele)
                attribute && vehicleConfig.push(attribute)
            })
            if (vehicleConfig.length > 0) {
                vehicleConfig.forEach((ele: string) => {
                    list[vehicleIndex][`displayName${ele}`] = vehicle[`displayName${ele}`]
                })
            }
            // OCCP-23556: need to update vehicleType and lang as it needs to change to match the current language
            list[vehicleIndex].lang = vehicle.lang
            list[vehicleIndex].vehicleType = vehicle.vehicleType
            store.dispatch(updateVehicleAction(list))
        }
        // add to localStorage
        StorageHelper.storageService
            .updateVehicle(config.LOCAL_STORAGE_KEYS.userVehicles, vehicle, vehicleId, vehicleConfigIds)
            .catch(err => console.error(err))
        sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.defaultVehicle, JSON.stringify(vehicle))
    }
}
