import { IGlobalProps } from '../redux/models/commonContent.interface'
import { CommonContentState } from '../redux/reducers/commonContent.reducer'
import store from '../store'
import { RootState } from '../redux/reducers'
import { Status } from '../globalConstants'

/**
 * Function to get Common Content
 * @returns {CommonContentState}
 */
export const getCommonContent = (): CommonContentState => {
    const state = store.getState() as RootState
    return state.commonContent
}

/**
 * Function to get ClpStatus
 * @param {IGlobalProps} commonContentAvailable - global props from commonContent
 * @param {string} commonContentLoadStatus - load status from commonContent
 * @returns {string | boolean}
 */
export const clpStatus = (commonContentAvailable: IGlobalProps, commonContentLoadStatus: string): string | boolean => {
    let result: string | boolean
    if (commonContentLoadStatus === Status.error || commonContentLoadStatus === Status.failed) {
        result = Status.error
    } else {
        result = commonContentAvailable?.featureFlag?.enableCLP
    }
    return result
}
