export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS'
export const FETCH_WEATHER_TECH_PRODUCT_SUCCESS = 'FETCH_WEATHER_TECH_PRODUCT_SUCCESS'
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE'
export const FETCH_LOAD_MORE_PRODUCT_SUCCESS = 'FETCH_LOAD_MORE_PRODUCT_SUCCESS'
export const UPDATE_FACET_CLICK = 'UPDATE_FACET_CLICK'
export const UPDATE_SELECTED_SORT = 'UPDATE_SELECTED_SORT'
export const FILL_PRICE_AVAILABILITY = 'FILL_PRICE_AVAILABILITY'
export const CLEAR_PRODUCT_LIST = 'CLEAR_PRODUCT_LIST'
export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST'
export const FETCH_WEATHER_TECH_PRODUCT_REQUEST = 'FETCH_WEATHER_TECH_PRODUCT_REQUEST'
export const SET_NO_RESULT = 'SET_NO_RESULT'
export const SET_PRODUCT_DISPLAY_COUNT = 'SET_PRODUCT_DISPLAY_COUNT'
