import React, { useMemo, useRef } from 'react'
import classNames from 'classnames'
import { SwiperRef } from 'swiper/react'
import { useSelector } from 'react-redux'

import { CarouselSwiper, SanitizeStringContentWrapper } from '@nl/lib'
import { ImageGalleryProps } from './ImageGallery.type'
import { commonContentAvailableSelector } from '../../../../redux/selectors/commonContent.selectors'
import { IAccessibility } from '../../../../redux/models/commonContent.interface'
import { MagicNumber } from '../../../../analytics/analytics.type'
import { breakpointSettings } from './ImageGallery.constant'
import { updateImagesWithSizes } from './ImageGallery.helper'

/**
 *
 * @param props
 */
export const ImageGallery: React.FC<ImageGalleryProps> = props => {
    const {
        imageGalleryInfo: { images },
    } = props
    const updatedImages = useMemo(() => updateImagesWithSizes(images), [images])
    const carouselRef = useRef<SwiperRef>(null)
    const { accessibility: { a11yCarouselNextLabel, a11yCarouselPreviousLabel } = {} as IAccessibility } =
        useSelector(commonContentAvailableSelector)
    const hasOneImage = updatedImages.length === MagicNumber.ONE

    const imageContainers = (): JSX.Element[] => {
        return updatedImages.map(({ url, altText }, index: number) => (
            <div className="image-gallery-item" key={index}>
                <SanitizeStringContentWrapper stringContent={url}>
                    {memoizedStringContent => <img src={memoizedStringContent} aria-label={altText} title={altText} />}
                </SanitizeStringContentWrapper>
            </div>
        ))
    }

    return (
        <div className="image-gallery-wrapper">
            <CarouselSwiper
                sliderRef={carouselRef}
                carouselList={imageContainers()}
                a11yCarouselPreviousLabel={a11yCarouselPreviousLabel}
                a11yCarouselNextLabel={a11yCarouselNextLabel}
                breakpointSettings={breakpointSettings}
                centerInsufficientSlides={false}
                infiniteScrolling
                dynamicBullet={{ show: true, count: MagicNumber.FOUR }}
                carouselClassName={classNames('image-gallery-carousel', {
                    ['single-image-gallery']: hasOneImage,
                })}
            />
        </div>
    )
}
