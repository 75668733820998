import { GlobalPropsHelper } from '../../../analytics/helpers/globalProps'
import getPageType from '../../../utils/getPageType'
import getPageTitle from '../../../utils/getPageTitle'
import checkIsStraggered from '../../../utils/checkIsStraggered'
import {
    checkShopByVehicleData,
    prepareVehicleDataInLabel,
    isPartialVehicleJIT,
    fetchShopByVehicleData,
    prepareVehicleDataByAttribute,
} from '../../../utils/checkShopByVehicleData'
import getCategoryLevelData from '../../../utils/getCategoryLevelData'
import { checkIsAutomotivePage } from '../../../utils/checkIsAutomotivePage'
import getUserStatusForLogin from '../../../utils/getUserStatusForLogin'
import { checkDataLength } from '../Addresses/checkDataLength'
import {
    notTriangleMember,
    onLoadEvent,
    triangleMember,
    notEnrolledForLoyalty,
} from './GlobalPageLoadAnalytics.constant'
import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import {
    GlobalAnalyticsOnLoadType,
    GlobalAnalyticsPage,
    GlobalAnalyticsStore,
    GlobalAnalyticsUser,
    GlobalAnalyticsVehicle,
    GlobalOnloadAnalyticData,
    LoyaltyInfo,
} from './GlobalPageLoadAnalytics.type'
import { isArrayNotEmpty, libUtils, CardType } from '@nl/lib'
import { getProvider } from '../../../analytics/providers'
import { CategoryBreadCrumb } from '../../../redux/models/category.interface'
import { IGlobalProps } from '../../../redux/models/commonContent.interface'
import { FilteredCartData } from '../../../redux/models/cart.interface'
import { UserProfileData } from '../../../redux/models/user.profile.interface'
import sessionStorageService from '../../../utils/sessionStorageService'
import appCacheService from '../../../utils/appCacheService'
import { getEnvironment } from '../../../environments'
import { StoreWithAvailability } from '../../../redux/models/storeDetails.interface'
import { ProductState } from '../../../redux/models/product.interface'
import { REF_URL_KEY } from '../../../config'
import { AnalyticsRecordInterface } from '../../../analytics/providers/provider.type'

/**
 * Analytics implementation for page load
 */
export class GlobalPageLoadAnalyticsService {
    /**
     * Fire order history page profile on-load analytics event
     * @param {Record<string, unknown>} analyticsData - analytics data
     * @returns {void} - void
     */
    static setOnloadAnalytics(analyticsData: GlobalOnloadAnalyticData): void {
        const provider = getProvider()

        const productBreadcrumbList =
            analyticsData?.productData?.productData?.breadcrumbList?.filter(breadcrumb => breadcrumb.categoryId) || []
        const productCategoryId = productBreadcrumbList[productBreadcrumbList.length - 1]?.categoryId
        const breadcrumbList = isArrayNotEmpty(analyticsData?.categoryData?.breadcrumbList)
            ? analyticsData?.categoryData?.breadcrumbList
            : productBreadcrumbList

        let onloadData: GlobalAnalyticsOnLoadType = {
            event: onLoadEvent,
            page: this.prepareOnloadPageData(breadcrumbList, analyticsData?.categoryData?.id || productCategoryId),
            user: this.prepareOnloadUserData(analyticsData),
            store: this.prepareOnloadStoreData(analyticsData),
            ref_url: sessionStorageService.getItem(REF_URL_KEY) || document.referrer,
        }
        if (
            checkIsAutomotivePage({
                categoryData: analyticsData?.categoryData,
                productData: analyticsData?.productData,
            })
        ) {
            onloadData = {
                ...onloadData,
                vehicle: this.prepareOnloadVehicleData(analyticsData.vehicleData as unknown as ProductState, true),
            }
        }
        provider.push(onloadData as unknown as AnalyticsRecordInterface, true)
    }

    /**
     * Prepare analytics data for onload page
     * @param {CategoryBreadCrumb[]} breadCrumb - category breadcrumb
     * @param {categoryId} categoryId - category id
     * @returns {GlobalAnalyticsPage} - analytics page data
     */
    static prepareOnloadPageData(breadCrumb: CategoryBreadCrumb[], categoryId?: string): GlobalAnalyticsPage {
        const globalProps = new GlobalPropsHelper()
        const dataConfig = globalProps.readDataConfig()
        const categoryLevelData = getCategoryLevelData(breadCrumb)
        return {
            url: window.location.href,
            name: getPageTitle(),
            banner: dataConfig?.bannerId as string,
            language: libUtils.getLanguage(),
            type: getPageType(),
            categoryId: categoryId,
            ...categoryLevelData,
        }
    }

    /**
     * Prepare analytics data for current user
     * @param {Record<string, unknown>} analyticsData - analytics data
     * @returns {GlobalAnalyticsPage} - analytics page data
     */
    static prepareOnloadUserData(analyticsData: GlobalOnloadAnalyticData): GlobalAnalyticsUser {
        const profileData = analyticsData?.profileData
        const vehicleData = prepareVehicleDataInLabel()
        const loyaltyData = this.getLoyaltyInfo(profileData, analyticsData?.orderData)
        return {
            loginStatus: getUserStatusForLogin(analyticsData),
            email: profileData?.email,
            firstName: profileData?.firstName,
            lastName: profileData?.lastName,
            phoneNumber: profileData?.addresses?.phone,
            address: profileData?.addresses,
            totalRewardTransactions: appCacheService.totalTraingleTransaction.get() || '0',
            languagePreferences: profileData?.locale || libUtils.getLanguage(),
            vehicle: vehicleData,
            viewedFlyer: String(!!sessionStorageService.getItem('viewedFlyer')),
            gigyaId: checkDataLength(profileData) ? appCacheService.gigyaId.get() : '',
            ...loyaltyData,
        }
    }

    /**
     * Prepare analytics data for current user
     * @param {UserProfileData} profileData - user profile data
     * @param {FilteredCartData} orderData - order data
     * @returns {LoyaltyInfo} - loyalty data
     */
    static getLoyaltyInfo(profileData: UserProfileData, orderData: FilteredCartData): LoyaltyInfo {
        if (checkDataLength(profileData)) {
            return this.getUserProfileLoyaltyInfo(profileData)
        } else if (checkDataLength(orderData)) {
            return this.getOrderDataLoyaltyInfo(orderData)
        }
        return {} as LoyaltyInfo
    }

    /**
     * Prepare analytics data for current user based on User Profile Data
     * @param {UserProfileData} profileData - user profile data
     * @returns {LoyaltyInfo} - loyalty data
     */
    static getUserProfileLoyaltyInfo(profileData: UserProfileData): LoyaltyInfo {
        const ctfsMember = appCacheService.ctfsMember.get()
        const loyaltyIds = this.enableEPCL()
            ? {
                  loyaltyId: profileData?.loyalty?.legacyId || '',
                  epclId: profileData?.loyalty?.id || '',
              }
            : {
                  loyaltyId: profileData?.loyalty?.id || '',
              }

        return {
            loyaltyStatus: profileData?.loyalty?.cardNumber ? triangleMember : notTriangleMember,
            triangleCardNumber: profileData?.loyalty?.cardNumber || notEnrolledForLoyalty,
            ctfsMember: ctfsMember ? (JSON.parse(ctfsMember) as boolean) : false,
            ...loyaltyIds,
        } as LoyaltyInfo
    }

    /**
     * Prepare analytics data for current user based on Order Data
     * @param {FilteredCartData} orderData - order data
     * @returns {LoyaltyInfo} - loyalty data
     */
    static getOrderDataLoyaltyInfo(orderData: FilteredCartData): LoyaltyInfo {
        const { loyaltyCardNumber, cardType } = orderData?.cart.paymentInfo || {}
        return {
            loyaltyStatus:
                cardType?.code?.toLowerCase() === CardType.triangle.toLowerCase() ? triangleMember : notTriangleMember,
            triangleCardNumber: loyaltyCardNumber || undefined,
            ctfsMember: false,
            loyaltyId: '',
            epclId: '',
        } as LoyaltyInfo
    }

    /**
     * Prepare analytics data for current user
     * @param {Record<string, unknown>} analyticsData - analytics data
     * @returns {GlobalAnalyticsPage} - analytics page data
     */
    static prepareOnloadStoreData(analyticsData: GlobalOnloadAnalyticData): GlobalAnalyticsStore {
        const config = getEnvironment()
        const currentStore: StoreWithAvailability = analyticsData?.preferredStoreDetails
        const getGeolocationAuthorizedInfo = sessionStorageService.getItem(
            config.SESSION_STORAGE_KEYS.geoLocationAuthorized,
        )
        const isLocationAuthorized = !!getGeolocationAuthorizedInfo && getGeolocationAuthorizedInfo === 'true'
        const geolocationAuthorizedStatus = isLocationAuthorized
            ? analyticsAttributes.eventParameters.selection.userSelected
            : analyticsAttributes.eventParameters.selection.defaultSelection
        return {
            id: currentStore?.id as string,
            name: currentStore?.name,
            province: currentStore?.address?.region?.name,
            city: currentStore?.address?.town,
            openNow: true,
            selection: geolocationAuthorizedStatus,
            geolocationAuthorized: String(isLocationAuthorized),
            geoCoordinateReturned: String(!!checkDataLength(currentStore?.geoPoint)),
        }
    }

    /**
     * Prepare vehicle analytics data for current user
     * @param {Record<string, string>} analyticsData - the vehicle data
     * @param {boolean} canPopulateData it is checking if automotive page
     * @returns {GlobalAnalyticsVehicle} - analytics vehicle data
     */
    static prepareOnloadVehicleData(analyticsData: ProductState, canPopulateData = false): GlobalAnalyticsVehicle {
        const vehicleData = fetchShopByVehicleData()
        const vehicleAttributes = prepareVehicleDataByAttribute(
            canPopulateData && !!vehicleData ? vehicleData : undefined,
        )

        return {
            partialVehicleJIT: canPopulateData ? isPartialVehicleJIT(vehicleData) : 'false', // '<true | false>', //so when vehicle has year make model, this would return true. if vehicle has body and option filled out, return false.
            shopWithVehicle: canPopulateData && checkShopByVehicleData() ? 'true' : 'false', // if customer shopping with vehicle this returns true
            staggeredFitment: canPopulateData && checkIsStraggered(analyticsData) ? 'true' : 'false', // if vehicle requires staggered fitment tires/wheels return true
            ...vehicleAttributes,
        }
    }

    /**
     * Determines EPCL condition based on values from enableEPCL
     * @return {boolean}
     */
    static enableEPCL = (): boolean => {
        const commonContent = sessionStorageService.getItem(`global_props_${libUtils.getLanguage()}`)
        let hasContent
        if (commonContent) {
            try {
                hasContent = JSON.parse(commonContent) as IGlobalProps
            } catch (error) {
                console.log(error)
            }
        }
        return hasContent?.featureFlag?.enableEPCL as boolean
    }
}
