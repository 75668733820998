import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { FeaturedProductList, isArrayNotEmpty, isArrayEmpty, libUtils } from '@nl/lib'
import { RootState } from '../../redux/reducers'
import { FeaturedProductListDynamicType } from './FeaturedProductListDynamic.type'
import { path } from '../../config'
import { CertonaInitialization, getImagesFromCertona } from '../../certona/certona.service'
import { fetchRecommendationsData } from '../../redux/actions'
import { RecommendationResponseDataDTO, ProductCardType } from '../../redux/models/recommendations.interface'
import { SkeletonComponent } from '@nl/lib'
import { PREFIX } from '../../config'
import { recommendationAnalytics } from '../../analytics/components/recommendationAnalytics'
import { analyticsAttributes } from '../../globalConstants'
import { CertonaProductType } from '../../certona/certona.type'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { IAccessibility, IProduct } from '../../redux/models/commonContent.interface'

/**
 * FeaturedProductListComponent component
 * @param {FeaturedProductListType} props - FeaturedProductListDynamic props
 * @returns {JSX.Element} returns FeaturedProductList component
 */
export const FeaturedProductListDynamic: React.FC<FeaturedProductListDynamicType> = (
    props: FeaturedProductListDynamicType,
): JSX.Element => {
    const dispatch = useDispatch()
    const { recommendationType } = props
    const [pCodes, setPCodes] = useState<string[]>([])
    const [recommendationsData, setRecommendationsData] = useState([] as ProductCardType[])
    const [recommendationsDataToPass, setRecommendationsDataToPass] = useState<ProductCardType[]>([])
    const [recommendationItems, setRecommendationItems] = useState<CertonaProductType[]>([])
    const [recommendationTitle, setRecommendationTitle] = useState('')
    const {
        accessibility = {} as IAccessibility,
        product: commonContentProduct = {} as IProduct,
        badges,
    } = useSelector(commonContentAvailableSelector)
    const [isDataAvailable, setIsDataAvailable] = useState(false)
    const [showSkeleton, setShowSkeleton] = useState(true)

    const certonaData = useSelector((state: RootState) => state.certona)
    const { recommendationsData: cdsRecommendationData } = useSelector((state: RootState) => state.recommendationsData)

    const {
        a11yCarouselNextLabel,
        a11yCarouselPreviousLabel,
        a11yClickToReadFootnote,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
    } = accessibility

    const {
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        thresholdValue: threshold,
    } = commonContentProduct

    const priceProps = {
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    /**
     * Extract pcodes from the certona response.
     */
    const filterPCodes = useCallback(() => {
        const schemes = certonaData?.resonance?.schemes
        if (isArrayEmpty(schemes)) {
            setShowSkeleton(false)
        } else {
            const { getPCodes, getTitle, getItems } = CertonaInitialization.extractSchemeDetails(
                schemes,
                props.schemaId,
            )
            setPCodes(getPCodes)
            setRecommendationTitle(getTitle)
            setRecommendationItems(getItems)
            if (isArrayEmpty(getPCodes)) {
                setShowSkeleton(false)
            }
        }
    }, [certonaData, props.schemaId])

    useEffect(() => filterPCodes(), [filterPCodes])

    /**
     * Sliced pCodes according to the maximumNumberOfRecommendationsPC value
     */
    const slicePCodes = useCallback(
        (productCodes: string[]) => {
            if (productCodes.length > props.maximumNumberOfRecommendationsPC) {
                return productCodes.slice(0, props.maximumNumberOfRecommendationsPC)
            }

            return productCodes
        },
        [props.maximumNumberOfRecommendationsPC],
    )

    const runApiOnce = useRef(0)

    /**
     * Make api call when there are pcodes.
     */
    const dispatchAction = useCallback(() => {
        if (isArrayNotEmpty(pCodes) && runApiOnce.current === 0) {
            const slicedPCodes = slicePCodes(pCodes)
            dispatch(fetchRecommendationsData(slicedPCodes, cdsRecommendationData, props.schemaId))
            runApiOnce.current = 1
        }
    }, [pCodes, dispatch, props.schemaId, cdsRecommendationData, slicePCodes])

    // use effect to dispatch cds api call when pcodes are ready.
    useEffect(() => {
        dispatchAction()
    }, [dispatchAction])

    useEffect(() => {
        const productList =
            cdsRecommendationData.find(
                (singleProductData: RecommendationResponseDataDTO) => singleProductData.scheme === props.schemaId,
            )?.productData || []
        setRecommendationsData(productList)
        if (productList.length) {
            setShowSkeleton(false)
        }
    }, [cdsRecommendationData, props.schemaId])

    useEffect(() => {
        if (recommendationsData && recommendationsData.length > 0 && !isDataAvailable) {
            recommendationAnalytics(
                analyticsAttributes.event.productImpression,
                recommendationsData,
                props.title,
                recommendationType,
            )
            setIsDataAvailable(true)
        }
    }, [recommendationsData, recommendationType, props.title, isDataAvailable, props.maximumNumberOfRecommendationsPC])

    const language = libUtils.getLanguage()

    /**
     * This function would help to fire an analytic layer when a productGrid is clicked from recommendation list.
     * @param {number} idx this param gives the position of productGrid which is being clicked
     * @returns {void} returns void
     */
    const productCardClickHandler = (idx: number): void => {
        recommendationAnalytics(
            analyticsAttributes.event.productClick,
            recommendationsData,
            props.title,
            recommendationType,
            idx,
        )
    }

    useEffect(() => {
        const updatedRecommendationData = recommendationsData?.map(recItems => {
            const urlToUpdate = recommendationItems?.find(
                recItem => recItem.prod_id?.toUpperCase() === recItems.code?.toUpperCase(),
            )
            return {
                ...recItems,
                url: urlToUpdate ? urlToUpdate['pdp_url'] : recItems.url,
            } as ProductCardType
        })

        updatedRecommendationData?.forEach((product: ProductCardType) => {
            product['images'] = getImagesFromCertona(recommendationItems, product)
        })
        setRecommendationsDataToPass(updatedRecommendationData)
    }, [recommendationsData, recommendationItems])

    const { featureFlag } = useSelector(commonContentAvailableSelector)
    const enableBoldedBrandName = featureFlag?.enableBoldedBrandName

    return (
        <>
            {isArrayNotEmpty(recommendationsData) && !showSkeleton ? (
                <FeaturedProductList
                    title={recommendationTitle}
                    aemProps={{ ...props, a11yCarouselPreviousLabel, a11yCarouselNextLabel }}
                    badgePriorities={badges}
                    productList={recommendationsDataToPass}
                    path={path}
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    language={language}
                    maxRecommendations={props.maximumNumberOfRecommendationsPC}
                    priceProps={priceProps}
                    productCardClickHandler={(idx: number) => productCardClickHandler(idx)}
                    returnPolicy={AkamaiImagePolicies.returnPolicy}
                    a11yClickToReadFootnote={a11yClickToReadFootnote}
                    enableBoldedBrandName={enableBoldedBrandName}
                />
            ) : (
                showSkeleton && <SkeletonComponent skeletonClass={`${PREFIX}-featuredProductListDynamic__skeleton`} />
            )}
        </>
    )
}
