import React from 'react'
import { Badges, isArrayNotEmpty } from '@nl/lib'
import { PREFIX } from '../../../config'
import { analyticsAttributes } from '../../../globalConstants'
import { BadgesWrapperProps } from './BadgesWrapper.type'

const badgesWrapperClass = `${PREFIX}-buy-box__badges`

const BadgesWrapper = ({
    badges,
    isStaggered,
    hideDisclaimer,
    badgesDisplayOptions,
    saleEndDaySoonMessage,
    productSaleEndDate,
    saleEndDisableShift,
}: BadgesWrapperProps): JSX.Element => {
    return (
        <>
            {isArrayNotEmpty(badges) && !isStaggered ? (
                <div className={badgesWrapperClass}>
                    <Badges
                        badges={badges}
                        badgesAndPriorities={badgesDisplayOptions}
                        hideDisclaimer={hideDisclaimer}
                        clickable={true}
                        saleEndDaySoonMessage={saleEndDaySoonMessage}
                        salePriceEndDate={productSaleEndDate}
                        analyticsLabelValue={analyticsAttributes.eventParameters.labels.clickableBadge}
                        saleEndDisableShift={saleEndDisableShift}
                    />
                </div>
            ) : null}
        </>
    )
}

export default BadgesWrapper
