import appCacheService from '../../utils/appCacheService'
import { isCheckoutPage, isShoppingCartPage } from '../../utils/checkPageType'
import { DrawerLevel } from './Checkout.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { CartAnalytics } from '../../analytics/components/cartAnalytics'
import { CartPaymentResponse, FilteredCartData } from '../../redux/models/cart.interface'
import {
    checkDataLength,
    getFormattedPriceWithLocale,
    isArrayNotEmpty,
    replaceStrWithDynamicVal,
    applyGiftCardErrorCodes,
} from '@nl/lib'
import { GiftCardInterface } from '@nl/lib/src/components/GiftCard/GiftCard.types'
import { paymentInformationConst } from './PaymentInformation/cardTypeConstants'
import { PlaceOrderErrorCode } from '../../components/Checkout/Checkout.constant'
import { UserProfileData } from '../../redux/models/user.profile.interface'
import { FulfillmentMethods } from '../ShoppingCart/ShoppingCart.type'
import { InitialAddressType } from './ShippingAddress/ShippingAddress.types'
import { CardErrors } from './PaymentInformation/AddNewCard.type'
import { Dispatch, SetStateAction } from 'react'
import { emptySpace } from '../../globalConstants'

/**
 * function to check if shipping drawer to be have edit mode or preview
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @return {boolean}
 */

export const shippingEditCheck = (level: number, drawerState: DrawerLevel): boolean => {
    return drawerState?.previous >= level ? drawerState?.edit === level : true
}

/**
 * function to check if pickup drawer to be activated
 *  @param {boolean} hasSTH
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @returns {boolean}
 */
export const pickupInactiveCheck = (hasSTH: boolean, level: number, drawerState: DrawerLevel): boolean => {
    return (
        (hasSTH ? drawerState?.previous < level : drawerState?.previous < MagicNumber.TWO) && drawerState.edit !== level
    )
}

/**
 * function to check if pickup drawer to be have edit mode or preview
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @returns {boolean}
 */
export const pickupEditCheck = (level: number, drawerState: DrawerLevel): boolean => {
    return drawerState?.previous >= level ? drawerState?.edit === level : true
}

/**
 * function to check if payment drawer to be activated
 * @param {boolean} hasBOPIS
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @returns {boolean}
 */
export const paymentInactiveCheck = (hasBOPIS: boolean, level: number, drawerState: DrawerLevel): boolean => {
    return (
        (hasBOPIS ? drawerState?.previous < level : drawerState?.previous < MagicNumber.THREE) &&
        drawerState.edit !== level
    )
}

/**
 * function to check if payment drawer to be edit mode or preview mode

 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @returns {boolean}
 */
export const paymentEditCheck = (level: number, drawerState: DrawerLevel): boolean => {
    return drawerState?.previous >= level ? drawerState?.edit !== level : false
}

/**
 * function to get selected delivery option
 * @param {any} deliveryOptions
 * @returns {string}
 */
export const getSelectedDeliveryOption = (deliveryOptions: Record<string, unknown>[]) => {
    return deliveryOptions.find(deliveryOption => deliveryOption.isSelected as boolean)?.deliveryOptionType as string
}

/**
 *
 * @param {number}dirtyDrawerLevel
 * @param {number}drawerLevel
 * @returns {boolean}
 */
export const checkIfModalTrigger = (dirtyDrawerLevel: number, drawerLevel: number): boolean => {
    return dirtyDrawerLevel === drawerLevel || !dirtyDrawerLevel
}

/**
 *
 * @param {number}drawerLevel
 * @returns {number}
 */
export const drawerToAnalyticsStep = (drawerLevel: number): number => {
    switch (drawerLevel) {
        case MagicNumber.ONE:
            return MagicNumber.TWO
        case MagicNumber.TWO:
        case MagicNumber.THREE:
            return MagicNumber.THREE
        case MagicNumber.FOUR:
            return drawerLevel
    }

    return drawerLevel
}

/**
 *
 * @param {DrawerLevel}drawerLevelState
 * @param {number}drawerLevel
 * @param {React.Dispatch<React.SetStateAction<DrawerLevel>>}setDrawerLevelState
 * @param {CartAnalytics|undefined } analyticsInstance
 * @param {boolean} isPayment
 */
export const drawerLevelSetter = (
    drawerLevelState: DrawerLevel,
    drawerLevel: number,
    setDrawerLevelState: React.Dispatch<React.SetStateAction<DrawerLevel>>,
    analyticsInstance: CartAnalytics | undefined,
    isPayment = false,
): void => {
    if (drawerLevelState.active === drawerLevel) {
        setDrawerLevelState(prevstate => ({
            ...prevstate,
            edit: prevstate.previous + MagicNumber.ONE,
            active: prevstate.previous + MagicNumber.ONE,
        }))
        if (!isPayment) {
            analyticsInstance?.fireCheckoutContactInfo(
                drawerToAnalyticsStep(drawerLevelState.previous + MagicNumber.ONE),
                undefined,
            )
        }
    } else {
        setDrawerLevelState(prevstate => ({
            ...prevstate,
            edit: prevstate.active,
        }))
        if (!isPayment) {
            analyticsInstance?.fireCheckoutContactInfo(drawerToAnalyticsStep(drawerLevelState.active), undefined)
        }
    }
}

/**
 * Checks the error code returned from CTFS on error for apply gift card and returns the corresponding error message for gift card errors.
 * @param {Record<string, string | number>} error - The error object containing component and code.
 * @param {string} gcErrorToastMsg - The error message to display for gift card inline errors.
 * @returns {string} The error message for gift card errors, or an empty string if not a invalid gc error found.
 */
export const showApplyGiftCardErrorToast = (
    error: Record<string, string | number>,
    gcErrorToastMsg: string,
): string => {
    const { component, code } = error
    if ((component === 'gc' || component === 'apply_gc') && !!code) {
        if (Object.values(applyGiftCardErrorCodes).includes(code)) return gcErrorToastMsg
        else return error.responseMessage as string
    }
    return ''
}

/**
 * Function to check is checkout opened after redirecting from PayPal
 *
 * @returns {boolean}
 */
export const isRedirectedAfterPayPal = (): boolean => {
    const queryParams = window.location.search
    return queryParams.toLowerCase().includes('txn_id') && queryParams.toLowerCase().includes('rspcode=0')
}

/**
 * Function to check is checkout opened after redirecting from click to pay sth validation error
 * @returns {boolean} isRedirectedAfterClickToPaySTHValidation
 */
export const isRedirectedAfterClickToPaySTHValidation = (): boolean => {
    const queryParams = window.location.search
    return queryParams.toLowerCase().includes(paymentInformationConst.clickToPaySTHValidationErrorKey.toLowerCase())
}

/**
 * Function to check if click to pay sth validation error is canadapost or postal code error
 * @param { string } clickToPaySTHValidationError errorCode
 * @returns {boolean} isClickToPaySTHShippingAddressError
 */
export const isClickToPaySTHShippingAddressError = (clickToPaySTHValidationError: string): boolean => {
    return (
        paymentInformationConst.clickToPaySTHCanadaPostSuggestionError === clickToPaySTHValidationError ||
        paymentInformationConst.clickToPaySTHPostalCodeOutOfRangeError === clickToPaySTHValidationError ||
        paymentInformationConst.clickToPaySTHOtherValidationError.includes(clickToPaySTHValidationError)
    )
}

/**
 * Function to check if given click to pay sth validation error is of canadapost suggestion error
 * @param {string} clickToPaySTHValidationError errorCode
 * @param {string} canadaPostAction nextAction
 * @param {string} canadaPostId id
 * @returns {boolean} isClickToPaySTHCanadaPostSuggestionError
 */
export const isClickToPaySTHCanadaPostSuggestionError = (
    clickToPaySTHValidationError: string,
    canadaPostAction: string,
    canadaPostId: string,
): boolean => {
    return (
        paymentInformationConst.clickToPaySTHCanadaPostSuggestionError === clickToPaySTHValidationError &&
        !!canadaPostAction &&
        !!canadaPostId
    )
}

/**
 * Function to check is clear tenders needed
 *
 * Note: In case of changing - ODP-Adobe cart call prefetch implementation should be considered
 * apps/ctcweb-core/components/page/headcartcall.html
 *
 * @param {Record<string, string>} cartGUID
 * @param {Record<string, boolean>} isInitialPageLoading
 *
 * @returns {boolean}
 */
export const isNeedToClearTenders = (cartGUID: string, isInitialPageLoading = false): boolean => {
    return (
        (appCacheService.isPaypalTendersApplied.get(cartGUID) && isShoppingCartPage()) ||
        (isInitialPageLoading &&
            !isRedirectedAfterPayPal() &&
            appCacheService.isGiftCardWasApplied.get(cartGUID) &&
            (isCheckoutPage() || isShoppingCartPage())) ||
        (!isInitialPageLoading && isCheckoutPage() && isRedirectedAfterPayPal())
    )
}

/**
 * Function to check if CTMoney redemption is applied to cart
 * @param {boolean} isAuthUser
 * @param {CartPaymentResponse} paymentInfo
 * @returns {boolean}
 */
export const isRedeemCTMoneyApplied = (isAuthUser: boolean, paymentInfo: CartPaymentResponse): boolean => {
    return isAuthUser && !!paymentInfo?.redeemCTAmount?.value
}

/**
 * Method to get the place order amount
 * @param {GiftCardInterface[] | undefined} giftCardList
 * @param {FilteredCartData} cartItemsData
 * @returns {number}
 */
const getPlaceOrderAmount = (
    giftCardList: GiftCardInterface[] | undefined,
    cartItemsData: FilteredCartData,
): number => {
    if (checkDataLength(giftCardList) || checkDataLength(cartItemsData?.cart?.paymentInfo?.giftCards)) {
        return cartItemsData?.cart?.paymentInfo?.remainingTotalCost?.value || 0
    }
    const amount = isArrayNotEmpty(cartItemsData?.cart?.cartSummary?.taxLines)
        ? cartItemsData?.cart?.cartSummary?.totalWithTaxesAmt?.value
        : cartItemsData?.cart?.cartSummary?.totalAmt?.value

    return amount || 0
}

/**
 * Method to construct place order CTA label content
 * @param {GiftCardInterface[] | undefined} giftCardList
 * @param {FilteredCartData} cartItemsData
 * @param {string} placeOrderCTA
 * @returns {string}
 */
export const getPlaceOrderCTA = (
    giftCardList: GiftCardInterface[] | undefined,
    cartItemsData: FilteredCartData,
    placeOrderCTA: string,
): string => {
    return replaceStrWithDynamicVal(
        placeOrderCTA,
        getFormattedPriceWithLocale(getPlaceOrderAmount(giftCardList, cartItemsData)),
    )
}

/** Function to find the button label for review or place order
 * @param {boolean | undefined} mergePlaceOrderAndReviewOrderFeature is merge order review order place order flag
 * @param {GiftCardInterface[] | undefined} giftCards is gift card
 * @param {FilteredCartData} cartItemsData is cart item data
 * @param {string} placeOrderCTALabel is place order label
 * @param {string} reviewOrderCTALabel is review order label
 * @returns {string} is review order or place order cta
 */
export const getReviewOrPlaceOrderCTA = (
    mergePlaceOrderAndReviewOrderFeature: boolean | undefined,
    giftCards: GiftCardInterface[] | undefined,
    cartItemsData: FilteredCartData,
    placeOrderCTALabel: string,
    reviewOrderCTALabel: string,
): string => {
    return mergePlaceOrderAndReviewOrderFeature
        ? getPlaceOrderCTA(giftCards, cartItemsData, placeOrderCTALabel)
        : reviewOrderCTALabel
}

/** Function to getting Cart GUID
 * @param {string} isStoreSharedCart
 * @param {FilteredCartData} storeInitiatedCart
 * @param {FilteredCartData} cartItemsData
 * @returns {string}
 */
export const getCartGUID = (
    isStoreSharedCart: boolean,
    storeInitiatedCart: FilteredCartData,
    cartItemsData: FilteredCartData,
): string => {
    return isStoreSharedCart ? storeInitiatedCart.cart.cartId : cartItemsData.cart.cartId
}

/** Function to getting class of component with modificator no-margin
 * @param {boolean} isExpressDeliveryMode
 * @param {string} componentName
 * @returns {string}
 */
export const getNoMarginClass = (isExpressDeliveryMode: boolean, componentName: string): string => {
    return isExpressDeliveryMode ? `${componentName}--no-margin` : ''
}

/**
 * Function to check if initPayment is success with merge place order review order feature
 * @param {boolean} isInitPaymentSuccess is initpayment success
 * @param {boolean} isPayPalPayment is paypal payment
 * @param {boolean} isClickToPayRedirect is click to pay redirect
 * @returns {boolean} proceed with place order
 */
export const isProceedWithPlaceOrder = (
    isInitPaymentSuccess: boolean,
    isPayPalPayment: boolean,
    isClickToPayRedirect: boolean,
): boolean => {
    return isInitPaymentSuccess || isPayPalPayment || isClickToPayRedirect
}

/** Function to check if the error is full page takeover error
 * @param {string} errorCode
 * @returns {boolean}
 */
export const isFullPageError = (errorCode = ''): boolean => {
    return Object.values(PlaceOrderErrorCode).includes(`error${errorCode}`)
}

/**
 * Function to check if billing address same as shipping or it's a mixed cart with sth
 * @param {boolean} isNotSameAddress is not same address flag
 * @param {FilteredCartData} cartItemsData cartdata
 * @returns {boolean} isNotSameAddressAndMixedCart
 */
export const isNotSameAddressAndMixedCart = (isNotSameAddress: boolean, cartItemsData: FilteredCartData): boolean => {
    return (
        isNotSameAddress ||
        !!(!cartItemsData?.sth?.length && (cartItemsData?.bopis?.length || cartItemsData?.services?.length))
    )
}

/**
 * Function to check if all conditions are met to render CT Money
 * @param {UserProfileData | null} userProfileData user profile data
 * @param {FilteredCartData} cartItemsData cartdata
 * @param {boolean} isAuthUser auth user flag
 * @param {boolean | undefined} isLabourSharedCart labour shared cart flag
 * @returns { boolean } display ct money
 */
export const displayCTMoney = (
    userProfileData: UserProfileData | null,
    cartItemsData: FilteredCartData,
    isAuthUser: boolean,
    isLabourSharedCart: boolean | undefined,
): boolean => {
    const userTriangleBalance = Number(parseFloat(userProfileData?.balance || '0').toFixed(MagicNumber.TWO))

    const userSubscribedLoyaltyProgram = !!userProfileData?.loyalty
    const userHasMoreThanZeroCTMoney = userTriangleBalance > 0
    // TODO: enable below line and remove next line when CDS starts sending flag isLoyaltyRedemptionEnabled
    // const isRedemptionEnabled = cartItemsData.cart?.paymentInfo?.isLoyaltyRedemptionEnabled || false
    const isRedemptionEnabled =
        cartItemsData.cart?.paymentInfo?.isLoyaltyRedemptionEnabled === undefined ||
        cartItemsData.cart?.paymentInfo?.isLoyaltyRedemptionEnabled
    return (
        !isLabourSharedCart &&
        isAuthUser &&
        userSubscribedLoyaltyProgram &&
        userHasMoreThanZeroCTMoney &&
        isRedemptionEnabled
    )
}

/**
 * Function to check if we need to render billing address form
 * @param {boolean} isNotSameAddress not same address flag
 * @param {FilteredCartData} cartItemsData cartdata
 * @returns {boolean} render billing form
 */
export const isBillingFormNeedToBeRendered = (isNotSameAddress: boolean, cartItemsData: FilteredCartData): boolean => {
    const hasSTH = checkDataLength(cartItemsData.sth)
    const hasBopis = checkDataLength(cartItemsData.bopis)
    const hasServices = checkDataLength(cartItemsData.services)
    return (isNotSameAddress && hasSTH) || (!hasSTH && hasBopis) || (!hasSTH && !hasBopis && hasServices)
}

/**
 * Function to get the billing address details for third party redirect to checkout (paypal, click to pay sth)
 * @param {FilteredCartData} cartItemsData cartdata
 * @param {React.MutableRefObject<InitialAddressType | null>} billingAddress billing address
 * @param {string} sameAsShippingLabel same as shipping label
 * @returns {string | InitialAddressType | null} billing address
 */
export const getBillingAddressForThirdParty = (
    cartItemsData: FilteredCartData,
    billingAddress: React.MutableRefObject<InitialAddressType | null>,
    sameAsShippingLabel: string,
): string | InitialAddressType | null => {
    return cartItemsData?.cart?.deliveryMode === FulfillmentMethods.BOPIS || isRedirectedAfterClickToPaySTHValidation()
        ? billingAddress.current
        : `${sameAsShippingLabel}`
}

/**
 * Determines if credit card errors need to be reset.
 * @param {CardErrors} iscardError - Object containing error flags for card fields.
 * @returns {boolean} Returns true if any of the card fields have errors, otherwise false.
 */
export const isResetCreditCardErrors = (iscardError: CardErrors): boolean => {
    return !!iscardError.cardNumberErrCode || !!iscardError.cvdErrCode
}
/**
 * Function to handle validation of text field
 * @param {string} selectedVal - value of the text field
 * @param {RegExp} regex - regex pattern to validate the text field
 * @param {Function} errorSetter - function to set the error message
 * @param {string} emptyErrorMsg - error message for empty field
 * @param {string} incorrectErrorMsg - error message for incorrect field
 * @param {boolean} isDirty - flag to check if the field is dirty
 * @param {boolean} isNextClicked - flag to check if the next button is clicked
 * @param {boolean} isRequired - flag to check if the field is required
 * @returns {boolean} - returns boolean value based on the validation
 */
export const validateTextField = (
    selectedVal: string,
    regex: RegExp,
    errorSetter: (val: string) => void | Dispatch<SetStateAction<string>>,
    emptyErrorMsg: string,
    incorrectErrorMsg: string,
    isDirty: boolean,
    isNextClicked: boolean,
    isRequired = true,
): boolean => {
    let errorMsg = emptySpace
    if (!selectedVal && isRequired && isNextClicked) {
        errorMsg = emptyErrorMsg
    } else if (!!selectedVal && isDirty) {
        const validate = !!selectedVal.length ? regex.test(selectedVal) : true
        errorMsg = !validate ? incorrectErrorMsg : emptySpace
    }
    errorSetter(errorMsg)
    return Boolean(errorMsg?.length)
}
