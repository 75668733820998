import React, { useState, useEffect, useRef, useCallback } from 'react'
import { initReactComponentEventType } from '../../global.type'
import { renderDynamicReactComponent } from '../../helpers/cms.helper'
import { ComponentType } from '../../helpers/cms.helper.type'

/* oustanding issues -
1) when targetting shoppable component for instance, the component gets updated with new prop data but child components like product cards are not re-rendered...
 ...their skeletons are displayed instead even when redux store contains product card data
2) warnings about creating root again after it was created using createRoot when calling renderDynamicReactComponent
*/

/**
 * InjectDynamicContentInit - Injects content provided by AEM into React components
 * @returns : null
 */
export const InjectDynamicContentInit: React.FC = () => {
    const [targetedElementState, setTargetedElementState] = useState<HTMLElement[]>([])

    const AEM_CUSTOM_EVENT = 'initReactComponent'

    const documentRef = useRef<Document>(document)

    documentRef.current.addEventListener(AEM_CUSTOM_EVENT, e => getTargetedElement(e))

    const getTargetedElement = useCallback((event?: CustomEvent<initReactComponentEventType>) => {
        const targetedElement = event?.detail.targetedElement as HTMLElement[]
        setTargetedElementState(targetedElement)
    }, [])

    useEffect(() => {
        targetedElementState.forEach((el: HTMLElement) => {
            const { props, component } = el.dataset
            const preparedCompObj: ComponentType = {
                name: component as string,
                element: el,
                props: JSON.parse(props as string) as Record<string, unknown>,
            }
            renderDynamicReactComponent(preparedCompObj)
        })
        return () => {
            documentRef.current.removeEventListener(AEM_CUSTOM_EVENT, e => getTargetedElement(e))
        }
    }, [targetedElementState, getTargetedElement])

    return null
}
