import { createAction } from 'typesafe-actions'

import { RecommendationResponseDataDTO, RecommendationsErrorResponse } from '../models/recommendations.interface'
import {
    FETCH_RECOMMENDATIONS_SUCCESS,
    FETCH_RECOMMENDATIONS_FAILURE,
    FETCH_RECOMMENDATIONS_ATC_STATUS,
    SET_RECOMMENDATIONS_LOADING,
    REMOVE_SPECIFIC_SCHEMA_FROM_RECOMMENDATION,
} from '../types/products/recommendationsData.actionTypes.constant'

export const fetchRecommendationsDataSuccessAction = createAction(
    FETCH_RECOMMENDATIONS_SUCCESS,
    (payload: RecommendationResponseDataDTO[]) => payload,
)<RecommendationResponseDataDTO[]>()

export const fetchRecommendationsDataErrorAction = createAction(
    FETCH_RECOMMENDATIONS_FAILURE,
    (payload: RecommendationsErrorResponse) => payload,
)<RecommendationsErrorResponse>()

export const setRecommendationsDataLoadingAction = createAction(
    SET_RECOMMENDATIONS_LOADING,
    (payload: boolean) => payload,
)<boolean>()

export const fetchRecommendationsAtcStatus = createAction(
    FETCH_RECOMMENDATIONS_ATC_STATUS,
    (payload: boolean) => payload,
)<boolean>()

export const removeSpecificSchemaFromRecommendationData = createAction(
    REMOVE_SPECIFIC_SCHEMA_FROM_RECOMMENDATION,
    (payload: string) => payload,
)<string>()
