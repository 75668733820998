import { Dispatch } from 'redux'
import { BannerImagePath } from '../components/Accounts/Rewards/Offers/OffersCard.type'
import {
    checkDataLength,
    offerComponentLocation,
    offerConstants,
    replaceStrWithDynamicVal,
    CertonaConstants,
    libUtils,
    localization,
    isArrayNotEmpty,
    statusLabel,
    magicNumber,
    replaceMultipleStrWithDynamicVal,
    ButtonStatusType,
    getDateOfBirth,
    dateOptions,
    excludeQuote,
    getDaysDifference,
    isAtleastOneParamValid,
} from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import {
    emailActivationUrl,
    lmsProfileIdParameterName,
    nppBaseRewardValue,
} from '../components/EmailOfferActivation/EmailOfferActivation.constant'
import {
    AwardValuesType,
    BenefitsValue,
    NppTieredProp,
    RewardsBoostValueType,
    RewardsVal,
} from '../components/PDPOfferBenefits/PDPOfferBenefits.type'
import { activateCustomerOffers, fetchCustomerOffers, fetchRecommendationsData } from '../redux/actions'
import { Offers } from '../redux/models/offers.interface'
import store from '../store'
import { getBannerId, getOfferBannerId } from '../utils/getCurrentBannerId'
import { getOffersPayload } from '../utils/offersCache.helper'
import { CurrentPrice, Price } from '../redux/models/cart.interface'
import { CertonaInitialization } from '../certona/certona.service'
import appCacheService from '../utils/appCacheService'
import { certonaEventConst } from '../certona/certona.constant'
import getEnvironment from '../utils/getEnvironment'
import { pageTypes } from '../config'
import getPageType from '../utils/getPageType'
import { roundOfToTwoDecimals } from '@nl/lib/src/utils/roundOfToTwoDecimals'
import { allWhitespace, commaRegEx, numberWithDotRegEx } from '../globalConstants'
import { DOLLAR_SYMBOL, DOT_SYMBOL, EMPTY_STRING, X_SYMBOL } from '@nl/lib/src/globalConstants/global.constant'
import { isHomeServiceItem } from '../components/BuyBox/BuyBox.helper'
import { isAutomotivePDP } from '../components/Vehicles/Vehicle.helper'
import {
    AddToCartRecommendationDataType,
    ProductCardType,
    OfferRecommendation,
    RecommendationResponseDataDTO,
} from '../redux/models/recommendations.interface'
import { CertonaSchemaType } from '../certona/certona.type'
import { IGlobalProps } from '../redux/models/commonContent.interface'
import { getComboExclusionsList } from '../components/LoyaltyOffers/LoyaltyOffers.helper'
import { ItemListType } from '../global.type'
import { ProductLevel, SkuCode } from '../components/BuyBox/BuyBox.type'
import { UserProfileData } from '../redux/models/user.profile.interface'
import { CoachMarkOffer, CoachMarkOfferCard } from '../redux/models/offerCoachMark.interface'
import { TriangleBenefitList } from '../redux/models/product.interface'

/**
 * function render card title
 * @param {boolean} isRewardCard
 * @param {string} pteTriangleRewardsLabel
 * @param {string} pteTriangleMCBenefitsLabel
 * @returns {string} returns the card title based on card type
 */
export const renderPTETitle = (
    isRewardCard: boolean,
    pteTriangleRewardsLabel: string,
    pteTriangleMCBenefitsLabel: string,
): string => {
    if (!!isRewardCard) {
        return pteTriangleRewardsLabel
    } else {
        return pteTriangleMCBenefitsLabel // for mastercard
    }
}

/**
 * function render card subtitle
 * @param {boolean} isRewardCard
 * @param {string} pteEverydayBonuses
 * @param {string} ptTriangleEarnCTMoneyText
 * @param {number} rewardsPercentage
 * @returns {string} returns the card subtitle based on card type
 */
export const renderPTESubtitle = (
    isRewardCard: boolean,
    pteEverydayBonuses: string,
    ptTriangleEarnCTMoneyText: string,
    rewardsPercentage: number,
): string => {
    const cartSubtitle = !!isRewardCard ? pteEverydayBonuses : ptTriangleEarnCTMoneyText
    return replaceStrWithDynamicVal(cartSubtitle, `${rewardsPercentage}%`)
}

/**
 * function return sum of all award values
 * @param {number[]} awardValues
 * @returns {number} returns sum of all award values
 */
export const renderPTEAmount = (awardValues: number[]): number => {
    return awardValues.reduce((a, c) => a + c, 0)
}

/**
 * function to check if offer status activated or not
 * @param {Offers} offer
 * @param {string} offerStatus
 * @returns {boolean} returns true/false based on offer status
 */
export const checkOfferStatus = (offer: Offers, offerStatus: string): boolean => {
    return offer?.offerStatus?.toLowerCase() === offerStatus.toLowerCase()
}

/**
 * function calculating Reward Boost value
 * @param {number} multiplierValue
 * @param {number} currentPrice
 * @param {number} quantity
 * @returns {number} returns rewards boost value
 */
export const renderPTERewardsBoostValue = (
    multiplierValue: number,
    currentPrice: number,
    quantity?: number,
): number => {
    return roundOfToTwoDecimals(Number(multiplierValue * nppBaseRewardValue * currentPrice * quantity))
}

/**
 * function calculating Reward Boost % value
 * @param {number} multiplierValue
 * @returns {number} returns rewards boost percentage value
 */
export const rewardsBoostPercentageValue = (multiplierValue: number): number => {
    return roundOfToTwoDecimals(Number(multiplierValue * nppBaseRewardValue * MagicNumber.HUNDRED))
}

/**
 * function to return total award value array
 * @param {Offers[]} offersData
 * @returns {number[]} returns total award value array
 */
export const getAwardValues = (offersData: Offers[]): AwardValuesType => {
    const totalAwardValue: number[] = []
    const totalAwardValueForActivatedOffers: number[] = []
    offersData?.forEach((offerObj: Offers) => {
        const awardValue = Number(offerObj.details.awardValue)
        totalAwardValue.push(awardValue)
        !!checkOfferStatus(offerObj, offerConstants.activated) && totalAwardValueForActivatedOffers.push(awardValue)
    })
    return { totalAwardValue, totalAwardValueForActivatedOffers }
}

/**
 * function to return class based on offer status
 * @param {boolean} isActivatedOffer
 * @param {string} prefixClass
 * @returns {string} function to return class based on offer status
 */
export const getOfferStatusClass = (isActivatedOffer: boolean, prefixClass: string): string => {
    return isActivatedOffer ? `${prefixClass}--activated` : `${prefixClass}--not-activated`
}

/**
 * @param {Offers[]} allOffers of offers to be activated -- can be one/many/all
 * @returns {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const handleOffersToActivated = (allOffers?: Offers[]): Offers[] => {
    return allOffers?.map((offer: Offers) => {
        return checkOfferStatus(offer, offerConstants.activate)
            ? {
                  ...offer,
                  offerStatus: offerConstants.activated,
              }
            : offer
    })
}

/** Function to render number of the offers which are unactivated
 * @param {Offers} offers
 * @returns {number} returns number of offers that are unactivated
 */
export const getUnactivatedOffersCount = (offers?: Offers[]): number => {
    const inActivatedOffers = offers?.filter(offer => offer.offerStatus === offerConstants.activate)
    return inActivatedOffers?.length || MagicNumber.ZERO
}

/** Function to render npp offer title based on offer type eg: MULTIPLIER/SPENDANDGET
 * @param {boolean} isMultiplierOffer
 * @param {string} pteNppSpendAndGetOfferTitle
 * @param {string} pteNppMultiplierOfferTitle
 * @param {number} pteNppMultiplierAmount
 * @returns {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const renderNPPOfferTitle = (
    isMultiplierOffer: boolean,
    pteNppSpendAndGetOfferTitle: string,
    pteNppMultiplierOfferTitle: string,
    pteNppMultiplierAmount: number,
): string => {
    if (!!isMultiplierOffer) {
        return replaceStrWithDynamicVal(pteNppMultiplierOfferTitle, pteNppMultiplierAmount)
    } else {
        return pteNppSpendAndGetOfferTitle
    }
}

/** Function to render npp offer value based on offer type eg: MULTIPLIER/SPENDANDGET
 * @param {boolean} isMultiplierOffer
 * @param {number} pteNppSpendAndGetOfferVal
 * @param {number} pteNppMultiplierVal
 * @returns {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const renderNPPOfferValue = (
    isMultiplierOffer: boolean,
    pteNppSpendAndGetOfferVal: number,
    pteNppMultiplierVal: number,
): number => {
    if (!!isMultiplierOffer) {
        return pteNppMultiplierVal
    } else {
        return pteNppSpendAndGetOfferVal
    }
}

/**
 * callback function to activate offers and where offersActivatedData is dispatched
 * if there is a param passed, its a single offer to activate only; otherwise, its ALL offers
 * @param {Offers} offer offers to activate
 * @param {React.Dispatch<React.SetStateAction<Offers>>} updateOfferState function to update offer status
 * @param {Offers[]} filteredOffers offers list
 * @param {string} offerComponent component name
 * @param {string[]} offersSortBy offers sortby list based on banner id'd
 * @param {string} loyaltyCardNumber  loyalty id for guest user
 * @returns {void}
 */
export const activateOffer = (
    offer: Offers | null,
    updateOfferState?: React.Dispatch<React.SetStateAction<Offers>>,
    filteredOffers?: Offers[],
    offerComponent?: string,
    offersSortBy?: string[],
    loyaltyCardNumber?: string | null,
): void => {
    let allOfferCodes: string[]
    const urlParams = new URLSearchParams(window.location.search)
    const activationUrl = window.location.href.includes(emailActivationUrl)
    const lmsProfileIdParam = activationUrl ? urlParams.get(lmsProfileIdParameterName) : null
    if (offerComponent === offerComponentLocation.loyaltyOffer) {
        if (checkDataLength(offer) && checkOfferStatus(offer, offerConstants.activate)) {
            allOfferCodes = [offer.offerCode]
            updateOfferState && updateOfferState(offer)
        }
    } else {
        if (checkDataLength(offer)) {
            allOfferCodes = [offer.offerCode]
            updateOfferState && updateOfferState(offer)
        } else {
            allOfferCodes = filteredOffers?.map((filteroffer: Offers) => {
                return filteroffer[offerConstants.offerCode as keyof typeof filteroffer]
            })
        }
    }
    if (allOfferCodes) {
        const payload = {
            offerCodes: allOfferCodes,
        }

        store.dispatch(
            lmsProfileIdParam
                ? activateCustomerOffers(payload, lmsProfileIdParam, offerComponent, offersSortBy)
                : activateCustomerOffers(payload, '', offerComponent, offersSortBy, loyaltyCardNumber),
        )
    }
}

/**
 * function to display offers after filtering with offer banner
 * @param {Offers[]} offersData
 * @param {string[]} offerBannerList
 * @returns {Offers[]} returns offers which are filtered
 */
export const getFilteredOffersBanner = (offersData?: Offers[], offerBannerList?: string[]): Offers[] => {
    return offersData?.filter(offer => offerBannerList?.some((banner: string) => offer?.banner.includes(banner))) || []
}

/**
 * function to render NPP multiplier offer award value
 * @param {number} pteNppMultiplierAmount
 * @param {number} currentPrice
 * @param {number} quantity
 * @returns {number} returns NPP multiplier offer award value
 */
export const renderMultiplierAwardValue = (
    pteNppMultiplierAmount: number,
    currentPrice: number,
    quantity?: number,
): number => {
    return Number(nppBaseRewardValue * pteNppMultiplierAmount * currentPrice * quantity) || MagicNumber.ZERO
}

/**
 * function to render bonus earn value
 * @param {boolean} isRewardCard
 * @param {boolean} isMultiplierOffer
 * @param {number} pteNppMultiplierAmount
 * @param {number} pteNppMultiplierAmountMCUser
 * @param {number} pteNppSpendAndGetOfferVal
 * @param {number} subtotal
 * @returns {number} return bonus earn value
 */
export const getBonusValue = (
    isRewardCard: boolean,
    isMultiplierOffer: boolean,
    pteNppMultiplierAmount: number,
    pteNppMultiplierAmountMCUser: number,
    pteNppSpendAndGetOfferVal: number,
    subtotal: number,
): number => {
    const nppMultiplierAmount = isRewardCard ? pteNppMultiplierAmount : pteNppMultiplierAmountMCUser
    const nppMultiplierAwardValue = renderMultiplierAwardValue(nppMultiplierAmount, subtotal, MagicNumber.ONE)
    const bonusEarnValue = isMultiplierOffer ? nppMultiplierAwardValue : pteNppSpendAndGetOfferVal
    return bonusEarnValue ? bonusEarnValue : MagicNumber.ZERO
}

/**
 * function to check whether bonus satisfy minimum purchase criteria
 * @param {boolean} isMultiplierOffer
 * @param {number} pteMinMultiplierVal
 * @param {number} pteMinSpendAndGetVal
 * @param {number} subtotal
 * @returns {boolean}
 */
export const bonusToDisplay = (
    isMultiplierOffer: boolean,
    pteMinMultiplierVal: number,
    pteMinSpendAndGetVal: number,
    subtotal: number,
): boolean => {
    const pteMinVal = isMultiplierOffer ? pteMinMultiplierVal : pteMinSpendAndGetVal
    return Boolean(subtotal >= pteMinVal)
}

/**
 * function to display notification Badge
 * @param {number} unActivatedOffersCount
 * @param {number} offersBadgeCountThreshold
 * @returns {boolean} returns true/false based on condition
 */
export const showBadge = (unActivatedOffersCount: number, offersBadgeCountThreshold: number): boolean => {
    return Boolean(!!unActivatedOffersCount && unActivatedOffersCount <= offersBadgeCountThreshold)
}

/**
 * Function to check NotificationBadge should display or not display
 * @param { Offers[] } offersToDisplay - offers to display
 * @param { number } offersBadgeCountThreshold
 * @returns {boolean}
 */
export const showNotificationBadge = (offersToDisplay?: Offers[], offersBadgeCountThreshold?: number): boolean => {
    return getUnactivatedOffersCount(offersToDisplay) && offersBadgeCountThreshold
        ? showBadge(getUnactivatedOffersCount(offersToDisplay), offersBadgeCountThreshold)
        : false
}

/** Function to filter offers which are Spend and Get and not redeemed offers
 * @param {Offers[]} offersData
 * @param {string} spendAndGetText
 * @param {string} spcBanner
 * @param {string[]} comboExclusions
 * @returns {Offers[]} return offers that are only Spend and Get and not redeemed offers
 */
export const spendAndGetFilteredOffers = (offersData: Offers[], spendAndGetText: string): Offers[] => {
    return (
        offersData?.filter(
            offer =>
                offer?.details.bonusType === offerConstants.flatrate &&
                offer?.offerStatus !== offerConstants.redeemed &&
                offer?.redeemed !== offerConstants.yes &&
                offer?.details?.categoryLevel1?.trim().toLowerCase() === spendAndGetText?.trim().toLowerCase() &&
                offer?.banner?.includes(getOfferBannerId() || getBannerId()),
        ) || []
    )
}

/** Function to call offers api
 * @param {Dispatch} dispatch
 * @param {string[]} offersSortBy
 */
export const loadOffers = (dispatch: Dispatch, offersSortBy?: string[], loyaltyCardNumber?: string | null) => {
    dispatch(fetchCustomerOffers(getOffersPayload(offersSortBy), null, null, null, loyaltyCardNumber))
}

const environment = getEnvironment()

/** Function to call certona to get offer recomendation list
 * @param {string} event
 * @returns {void}
 */
export const certonaOfferRecommendations = (event: string): void => {
    CertonaInitialization.triggerCertona({
        event: event,
        environment: environment,
        recommendations: true,
        filter: {
            storeid: appCacheService.preferredStoreId.get() || '',
            currencycode: CertonaConstants.CURRENCY,
            language: libUtils.getLanguage(),
        },
    })
}

/** Function to get certona event based on pagetype
 * @returns {string}
 */
export const getCertonaEvent = (): string => {
    const currentPageType = getPageType()
    const { emailOfferActivation, weeklyOffer, accountDasboard } = pageTypes
    switch (currentPageType) {
        case weeklyOffer:
            return certonaEventConst.weekelyOffer
        case accountDasboard:
            return certonaEventConst.dashboardOffer
        case emailOfferActivation:
            return certonaEventConst.emailOffer
        default:
            return ''
    }
}

/** Function to update current price
 * @param {Price} priceValue
 * @returns {CurrentPrice}
 */
export const updatingPriceValue = (priceValue: Price): CurrentPrice => {
    let updatePriceStructure
    const { value, maxPrice, minPrice } = !!priceValue && priceValue
    if (value) {
        updatePriceStructure = { value: value }
    } else if (minPrice) {
        updatePriceStructure = { value: minPrice }
    } else {
        updatePriceStructure = maxPrice ? { value: maxPrice } : { value: MagicNumber.ZERO }
    }
    return updatePriceStructure
}

/**
 * @param { number } baseValue
 * @param { number } nppOfferValue
 * @param { number } selectCardRewardsValue
 * @param { number } bonusValue
 * @returns { number }
 */
export const earnedTotalAmount = (
    baseValue: number,
    nppOfferValue: number,
    selectCardRewardsValue: number,
    bonusValue: number,
): number => {
    return baseValue + nppOfferValue + selectCardRewardsValue + bonusValue
}

/** Fetching and filtering loyalty badge value
 * TODO: Need to remove this function once actual solution from CDS implemented
 * @param {string[]} badges
 * @param {string} loyaltyLabel
 * @param {number} rewardsPercentage
 * @param {number} currentPrice
 * @param {number} quantity
 * @returns {RewardsBoostValueType}
 */
export const getRewatdBoostValue = (
    badges: string[],
    loyaltyLabel: string,
    currentPrice: number,
    quantity?: number,
    isLoyalty?: boolean,
    exclude?: string[],
    skuValidParam?: RewardsVal,
    isAllVariantSelected?: boolean,
): RewardsBoostValueType => {
    const filteredProductBadges = badges?.filter(badge => !exclude?.includes(badge.split('|')[0]))
    const loyaltyBadge = filteredProductBadges
        ?.filter(item => item?.toUpperCase().includes(loyaltyLabel?.toUpperCase()))
        .toString()
        ?.split('|')
    const rewardsValue = loyaltyBadge?.filter(item => item?.toUpperCase() !== loyaltyLabel?.toUpperCase()).toString()
    const loyaltyValue = rewardsValue?.replace(numberWithDotRegEx, EMPTY_STRING) as unknown as number
    const loyaltyRewardsBoostValue = (localization?.isFrench
        ? loyaltyValue?.toString()?.replace(commaRegEx, DOT_SYMBOL)
        : loyaltyValue) as unknown as number
    let badgeValue = ''
    let pteRewardsBoosterValue = MagicNumber.ZERO
    if (rewardsValue?.includes(DOLLAR_SYMBOL)) {
        badgeValue = rewardsValue?.replace(allWhitespace, EMPTY_STRING)
        pteRewardsBoosterValue = loyaltyRewardsBoostValue
    }
    if (rewardsValue?.includes(X_SYMBOL)) {
        badgeValue = `${loyaltyValue}${X_SYMBOL.toLowerCase()}`
        pteRewardsBoosterValue = renderPTERewardsBoostValue(loyaltyRewardsBoostValue, currentPrice, quantity)
    }
    const pteRewardsBoostPercentage = rewardsBoostPercentageValue(loyaltyRewardsBoostValue)
    if (isLoyalty) {
        return { badgeValue, pteRewardsBoosterValue, pteRewardsBoostPercentage }
    } else {
        return isAllVariantSelected
            ? getSkuValidRewardBoost(skuValidParam, quantity)
            : { badgeValue, pteRewardsBoosterValue, pteRewardsBoostPercentage }
    }
}

/** Fetching and filtering reward boost value
 * @param {ProductSkusData} prodSkuData
 * @param {string} code
 * @param {number} quantity
 * @returns {RewardsBoostValueType}
 */
export const getSkuValidRewardBoost = (skuValidParam: RewardsVal, quantity?: number): RewardsBoostValueType => {
    const selectedSKU = skuValidParam?.skuList?.list?.find(skuData => skuData?.code === skuValidParam?.skuList?.code)
    let badgeValue = ''
    let pteRewardsBoosterValue = 0
    let pteRewardsBoostPercentage = 0
    const { OfferRewardValue = '', RewardPercentage, OfferRule = '' } = selectedSKU?.loyalty?.Bonus || {}
    const RewardVal = Number(OfferRewardValue) * quantity

    badgeValue = RewardPercentage ? OfferRule.toLowerCase() : `${OfferRewardValue}${DOLLAR_SYMBOL}`
    pteRewardsBoosterValue = RewardPercentage ? Number(RewardVal) : Number(OfferRewardValue)
    pteRewardsBoostPercentage = Number(RewardPercentage) || 0
    return { badgeValue, pteRewardsBoosterValue, pteRewardsBoostPercentage }
}

/** Function to get whether product is genmerch or not
 * @param {string} fitmentTypeCode
 * @param {string} productWheelType
 * @param {string} productType
 * @param {number} quantitySelected
 * @returns {number}
 */
export const getQuantityforGenMerchProducts = (
    fitmentTypeCode: string,
    productWheelType: string,
    productType: string,
    quantitySelected: number,
): number => {
    return !isAutomotivePDP(fitmentTypeCode, productWheelType) && !isHomeServiceItem(productType)
        ? quantitySelected
        : MagicNumber.ONE
}

/**
 * Extract pcodes from the certona response.
 * @param {AddToCartRecommendationDataType[]} offerRecommendationData
 * @param {RecommendationResponseDataDTO[]}  recommendationsData
 * @param {Dispatch} dispatch
 * @returns {void}
 */
export const dispatchCertonaOffers = (
    offerRecommendationData: AddToCartRecommendationDataType[],
    recommendationsData: RecommendationResponseDataDTO[],
    dispatch: Dispatch,
): void => {
    offerRecommendationData.forEach(
        (
            schemeObj: AddToCartRecommendationDataType,
            index: number,
            recommendationArray: AddToCartRecommendationDataType[],
        ) => {
            const selectedSchemeId: string = Object.keys(schemeObj)[MagicNumber.ZERO]
            const pcode = recommendationArray[index][selectedSchemeId].pCode as unknown as string[]
            if (isArrayNotEmpty(pcode)) {
                dispatch(fetchRecommendationsData(pcode, recommendationsData, selectedSchemeId))
            }
        },
    )
}

/**
 * Get Recommendation array.
 * @param {CertonaSchemaType[]} certonaSchemaIdList
 * @param {string}  schemaId
 * @returns {AddToCartRecommendationDataType[]}
 */
export const getofferRecommendationObj = (
    certonaSchemaIdList: CertonaSchemaType[],
    schemaId: string,
): AddToCartRecommendationDataType[] => {
    const offerRecommendationObj = []
    const { getPCodes, getTitle, getItems } = CertonaInitialization.extractSchemeDetails(certonaSchemaIdList, schemaId)

    isArrayNotEmpty(getPCodes) &&
        offerRecommendationObj.push({
            [schemaId]: {
                pCode: getPCodes,
                title: getTitle,
                items: getItems,
            },
        })
    return offerRecommendationObj
}

/**
 * Get Recommendation List.
 * @param {CertonaSchemaType[]} certonaSchemaIdList
 * @param {string}  schemaId
 * @param {IGlobalProps}  commonContentAvailable
 * @param {string | undefined}  recommendedProductsLabel
 * @param {ProductCardType[]}  productList
 * @param {number}  maxNumberOfProductRecommendations
 * @returns {OfferRecommendation}
 */
export const getRecommendationList = (
    certonaSchemaIdList: CertonaSchemaType[],
    schemaId: string,
    commonContentAvailable: IGlobalProps,
    recommendedProductsLabel: string | undefined,
    productList: ProductCardType[],
    maxNumberOfProductRecommendations?: number,
): OfferRecommendation => {
    const recommendationTitle: string | undefined = certonaSchemaIdList?.find(
        (item: { scheme: string }) => item.scheme === schemaId,
    )?.explanation
    const language = libUtils.getLanguage()
    const priceProps = { ...commonContentAvailable?.product }
    const a11yStrikeOutPrice = commonContentAvailable?.accessibility?.a11yStrikeOutPrice
    const a11yStrikeOutPriceRange = commonContentAvailable?.accessibility?.a11yStrikeOutPriceRange
    return {
        title: recommendedProductsLabel || recommendationTitle,
        productList: productList,
        priceProps: priceProps || {},
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        language,
        selectedSchemaId: schemaId,
        isYouNeedThis: false,
        badgePriorities: commonContentAvailable?.badges,
        maximumNumberOfRecommendations: maxNumberOfProductRecommendations,
    }
}

/**
 * Get header button Label.
 * @param {Offers[]} filteredOffers
 * @param {ButtonStatusType}  allOffersActivated
 * @param {string}  activateOfferLabel
 * @param {string}  activateAllOffersLabel
 * @returns {string}
 */
export const getHeaderButtonLabel = (
    filteredOffers: Offers[],
    allOffersActivated: ButtonStatusType,
    activateOfferLabel: string,
    activateAllOffersLabel: string,
): string => {
    // Offer object/s that are in activate status
    const offersInActivateStatus =
        filteredOffers &&
        filteredOffers.filter(
            item => item?.offerStatus?.toLocaleLowerCase() === statusLabel.activateStatus?.toLocaleLowerCase(),
        )
    return allOffersActivated
        ? allOffersActivated?.label
        : replaceMultipleStrWithDynamicVal(
              offersInActivateStatus?.length === magicNumber.ONE ? activateOfferLabel : activateAllOffersLabel,
              [offersInActivateStatus?.length],
          )
}

/**
 * Get brand banner list
 * @param {boolean} enableOffersBrandFiltering
 * @param {BannerImagePath[]}  bannerIdToNameList
 * @returns {BannerImagePath[]}
 */
export const getBrandBannerList = (
    enableOffersBrandFiltering: boolean,
    bannerIdToNameList: BannerImagePath[],
): BannerImagePath[] | null => {
    return enableOffersBrandFiltering ? bannerIdToNameList : null
}

/**
 * Get Offers Expiry Date
 * @param {Offers} offer
 * @param {string}  offerEndsTodayLabel
 * @param {string}  offerEndsInLabel
 * @param {string} offerEndsLabel
 * @returns {string} returns offer expiry date
 */
export const getOfferCardExpiryDate = (
    offer: Offers,
    offerEndsTodayLabel: string,
    offerEndsInLabel: string,
    offerEndsLabel: string,
): string => {
    const offerExpiryInDaysForm =
        Number(offer.daysLeft) <= magicNumber.ONE
            ? offerEndsTodayLabel
            : replaceMultipleStrWithDynamicVal(offerEndsInLabel, [offer.daysLeft])
    const offerExpiryInDateForm = getDateOfBirth(offer?.offerEndDate, dateOptions.style1.month)
    return Number(offer.daysLeft) > magicNumber.SEVEN
        ? `${offerEndsLabel} ${offerExpiryInDateForm}`
        : offerExpiryInDaysForm
}

/**
 * get swapable data based on offerswapflag
 * @param {Offers[]} offers
 * @returns {Offers[]} return swappable filtered offer
 */
export const getSwappableOffer = (offers?: Offers[]): Offers[] | [] => {
    return (
        offers?.filter(
            offer => offer?.offerStatus !== offerConstants.redeemed && offer?.swappableFlag === offerConstants.yes,
        ) || []
    )
}

/**
 * Function will exclude particular banner combo coming from AEM from OfferList if toggle is enabled
 * @param {Offers[]} offers
 * @param {boolean} enableComboExclusions
 * @param {string} comboExclusions
 * @returns {Offers[]} return combo filtered offer
 */
export const getComboFilterList = (
    offers: Offers[],
    enableComboExclusions?: boolean,
    comboExclusions?: string,
): Offers[] => {
    return isArrayNotEmpty(offers)
        ? enableComboExclusions
            ? getComboExclusionsList(offers, excludeQuote(comboExclusions))
            : offers
        : []
}

/**
 * function to get tiered event details based on Qualifier amount
 * @param {number} totalPrice total price calculation based on quantity
 * @param {NppTieredProp} nppTieredProps all qualifier amount and reward amount
 * @returns {ItemListType} returns tiered event title and value
 */
export const renderTieredEventValue = (totalPrice: number, nppTieredProps: NppTieredProp): ItemListType => {
    const {
        nppTieredEventOfferText,
        nppTieredEventQAmtT2,
        nppTieredEventRewardsValT1,
        nppTieredEventRewardsValT2,
        nppTieredEventQAmtT1,
    } = nppTieredProps
    if (totalPrice >= nppTieredEventQAmtT2) {
        return {
            title: replaceMultipleStrWithDynamicVal(nppTieredEventOfferText, [
                nppTieredEventQAmtT2,
                nppTieredEventRewardsValT2,
            ]),
            value: nppTieredEventRewardsValT2,
        }
    } else {
        return {
            title: replaceMultipleStrWithDynamicVal(nppTieredEventOfferText, [
                nppTieredEventQAmtT1,
                nppTieredEventRewardsValT1,
            ]),
            value: nppTieredEventRewardsValT1,
        }
    }
}

/**
 * function to get npp offer list
 * @param {boolean} isMultiplierOffer is Multiplier Offer Type
 * @param {ItemListType} pteSpendAndGet spendAndGet data
 * @param {number} nppMultiAmount npp multiplier amount based on usertype
 * @param {string} pteNppMultiplierOfferTitle npp multiplier title
 * @param {number} nppMultiplierAwardValue npp multiplier value
 * @returns {ItemListType} returns npp offer list
 */
export const getNppOfferVal = (
    isMultiplierOffer: boolean,
    pteSpendAndGet: ItemListType,
    nppMultiAmount: number,
    pteNppMultiplierOfferTitle: string,
    nppMultiplierAwardValue: number,
): ItemListType => {
    const nppOfferTitle = renderNPPOfferTitle(
        isMultiplierOffer,
        pteSpendAndGet?.title as string,
        pteNppMultiplierOfferTitle,
        nppMultiAmount,
    )
    const nppOfferValue = renderNPPOfferValue(
        isMultiplierOffer,
        pteSpendAndGet?.value as number,
        nppMultiplierAwardValue,
    )
    return {
        title: nppOfferTitle,
        value: nppOfferValue,
    }
}

/**
 * Function to get trianglemaster,trianglebase or triangleselect benefits
 * @param { SkuCode } sku
 * @param {ProductLevel} defaultSelect
 * @returns {TriangleBenefitList | undefined} return triangle Benefit
 */
const getTriangleBenefits = (sku: SkuCode, defaultSelect?: ProductLevel): TriangleBenefitList | undefined => {
    const selectedSKU = sku?.list?.find(skuData => skuData?.code === sku?.code)
    return defaultSelect?.isAllVariantSelected ? selectedSKU : defaultSelect?.data
}

/**
 * Function to calculate RewardsBaseLoyaltyValue
 * @param { RewardsVal } info
 * @returns {number} return RewardsBaseLoyaltyValue
 */
export const getRewardsBaseLoyaltyValue = (info: RewardsVal): number => {
    const { skuList, productLevel, selectedQty = 0 } = info || {}
    const selectedSKU = getTriangleBenefits(skuList, productLevel)
    return selectedSKU ? selectedSKU?.triangleBenefits?.tsRewardsBaseLoyaltyValue * selectedQty : 0
}

/**
 * Function to calculate triangle Select Benefits or mastercard reward value
 * @param {RewardsVal} info card details
 * @param {boolean} isLoyaltyUser should set card for loyalty user
 * @returns {BenefitsValue} return triangle Select Benefits or mastercard reward value object
 */
export const getTriangleBenefitsValue = (info: RewardsVal, isLoyaltyUser?: boolean): BenefitsValue => {
    let benefitValue: BenefitsValue = {
        masterCardValue: 0,
        selectCardValue: 0,
    }
    const { selectedPtE, selectedQty = 0, skuList, productLevel } = info || {}
    const selectedSKU = getTriangleBenefits(skuList, productLevel)
    const { triangleBenefits, triangleSelectBenefits } = selectedSKU || {}
    const { tsTriangleMastercardBenefitsValue = 0 } = triangleBenefits || {}
    const { tsEverydayDiscountValue = 0, tsBrandDiscountValue = 0, tsBrand } = triangleSelectBenefits || {}

    if (isAtleastOneParamValid(selectedPtE?.isTriangleMasterCard, isLoyaltyUser) && tsTriangleMastercardBenefitsValue) {
        benefitValue = {
            ...benefitValue,
            masterCardValue: Number(tsTriangleMastercardBenefitsValue) * selectedQty,
        }
    }
    if (isAtleastOneParamValid(selectedPtE?.isTriangleSelect, isLoyaltyUser) && tsEverydayDiscountValue) {
        const everydayDiscount = Number(tsEverydayDiscountValue)
        const brandDiscount = tsBrand ? Number(tsBrandDiscountValue) : 0
        benefitValue = {
            ...benefitValue,
            selectCardValue: (everydayDiscount + brandDiscount) * selectedQty,
        }
    }
    return benefitValue
}

/** Function to calculate total reward value of triangle Select Benefits and mastercard reward value
 * @param {RewardsVal} info
 * @returns {number} return total reward value of triangle Select Benefits and mastercard reward value
 */
export const getTriangleMCAndSelectTotal = (info: RewardsVal): number => {
    const triangleMC = info?.selectedPtE?.isTriangleMasterCard ? getTriangleBenefitsValue(info).masterCardValue : 0
    const triangleSelect = info?.selectedPtE?.isTriangleSelect ? getTriangleBenefitsValue(info).selectCardValue : 0
    return Number(triangleMC + triangleSelect)
}

/**
 * Function to getdate difference lable for offers
 * @param { string } offerEndsTodayLabel offer end today label
 * @param {string} offerEndsLabel offer end in [0] days label
 * @param {string} endDate offer end date
 * @returns {string} return offer date label
 */
export const getOfferDateLabel = (offerEndsTodayLabel: string, offerEndsLabel: string, endDate: string): string => {
    return getDaysDifference(endDate) == String(0)
        ? offerEndsTodayLabel
        : `${replaceStrWithDynamicVal(offerEndsLabel, getDaysDifference(endDate))}`
}

/**
 * Function to set Loyalty id to local storage
 * @param {UserProfileData} profileData user profile data from API
 * @returns {void}
 */
export const setLoyaltyId = (profileData: UserProfileData): void => {
    const loyaltyId = profileData?.loyalty?.id ?? profileData?.id
    if (loyaltyId) {
        const isLoyaltyIdExist = appCacheService.lmsId.get()
        !isLoyaltyIdExist && appCacheService.lmsId.set(loyaltyId)
    }
}

/**
 * function to render offers list
 * @param {String} bannerId selected banner
 * @param {CoachMarkOffer} swapOffersTourBannerList selected banner List
 * @returns {CoachMarkOfferCard} sorted list based on isSwapOffer flag
 */
export const templateOffersByBannerId = (
    bannerId: string,
    swapOffersTourBannerList: CoachMarkOffer[],
): CoachMarkOfferCard[] => {
    const result = swapOffersTourBannerList?.find(item => item.bannerId === bannerId)
    return result ? result.swapOffersTourList.sort((a, b) => Number(a.isSwapOffer) - Number(b.isSwapOffer)) : []
}
