/* eslint-disable complexity */
import {
    ProductResponseData,
    ProductSkusData,
    SizeChartDataType,
    ProductState,
    BrandValues,
} from '../models/product.interface'
import { Price } from '../models/cart.interface'
import { ActionType, getType } from 'typesafe-actions'

import { MagicNumber } from './../../analytics/analytics.type'
import {
    fetchProductDataSuccessAction,
    fetchProductDataErrorAction,
    fetchProductDataBySkuAction,
    setSelectedSizeProductCodeAction,
    setSelectedCurrentPriceAction,
    setStickyBuyboxCTAClickedAction,
    toggleAddToCartCTAAction,
    setIfAllVariantsSelectedAction,
    setFBTProductCodeAction,
    setBuyBoxPropsAction,
    setIsOutOfStockAction,
    setQuantitySelectedAction,
    setSelectedImageUrlAction,
    resetProductSKUDataAction,
    setRearQuantitySelectedAction,
    setFrontQuantitySelectedAction,
    fetchFBTProductDataBySkuAction,
    setIsTireOrWheelShopWithNoVehicleAction,
    setIsSKUPresentInUrlAction,
    setIsOOSCurrentStoreAction,
    setIsPriceAvailabilityApiDoneAction,
    setIsProductDataAvailableAction,
    setCartFlyoutOpenAction,
    skuNotFoundAction,
    setHeliumInflationSelectedAction,
    fetchVariantsProductDataBySkuAction,
    fetchVariantsProductDataBySkuErrorAction,
    setIsVehicleChangedAction,
    fetchSizeChartDataAction,
    setSelectedPartNumber,
    resetSelectedPartNumber,
    setSelectedVariantIdAction,
    setSelectedVariantAction,
    setHeliumInflationGlobalToggleAction,
} from '../actionCreators'
import * as actions from '../actionCreators'
import { firstVariantKey, secondVariantKey } from '../../components/BuyBox/BuyBox.constant'
import { BuyboxProps, ProductDataForABTest } from '../../components/BuyBox/BuyBox.type'

type Action = ActionType<typeof actions>

const initialProductErrorResponse = {
    data: null,
    status: null,
}

export const initialState: ProductState = {
    productData: {} as ProductResponseData,
    productSkuData: {} as ProductSkusData,
    quantitySelected: 1,
    imageSelectedUrl: '',
    selectedFirstVariant: '',
    selectedFirstVariantDisplayName: '',
    selectedCurrentPrice: {} as Price,
    selectedSecondVariant: '',
    selectedProductCode: '',
    selectedThirdVariant: '',
    selectedStoreId: '363', // To be updated when store selector logic is integrated
    selectedFirstVariantId: '',
    selectedSecondVariantId: '',
    selectedThirdVariantId: '',
    isStickyBuyboxCTAClicked: {
        event: null,
        isClicked: false,
        clickedCTAName: '',
    },
    showAddToCartOnStickyBuyBox: false,
    isAllVariantSelected: false,
    fbtProductCode: [''],
    buyBoxPropsState: {} as BuyboxProps,
    isOutOfStock: false,
    rearQuantitySelected: MagicNumber.TWO,
    frontQuantitySelected: MagicNumber.TWO,
    fbtProductSkuData: {} as ProductSkusData,
    variantsProductSkuData: {} as ProductSkusData,
    productErrorResponse: initialProductErrorResponse,
    isTireOrWheelShopWithNoVehicle: false,
    isProductDataAvailable: false,
    isPriceAvailabilityApiDone: false,
    isPriceCallCompletedForSwatches: false,
    isVehicleChanged: false,
    isOOSCurrentStore: null,
    isCartFlyoutOpen: false,
    skuNotFound: false,
    sizeChartData: {} as unknown as SizeChartDataType,
    brandList: [] as BrandValues[],
    selectedPartNumber: '',
    productListForABTestProps: {} as ProductDataForABTest,
    appointmentAvailabilityDateTimeSlotsLoading: false,
    appointmentAvailabilityDateTimeSlots: null,
    appointmentAvailabilityDateTimeSlotsError: null,
}

export const productReducer = (state: ProductState = initialState, action: Action): ProductState => {
    switch (action.type) {
        case getType(fetchProductDataSuccessAction): {
            return { ...state, productData: action.payload, productErrorResponse: initialProductErrorResponse }
        }
        case getType(setSelectedVariantAction): {
            if (action.payload.variantLevel === firstVariantKey) {
                return {
                    ...state,
                    selectedFirstVariant: action.payload.selectedVariant,
                    selectedFirstVariantDisplayName: action.payload.selectedFirstVariantDisplayName,
                }
            } else if (action.payload.variantLevel === secondVariantKey) {
                return {
                    ...state,
                    selectedSecondVariant: action.payload.selectedVariant,
                }
            } else {
                return { ...state, selectedThirdVariant: action.payload.selectedVariant }
            }
        }
        case getType(fetchProductDataErrorAction): {
            return { ...state, productErrorResponse: action.payload }
        }
        case getType(fetchProductDataBySkuAction): {
            return { ...state, productSkuData: action.payload }
        }
        case getType(setSelectedSizeProductCodeAction): {
            return { ...state, selectedProductCode: action.payload }
        }
        case getType(setSelectedCurrentPriceAction): {
            return { ...state, selectedCurrentPrice: action.payload }
        }

        case getType(setSelectedVariantIdAction): {
            if (action.payload.variantLevel === firstVariantKey) {
                return {
                    ...state,
                    selectedFirstVariantId: action.payload.selectedVariantID,
                }
            } else if (action.payload.variantLevel === secondVariantKey) {
                return {
                    ...state,
                    selectedSecondVariantId: action.payload.selectedVariantID,
                }
            } else {
                return {
                    ...state,
                    selectedThirdVariantId: action.payload.selectedVariantID,
                }
            }
        }
        case getType(setStickyBuyboxCTAClickedAction): {
            return {
                ...state,
                isStickyBuyboxCTAClicked: action.payload,
            }
        }
        case getType(toggleAddToCartCTAAction): {
            return {
                ...state,
                showAddToCartOnStickyBuyBox: action.payload,
            }
        }
        case getType(setIfAllVariantsSelectedAction): {
            return { ...state, isAllVariantSelected: action.payload }
        }
        case getType(setFBTProductCodeAction): {
            return { ...state, fbtProductCode: action.payload }
        }
        case getType(setBuyBoxPropsAction): {
            return { ...state, buyBoxPropsState: action.payload }
        }
        case getType(setIsOutOfStockAction): {
            return { ...state, isOutOfStock: action.payload }
        }
        case getType(setIsOOSCurrentStoreAction): {
            return { ...state, isOOSCurrentStore: action.payload }
        }
        case getType(setQuantitySelectedAction): {
            return { ...state, quantitySelected: action.payload }
        }
        case getType(setSelectedImageUrlAction): {
            return { ...state, imageSelectedUrl: action.payload }
        }
        case getType(resetProductSKUDataAction): {
            return {
                ...state,
                productSkuData: {} as ProductSkusData,
            }
        }
        case getType(setFrontQuantitySelectedAction): {
            return {
                ...state,
                frontQuantitySelected: action.payload,
            }
        }
        case getType(setRearQuantitySelectedAction): {
            return { ...state, rearQuantitySelected: action.payload }
        }
        case getType(fetchFBTProductDataBySkuAction): {
            return { ...state, fbtProductSkuData: action.payload }
        }
        case getType(fetchVariantsProductDataBySkuAction): {
            return { ...state, variantsProductSkuData: action.payload, isPriceCallCompletedForSwatches: true }
        }
        case getType(fetchVariantsProductDataBySkuErrorAction): {
            return { ...state, productErrorResponse: action.payload, isPriceCallCompletedForSwatches: true }
        }
        case getType(setIsTireOrWheelShopWithNoVehicleAction): {
            return { ...state, isTireOrWheelShopWithNoVehicle: action.payload }
        }
        case getType(setIsSKUPresentInUrlAction): {
            return { ...state, isSKUPresentInUrl: action.payload }
        }
        case getType(setIsProductDataAvailableAction): {
            return { ...state, isProductDataAvailable: action.payload }
        }
        case getType(setIsPriceAvailabilityApiDoneAction): {
            return { ...state, isPriceAvailabilityApiDone: action.payload }
        }
        case getType(setCartFlyoutOpenAction): {
            return { ...state, isCartFlyoutOpen: action.payload }
        }
        case getType(setHeliumInflationSelectedAction): {
            return { ...state, isHeliumInflationSelected: action.payload }
        }
        case getType(setIsVehicleChangedAction): {
            return { ...state, isVehicleChanged: action.payload }
        }
        case getType(skuNotFoundAction): {
            return { ...state, skuNotFound: true }
        }
        case getType(fetchSizeChartDataAction): {
            return { ...state, sizeChartData: action.payload }
        }
        case getType(setSelectedPartNumber): {
            return { ...state, selectedPartNumber: action.payload }
        }
        case getType(resetSelectedPartNumber): {
            return { ...state, selectedPartNumber: '' }
        }
        case getType(actions.setProductListABTestSuccessAction): {
            // reducer for A/B test on PDP's
            return { ...state, productListForABTestProps: action.payload }
        }
        case getType(actions.setAppointmentAvailabilityDateTimeSlotsLoading): {
            return { ...state, appointmentAvailabilityDateTimeSlotsLoading: action.payload }
        }
        case getType(actions.fetchAppointmentAvailabilityDateTimeSlotsSuccessAction): {
            return { ...state, appointmentAvailabilityDateTimeSlots: action.payload }
        }
        case getType(actions.fetchAppointmentAvailabilityDateTimeSlotsErrorAction): {
            return { ...state, appointmentAvailabilityDateTimeSlotsError: action.payload }
        }
        case getType(actions.resetAppointmentAvailabilityDateTimeSlots): {
            return {
                ...state,
                appointmentAvailabilityDateTimeSlots: null,
                appointmentAvailabilityDateTimeSlotsError: null,
            }
        }
        case getType(setHeliumInflationGlobalToggleAction): {
            return { ...state, isATCHeliumInflationToggleOffError: true }
        }
        default:
            return state
    }
}
