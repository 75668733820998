import { Dispatch } from 'redux'

import criteoService from '../../services/criteoService'
import {
    fetchSponsoredAdsErrorAction,
    fetchSponsoredAdsRequestAction,
    fetchSponsoredAdsSuccessAction,
    setSponsoredAdsCarouselProducts,
} from '../actionCreators/sponsoredAds.actionCreators'
import { CriteoEventType } from '../../services/criteoService/criteoService.constants'
import {
    CriteoProduct,
    CriteoUniversalParams,
    CriteoViewItemParams,
} from '../../services/criteoService/criteo.interface'
import productDetailsService from '../../services/productDetailsService'

/**
 * Fetches sponsored ads using search keyword or category ID and dispatches appropriate actions.
 * @param {string} criteoBaseUrl - The base URL for the Criteo request.
 * @param {string} partnerId - The partner ID for Criteo.
 * @param {string} noLog - Flag to determine if logging should be disabled.
 * @param {string} retailerVisitorId - The retailer visitor ID.
 * @param {string} storeId - The store ID or region ID.
 * @param {boolean} isMobile - Indicates if the environment is mobile.
 * @param {number} pageNumber - The current page number of the pagination.
 * @param {string} identifier - Keywords for the search query or category ID.
 * @param {string} [customerId] - The optional customer ID.
 * @param {boolean} isKeyword - Flag to determine if the identifier is a keyword (true) or category ID (false).
 * @param {boolean} isNullSearch - Flag to determine if the criteo event to trigger is NullSearch
 * @returns {Promise<void>}
 */
export const fetchSponsoredAds =
    (
        criteoBaseUrl: string,
        partnerId: string,
        noLog: string,
        retailerVisitorId: string,
        storeId: string,
        isMobile: boolean,
        pageNumber: number,
        identifier: string,
        customerId?: string,
        isKeyword?: boolean,
        isNullSearch = false,
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchSponsoredAdsRequestAction())

        const fetchCriteoData = isKeyword
            ? criteoService.fetchCriteoData({
                  eventType: CriteoEventType.VIEW_SEARCH_RESULT,
                  keywords: identifier,
                  criteoBaseUrl,
                  partnerId,
                  noLog,
                  retailerVisitorId,
                  storeId,
                  isMobile,
                  pageNumber,
                  customerId,
                  isNullSearch,
              })
            : criteoService.fetchCriteoData({
                  eventType: CriteoEventType.VIEW_CATEGORY,
                  category: identifier,
                  criteoBaseUrl,
                  partnerId,
                  noLog,
                  retailerVisitorId,
                  storeId,
                  isMobile,
                  pageNumber,
                  customerId,
              })

        return fetchCriteoData
            .then(response => {
                // Trigger root level beacon
                navigator.sendBeacon(response.data.OnLoadBeacon)

                const simplifiedResponse = criteoService.renamePlacementKeys(response.data)
                dispatch(fetchSponsoredAdsSuccessAction(simplifiedResponse))
            })
            .catch(error => {
                console.warn(error)
                dispatch(fetchSponsoredAdsErrorAction())
            })
    }

/**
 * Fetches sponsored ads data for the Product Detail Page (PDP).
 * @param {CriteoUniversalParams} baseParams - The universal parameters for the Criteo API.
 * @param {string} baseParams.criteoBaseUrl - The base URL for the Criteo API.
 * @param {string} baseParams.partnerId - The partner ID for the Criteo API.
 * @param {string} baseParams.noLog - A parameter related to logging in Criteo.
 * @param {string} baseParams.retailerVisitorId - The retailer visitor ID for tracking.
 * @param {string} baseParams.storeId - The store ID associated with the Criteo setup.
 * @param {boolean} baseParams.isMobile - Indicates whether the environment is mobile.
 * @param {string} [baseParams.customerId] - Optional customer ID for personalized ads.
 * @param {CriteoViewItemParams} viewItemParams - The specific parameters for the view item event.
 * @param {string} viewItemParams.parentItem - The parent item ID or reference.
 * @param {string} viewItemParams.price - The price of the viewed item.
 * @param {boolean} viewItemParams.isAvailable - Indicates if the item is available.
 * @param {string} [viewItemParams.category] - Optional category information of the viewed item.
 * @returns {Promise<void>}
 */
export const fetchPDPSponsoredAds =
    (baseParams: CriteoUniversalParams, viewItemParams: CriteoViewItemParams) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchSponsoredAdsRequestAction())

        const { criteoBaseUrl, partnerId, noLog, retailerVisitorId, storeId, isMobile, customerId } = baseParams
        const { isAvailable, parentItem, price, category } = viewItemParams

        return criteoService
            .fetchCriteoData({
                eventType: CriteoEventType.VIEW_ITEM,
                criteoBaseUrl,
                partnerId,
                noLog,
                retailerVisitorId,
                storeId,
                isMobile,
                customerId,
                isAvailable,
                parentItem,
                price,
                category,
            })
            .then(response => {
                // Trigger root level beacon
                navigator.sendBeacon(response.data.OnLoadBeacon)

                const simplifiedResponse = criteoService.renamePlacementKeys(response.data)
                dispatch(fetchSponsoredAdsSuccessAction(simplifiedResponse))
            })
            .catch(error => {
                console.warn(error)
                dispatch(fetchSponsoredAdsErrorAction())
            })
    }

/**
 * Fetch additional details like badges, ratings and price for Sponsored Product cards
 * @param {CriteoProduct[]} carouselDataFromCriteo - data from criteo carousel placement
 * @param {string} storeId - The preferred store id
 * @returns {Promise<void>}
 */
export const fetchSponsoredCarouselDetails =
    (carouselDataFromCriteo: CriteoProduct[], storeId: string) =>
    (dispatch: Dispatch): Promise<void> => {
        const pCodes = carouselDataFromCriteo.map(product => product.ParentSKU)

        return productDetailsService
            .getProductInfoUsingPCodesBasic(pCodes, storeId, false)
            .then(response => {
                const productsWithDetails = criteoService.attachProductDetails(
                    carouselDataFromCriteo,
                    response.data.products,
                )

                dispatch(setSponsoredAdsCarouselProducts(productsWithDetails))
            })
            .catch(err => console.warn(err))
    }
