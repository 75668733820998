import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { TextInput, Button } from '@nl/lib'
import { PHONE_MAX_LENGTH } from '../../../globalConstants/global.constant'
import { PREFIX, previousElementName } from '../../../config'
import PropTypes from 'prop-types'
import { telephoneRegex, phoneNumberFormat } from '../../../globalConstants/regexPatterns.constant'
import { toggleOrderNotificationSubscription } from '../../../redux/actions/orderConfirmation.action'

import { OrderUpdatesSubscriptionPopupProps } from '../OrderUpdatesSubscription.types'

const OrderUpdatesSubscriptionPopup: React.FC<OrderUpdatesSubscriptionPopupProps> = ({ ...props }) => {
    const { popupData, closePopup, isSignedInUser, orderCode } = props

    const dispatch = useDispatch()

    const {
        popupTitle,
        popupSubtitle,
        popupErrorMessage,
        popupInputPlaceholder,
        popupPolicyMessage,
        popupPrivacyPolicyLabel,
        privacyPolicyLink,
        popupMessage,
        popupAgreeButton,
        popupDisagreeButton,
    } = popupData

    const [errorMessage, setErrorMessage] = useState('')
    const [inputNumber, setInputNumber] = useState('')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target
        if (/[^-\d]/.exec(value)) {
            return
        }
        setInputNumber(value.replace(phoneNumberFormat, '$1-$2-$3') || '')
    }

    const previousElement: HTMLElement | null = document.querySelector(`button[${previousElementName}]`)
    const processSubscribeClick = (): void => {
        if (telephoneRegex.test(inputNumber)) {
            dispatch(toggleOrderNotificationSubscription(isSignedInUser, orderCode, inputNumber, true))
            closePopup()
        } else {
            setErrorMessage(popupErrorMessage)
        }
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName)
        }
    }

    return (
        <div className={`${PREFIX}-order-updates-popup__body`} data-testid="order-updates-popup">
            <div className={`${PREFIX}-order-updates-popup__body-container`}>
                <div className={`${PREFIX}-order-updates-popup__title-container`}>
                    <h1 className={`${PREFIX}-order-updates-popup__title`}>{popupTitle}</h1>
                </div>
                <div className={`${PREFIX}-order-updates-popup__subtitle`}>{popupSubtitle}</div>
                <div className={`${PREFIX}-order-updates-popup__input`}>
                    <TextInput
                        id="number-input"
                        value={inputNumber}
                        size="medium"
                        placeholder={popupInputPlaceholder}
                        error={errorMessage}
                        maxLength={PHONE_MAX_LENGTH}
                        customOnChange={true}
                        onChange={handleChange}
                        label=""
                    />
                </div>
                <div className={`${PREFIX}-order-updates-popup__policy`}>
                    <p>
                        {popupPolicyMessage}{' '}
                        <a
                            target="_blank"
                            href={privacyPolicyLink}
                            rel="noreferrer noopener"
                            data-link-value={popupPrivacyPolicyLabel}>
                            {popupPrivacyPolicyLabel}
                        </a>
                        {'.'}
                    </p>
                </div>
                <div className={`${PREFIX}-order-updates-popup__message`}>{popupMessage}</div>
            </div>
            <div className={`${PREFIX}-order-updates-popup__buttons-container`}>
                <div className={`${PREFIX}-order-updates-popup__agree-button`}>
                    <Button type="primary" size="medium" onClick={processSubscribeClick}>
                        {popupAgreeButton}
                    </Button>
                </div>
                <div className={`${PREFIX}-order-updates-popup__disagree-button`}>
                    <Button type="tertiary" size="small" onClick={() => closePopup()}>
                        {popupDisagreeButton}
                    </Button>
                </div>
            </div>
        </div>
    )
}

OrderUpdatesSubscriptionPopup.propTypes = {
    closePopup: PropTypes.func.isRequired,
    popupData: PropTypes.shape({
        popupTitle: PropTypes.string.isRequired,
        popupSubtitle: PropTypes.string.isRequired,
        popupErrorMessage: PropTypes.string.isRequired,
        popupInputPlaceholder: PropTypes.string.isRequired,
        popupPolicyMessage: PropTypes.string.isRequired,
        privacyPolicyLink: PropTypes.string.isRequired,
        popupMessage: PropTypes.string.isRequired,
        popupPrivacyPolicyLabel: PropTypes.string.isRequired,
        popupAgreeButton: PropTypes.string.isRequired,
        popupDisagreeButton: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        deactivateSubtitle: PropTypes.string.isRequired,
        deactivateButtonLabel: PropTypes.string.isRequired,
        privacyPolicyLabel: PropTypes.string.isRequired,
        genericErrorTitle: PropTypes.string.isRequired,
        genericErrorSubtitle: PropTypes.string.isRequired,
        genericErrorCloseButtonLabel: PropTypes.string.isRequired,
    }).isRequired,
    isSignedInUser: PropTypes.bool.isRequired,
    orderCode: PropTypes.string.isRequired,
}

export default OrderUpdatesSubscriptionPopup
