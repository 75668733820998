import React from 'react'
import { useSelector } from 'react-redux'
import clx from 'classnames'

import { isPrerenderOrNot } from '@nl/lib'
import { getLanguage } from '@nl/lib/src/utils'

import HallOfFameProductTile from './HallOfFameProductTile'
import { hallOFameProductGrid, hallOFameProductTile, hallOFameSkeleton } from './HallOfFame.classnames'

import {
    AuthoriableProductProps,
    HallOfFameProductGridProps,
    HallOfFameProductTileProps,
    Language,
    ProductDetailsProps,
} from './HallOfFame.types'

import { commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import { HALL_OF_FAME_PRODUCTS_AMOUNT } from './HallOfFame.constants'
import PrerenderHallOfFameProductTile from './PrerenderHallOfFameProductTile'
import { IAccessibility, IGeneral, IGlobalProps, IProduct } from '../../../redux/models/commonContent.interface'

/**
 * HallOfFameProductGrid
 * @param {HallOfFameProductGridProps} props - props
 * @returns {JSX.Element} - HallOfFameProductGrid
 */
function HallOfFameProductGrid({
    products,
    isLoading,
    productDetailsProps = {} as ProductDetailsProps,
}: HallOfFameProductGridProps): JSX.Element {
    const {
        badges,
        general = {} as IGeneral,
        automotive = {} as IGlobalProps['automotive'],
        product = {} as IProduct,
        accessibility = {} as IAccessibility,
    } = useSelector(commonContentAvailableSelector)

    const { roadRatedLabel, attributeFrontLabel, attributeRearLabel } = automotive
    const {
        eachLabel,
        fromLabel,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
    } = product
    const { a11yStrikeOutPrice, a11yStrikeOutPriceRange, a11yCloseIconLabel, a11yTooltipIcon } = accessibility
    const { plusMinusSymbol = '' } = general

    const language = getLanguage() as Language

    const {
        specificationsProps = {} as typeof productDetailsProps.specificationsProps,
        availabilityProps = {} as typeof productDetailsProps.availabilityProps,
    } = productDetailsProps

    const authorableProps = {
        roadRatedLabel,
        eachLabel,
        fromLabel,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yCloseIconLabel,
        a11yTooltipIcon,
        plusMinusSymbol,
        badgesConfig: badges,
        tireSizeLabel: specificationsProps.tireSizeLabel,
        wheelOffsetLabel: specificationsProps.wheelOffsetLabel,
        wheelRimWidthLabel: specificationsProps.wheelRimWidthLabel,
        wheelRimDiameterLabel: specificationsProps.wheelRimDiameterLabel,
        checkAvailabilityLabel: availabilityProps.checkAvailabilityLabel,
        inStockAisleLabel: availabilityProps.inStockAisleLabel,
        inStockOnlineLabel: availabilityProps.inStockOnlineLabel,
        outOfStockLabel: availabilityProps.outOfStockLabel,
        isUrgentLowStockLabel: availabilityProps.isUrgentLowStockLabel,
        attributeFrontLabel,
        attributeRearLabel,
        checkOtherStoresLabel: availabilityProps.checkOtherStoresLabel,
        isUseLegacyLogicOfVehicleCheckAvailability: availabilityProps.isUseLegacyLogicOfVehicleCheckAvailability,
        inStorePurchaseLabel: availabilityProps.inStorePurchaseLabel,
    } as HallOfFameProductTileProps | AuthoriableProductProps

    return (
        <ol className={hallOFameProductGrid}>
            {isLoading && <HOFSkeleton />}
            {!isLoading ? (
                <>
                    {products.map((productItem, index) => {
                        return isPrerenderOrNot() ? (
                            <PrerenderHallOfFameProductTile
                                key={productItem.prodId}
                                language={language}
                                {...productItem}
                                {...authorableProps}
                            />
                        ) : (
                            <HallOfFameProductTile
                                key={`hall-of-fame-item-${index}`}
                                language={language}
                                {...productItem}
                                {...authorableProps}
                            />
                        )
                    })}
                </>
            ) : null}
        </ol>
    )
}

const HOFSkeleton = () => {
    const skeletonItems = new Array<number>(HALL_OF_FAME_PRODUCTS_AMOUNT).fill(HALL_OF_FAME_PRODUCTS_AMOUNT)
    return (
        <>
            {skeletonItems.map((value, index) => (
                <div key={value + index} className={clx(hallOFameProductTile, hallOFameSkeleton)} />
            ))}
        </>
    )
}

export default HallOfFameProductGrid
