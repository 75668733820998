import { createAction } from 'typesafe-actions'

import {
    FETCH_SPONSORED_ADS_FAILURE,
    FETCH_SPONSORED_ADS_REQUEST,
    FETCH_SPONSORED_ADS_SUCCESS,
    SET_SPONSORED_ADS_CAROUSEL_PRODUCTS,
    SET_SPONSORED_ADS_LOADING,
} from '../types/products/sponsoredAds.actionTypes.constant'
import { ModifiedCriteoResponse } from '../../services/criteoService/criteo.interface'
import { ProductDataObj } from '../../components/ProductGridView/ProductCard.types'

export const fetchSponsoredAdsRequestAction = createAction(FETCH_SPONSORED_ADS_REQUEST)()

export const fetchSponsoredAdsErrorAction = createAction(FETCH_SPONSORED_ADS_FAILURE)()

export const fetchSponsoredAdsSuccessAction = createAction(
    FETCH_SPONSORED_ADS_SUCCESS,
    (payload: ModifiedCriteoResponse) => payload,
)<ModifiedCriteoResponse>()

export const setSponsoredAdsLoading = createAction(SET_SPONSORED_ADS_LOADING, (payload: boolean) => payload)()

export const setSponsoredAdsCarouselProducts = createAction(
    SET_SPONSORED_ADS_CAROUSEL_PRODUCTS,
    (payload: ProductDataObj[]) => payload,
)<ProductDataObj[]>()
