import BaseService from '../base.service'
import { getHttpClient } from '../../httpClient'
import {
    CriteoParams,
    CriteoPlacement,
    CriteoProduct,
    CriteoResponse,
    ModifiedCriteoResponse,
    ParsedRenderingAttr,
} from './criteo.interface'
import { AxiosPromise } from 'axios'
import {
    IN_GRID_MAX_SPONSORED_ADS,
    ModifiedPlacementKey,
    OriginalPlacementKey,
    initialPlacementObj,
} from './criteoService.constants'
import { appendCustomCriteoParams } from './criteoService.helper'
import { ProductCardType } from '../../redux/models/recommendations.interface'
import { ProductDataObj } from '../../components/ProductGridView/ProductCard.types'

const httpClient = getHttpClient()

/**
 * Criteo Service
 */
class CriteoService extends BaseService {
    /**
     * Converts an array of Criteo products to an array of product data objects.
     * @param {CriteoProduct[]} criteoProducts - An array of products from Criteo.
     * @param {boolean} includeAll - Indicate whether to include all the data, by default limited to IN_GRID_MAX_SPONSORED_ADS.
     * @returns {ProductDataObj[]} An array of product data objects.
     */
    convertCriteoData = (criteoProducts: CriteoProduct[], includeAll?: boolean): ProductDataObj[] => {
        const products = includeAll ? criteoProducts : criteoProducts.slice(0, IN_GRID_MAX_SPONSORED_ADS)

        return products.map((criteoProduct: CriteoProduct) => {
            const { clearance } = JSON.parse(criteoProduct.RenderingAttributes) as ParsedRenderingAttr

            return {
                title: criteoProduct.ProductName,
                url: criteoProduct.ProductPage,
                code: criteoProduct.ParentSKU,
                images: [{ url: criteoProduct.Image }],
                badges: clearance === 'Y' ? ['CLEARANCE'] : [],
                isSponsored: true,
                skus: [],
            }
        })
    }

    /**
     * Attaches product details to a list of Criteo products.
     * @param {CriteoProduct[]} criteoProducts - An array of CriteoProduct objects.
     * @param {ProductCardType[]} productCards - An array of ProductCardType objects.
     * @returns {ProductDataObj[]} - An array of Product Cards with attached details.
     */
    attachProductDetails = (criteoProducts: CriteoProduct[], productCards: ProductCardType[]): ProductDataObj[] => {
        const products: ProductDataObj[] = criteoProducts.map(criteoData => {
            const { ProductName, ProductPage, ParentSKU, Image } = criteoData

            const details = productCards.find(item => item.code === criteoData.ParentSKU)

            return {
                title: ProductName,
                url: ProductPage,
                code: ParentSKU,
                images: [{ url: Image }],
                rating: Number(details?.rating),
                ratingsCount: Number(details?.ratingsCount) || 0,
                badges: details?.badges,
                currentPrice: details?.currentPrice,
                originalPrice: details?.originalPrice,
                priceMessage: details?.priceMessage,
                displayWasLabel: details?.displayWasLabel,
                isMultiSku: details?.isMultiSku,
                sellable: details?.sellable,
                orderable: details?.orderable,
                fulfillment: details?.fulfillment,
                skus: details?.skus,
                brand: details?.brand,
            }
        })

        return products
    }

    /**
     * Rename Criteo placement keys to just `inGrid` and `carousel`
     * @param {CriteoResponse} response The original response from Criteo API
     * @returns {ModifiedCriteoResponse} modified object
     */
    renamePlacementKeys = (response: CriteoResponse): ModifiedCriteoResponse => {
        // Initialize an empty placements object for the simplified response
        const simplifiedPlacements: {
            [x in ModifiedPlacementKey]: CriteoPlacement
        } = {
            inGrid: initialPlacementObj,
            carousel: initialPlacementObj,
        }

        response.placements.forEach(placement => {
            const placementKey = Object.keys(placement)[0] as OriginalPlacementKey

            // Convert the keys to lowercase
            const inGrid = ModifiedPlacementKey.IN_GRID.toLocaleLowerCase()
            const carousel = ModifiedPlacementKey.CAROUSEL.toLocaleLowerCase()
            const key = placementKey.toLocaleLowerCase()

            // Determine the simplified key based on the original placement key
            let simplifiedKey: ModifiedPlacementKey
            if (key.includes(inGrid)) {
                simplifiedKey = ModifiedPlacementKey.IN_GRID
            } else if (key.includes(carousel)) {
                simplifiedKey = ModifiedPlacementKey.CAROUSEL
            } else {
                return // Skip unknown placement keys
            }

            const placementValue = placement[placementKey][0]
            simplifiedPlacements[simplifiedKey] = placementValue
        })

        return {
            status: response.status,
            OnLoadBeacon: response.OnLoadBeacon,
            'page-uid': response['page-uid'],
            placements: simplifiedPlacements,
        }
    }

    /**
     * @param {CriteoParams} params - The criteo API parameters
     * @param {CriteoEventType} params.eventType - Indicates what type of request we want to use
     * @param {string} params.criteoBaseUrl - The base URL for the Criteo request.
     * @param {string} params.partnerId - The partner ID for Criteo.
     * @param {string} params.noLog - Flag to determine if logging should be disabled.
     * @param {string} params.retailerVisitorId - The retailer visitor ID.
     * @param {string} params.storeId - The store ID or region ID.
     * @param {boolean} params.isMobile - Indicates if the environment is mobile.
     * @param {number} params.pageNumber - The page number for pagination.
     * @param {string} params.keywords -  Keywords for the search query. Provide only if eventType = viewSearchResult
     * @param {string} params.categoryId - The current CategoryID. Provide only if eventType = viewCategory
     * @param {string} [params.customerId] - The optional customer ID.
     * @param {boolean} [params.isNullSearch] - Flag to indicate if a NullSearch should be triggered
     * @returns {AxiosPromise<CriteoResponse>} - A promise that resolves to the Criteo response
     */
    fetchCriteoData(params: CriteoParams): AxiosPromise<CriteoResponse> {
        const { criteoBaseUrl, partnerId, noLog, retailerVisitorId, storeId, isMobile, customerId } = params

        const baseUrl = this.getCriteoUrl(
            criteoBaseUrl,
            partnerId,
            noLog,
            retailerVisitorId,
            storeId,
            isMobile,
            customerId,
        )

        const customParams = appendCustomCriteoParams(params)

        const url: URL = new URL(baseUrl)
        url.search += (url.search ? '&' : '') + customParams

        return httpClient.apiGet(url.toString())
    }

    /**
     * Compose a Criteo URL based on the Universal parameters.
     * @param {string} criteoBaseUrl - The base URL for the Criteo request.
     * @param {string} partnerId - The partner ID for Criteo.
     * @param {string} noLog - Flag to determine if logging should be disabled.
     * @param {string} retailerVisitorId - The retailer visitor ID.
     * @param {string} storeId - The store ID or region ID.
     * @param {boolean} isMobile - Indicates if the environment is mobile.
     * @param {string} [customerId] - The optional customer ID.
     * @returns {string} The composed Criteo URL.
     */
    getCriteoUrl = (
        criteoBaseUrl: string,
        partnerId: string,
        noLog: string,
        retailerVisitorId: string,
        storeId: string,
        isMobile: boolean,
        customerId?: string,
    ): string => {
        const params = new URLSearchParams({
            'criteo-partner-id': partnerId,
            'retailer-visitor-id': retailerVisitorId,
            regionId: storeId,
            environment: isMobile ? 'm' : 'd',
        })

        if (noLog) {
            params.append('nolog', '1')
        }

        if (customerId) {
            params.append('customer-id', customerId)
        }

        return `${criteoBaseUrl}?${params.toString()}`
    }
}

const criteoService = new CriteoService()
export default criteoService
