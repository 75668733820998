import { AxiosPromise } from 'axios'
import { EnvironmentConfig, getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { cdsTokenResponse, UserProfileData } from '../../redux/models/user.profile.interface'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import { GigyaAccountResp, GigyaJWTResp, ResponseStatusType } from '../../utils/gigya.type'
import localStorageService from '../../utils/localStorageService'
import BaseService from '../base.service'
import { GigyaGetCookieResp } from '../../utils/gigya.type'
import { jwtPayloadData, status } from '../../components/GigyaScreen/gigya.constants'
import { LiteNotificationPreferenceBody } from '../../redux/models/user.preferences.interface'

const httpClient = getHttpClient()
const environment: EnvironmentConfig = getEnvironment()
/**
 * Sso login service
 * TODO: name need to be changed
 */
class GigyaService {
    /**
     * Gigya check if session is available once isGigyaReady is true
     * @return {Promise}
     */
    hasSession(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            // eslint-disable-next-line
            window.gigya.hasSession().then((sessionExist: boolean) => {
                resolve(sessionExist)
            })
        })
    }

    /**
     * Gigya check session call
     * @return {Promise}
     */
    checkCookie(): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = this.createCheckCookieUrl()
            httpClient
                .apiGet<GigyaGetCookieResp>(url, undefined, undefined, true)
                .then(resp => {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    resp?.data?.result ? resolve() : reject()
                })
                .catch(() => {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                })
        })
    }

    /**
     * set cookie after successful login
     * @param {string} ssoToken
     * @param {boolean} isSSOEnabled
     * @return {Promise}
     */
    setCookie(ssoToken: string, isSSOEnabled: boolean): Promise<unknown> {
        const url = this.createSetCookieUrl(isSSOEnabled)
        const headers: HttpReqHeaders = {
            'X-Auth': `Bearer ${ssoToken}`,
        }
        return httpClient.apiGet(url, {}, headers)
    }

    /**
     * Create check cookie URL
     * @return {string}
     */
    createCheckCookieUrl(): string {
        // TODO: mock  url checking is pending
        return `${environment.API_BASE_URL}${environment.API_ENDPOINTS.checkCookie}`
    }

    /**
     * Create set cookie URL
     * @param {boolean} isSSOEnabled
     * @return {string}
     */
    createSetCookieUrl(isSSOEnabled: boolean): string {
        return `${environment.API_BASE_URL}${
            environment.API_ENDPOINTS.setCookie +
            `?refClient=odp/ctrweb&serviceClient=odp/ctrweb&refPath=%2F&cookiesEnabled=${isSSOEnabled.toString()}`
        }`
    }

    /**
     * Get JWT token with Gigya web sdk
     * @returns {Promise} Encoded JWT token
     */
    jwtToken(): Promise<GigyaJWTResp> {
        return new Promise((resolve, reject) => {
            window?.gigya?.accounts?.getJWT?.({
                callback: (response: GigyaJWTResp) => {
                    response.id_token ? resolve(response) : reject(response)
                },
                apiKey: window.gigya.apiKey,
                fields: [jwtPayloadData.loyaltyId, jwtPayloadData.epclId].join(','),
            })
        })
    }

    /**
     * Get Account/user profile Info from Gigya
     * @param {Record<string, string>} reqParam
     * @return {Promise}
     */
    getAccountInfo(reqParam: Record<string, string>): Promise<GigyaAccountResp> {
        return new Promise(resolve => {
            window?.gigya?.accounts?.getAccountInfo?.({
                ...reqParam,
                callback: (response: GigyaAccountResp) => {
                    resolve(response)
                },
            })
        })
    }

    /**
     * Verify session info from Gigya if user is authenticated or not
     * resolve status of gigya is ready
     * See {@link https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/c4b6178f43934cd88d66cff9a38d38ca.html?q=fullEventName | SAP verify session }
     * @return {Promise}
     */
    verifySession(): Promise<boolean> {
        return new Promise(resolve => {
            window?.gigya?.accounts?.session?.verify({
                callback: (response: ResponseStatusType) => {
                    const { errorCode } = response
                    const isReady = window.gigya.isReady
                    if (errorCode === status.authorizedUser) {
                        // inherit function from gigya
                        window.userGigyaData = response
                    }
                    resolve(isReady)
                },
            })
        })
    }

    /**
     *
     * Get cds access token
     * @param  {string} gigyaJwt
     * @return {AxiosPromise}
     */
    cdsAccessToken(gigyaJwt: string): AxiosPromise<cdsTokenResponse> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsAccessToken}`
        const payload = !!localStorageService.getItem('remember')
            ? { rememberMe: localStorageService.getItem('remember') === 'true' }
            : {}

        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJwt}`,
        }
        return httpClient.apiPost<cdsTokenResponse>(url, payload, headers, true)
    }
    /**
     * Load user profile from CDS
     * @param  {string|null} gigyaJwt token
     * @param  {boolean} isLiteProfile boolean value
     * @returns {UserProfileData} User Profile Data
     */
    userProfile(gigyaJwt: string | null, isLiteProfile?: boolean): Promise<{ data: UserProfileData }> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsUserProfile}`
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${String(gigyaJwt)}`,
        }

        return httpClient.apiGet(url, { lang: BaseService.language, checkStatus: !isLiteProfile }, headers, true)
    }

    /**
     *
     * Call CDS resend verification API
     * @param {string} UID
     * @return {AxiosPromise}
     */
    resendVerification(UID: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.resendVerification}/${UID}`
        return httpClient.apiPost(url, undefined, undefined, true)
    }

    /**
     * Function to verify OTP Security Code
     * @param {string} otpSecurityCode - OTP Security Code from cds
     * @param {string} vToken - vToken from cds
     * @returns {Promise}
     */
    verifyOtpCode(otpSecurityCode: string, vToken: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.validateOtpCode}`
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        return httpClient.apiPost(url, { vToken, code: otpSecurityCode }, headers, true)
    }

    updatePassword(password: string, newPassword: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.updatePassword}`
        const body = { password, newPassword }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    /**
     * Call CDS reset password API
     * @param {string} passwordResetToken
     * @param {string} newPassword
     * @returns {AxiosPromise}
     */
    resetPassword(passwordResetToken: string, newPassword: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.resetPassword}`
        const body = { passwordResetToken, newPassword }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    /**
     * Call CDS reset password API to send email with link to the reset password form
     * @param {string} loginID
     * @returns {AxiosPromise}
     */
    sendEmilToResetPassword(loginID: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.resetPassword}`
        const body = { loginID }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    /**
     * Call CDS notificationPreferenceLite API to update account information
     * @param {LiteNotificationPreferenceBody} accountInfo
     * @returns {AxiosPromise}
     */
    updateAccountInformation(accountInfo: LiteNotificationPreferenceBody): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.notificationPreferenceLite}`
        return httpClient.apiPost(url, JSON.stringify(accountInfo), undefined, false)
    }
}

export { GigyaService }
export default GigyaService
