import { PREFIX } from '../../config'

export const shopTheLook = 'shop the look'

export const constantValues = {
    twoProductsClassName: 'two-products',
    fourProductsClassName: 'four-products',
    maximumProductQuantity: 4,
    minimumProductQuantity: 2,
    acceptedBadges: ['PROMO', 'CLEARANCE', 'SALE'] as ('PROMO' | 'CLEARANCE' | 'SALE')[],
    analyticsLocValueForShopTheLookComponent: 'sc',
} as const

export const options: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
}

export const componentClassName = `${PREFIX}-fixed-height-banner`
