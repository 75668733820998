import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MiniBuyBox } from './components/'
import { miniPDPFlyoutDetailsSelector } from '../../redux/selectors/miniPDPFlyoutData.selectors'

import { MiniPDPProps } from './MiniPDP.type'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { setMiniPDPAEMProps } from '../../redux/actionCreators'

/**
 *
 * @param props
 */
export const MiniPDP: React.FC<MiniPDPProps> = props => {
    const { showComponent } = props
    const dispatch = useDispatch()
    const { isFlyoutVisible } = useSelector(miniPDPFlyoutDetailsSelector)
    const commonContentAvailable = useSelector(commonContentAvailableSelector)

    useEffect(() => {
        if (commonContentAvailable.featureFlag) {
            dispatch(setMiniPDPAEMProps({ ...props, ...commonContentAvailable.featureFlag }))
        }
    }, [dispatch, props, commonContentAvailable])

    if (!isFlyoutVisible || !showComponent) {
        return null
    }

    return <MiniBuyBox {...props} />
}
