import { libUtils, updateUrlHistory, checkDataLength } from '@nl/lib'
import { IGlobalProps } from '../redux/models/commonContent.interface'
import sessionStorageService from '../utils/sessionStorageService'
import { gigyaParams } from '../components/GigyaScreen/gigya.constants'
import { GlobalPropsHelper } from '../analytics/helpers/globalProps'
import localStorageService from '../utils/localStorageService'
import { globalPartialAuthCode } from '../globalConstants/cdsErrorCodes'
import store from '../store'
import { Status } from '../globalConstants'
import { commonContentMessages } from '../redux/models/commonContent.interface'
import { getCommonContent, clpStatus } from './getContent.helper'
import { SingleRegistration } from './ciam.type'

const language = libUtils.getLanguage()

export const fromCLP = () => Boolean(window?.gigya?.getUrlParam('actions'))

/**
 * Determines SSSO condition based on values from enableSSSO
 * @returns {boolean}
 */
export const enableSSSO = (): boolean => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            console.log(error)
        }
    }

    return hasContent?.featureFlag?.enableSSSO as boolean
}

/**
 * Determines SSO condition based on values from enableCLP in global props and clp parameter in page url
 * @returns {boolean}
 */
export const enableSingleSignOn = (): boolean => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            console.log(error)
        }
    }

    return hasContent?.featureFlag?.enableCLP as boolean
}

/**
 * Determines SSO condition based on values from enableCRP and enableLoyaltyFlowCRP in global props
 * @returns {boolean}
 */
export const enableSingleRegistration = (): SingleRegistration => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            console.log(error)
        }
    }

    return {
        enableCRP: hasContent?.featureFlag?.enableCRP,
        enableLoyaltyFlowCRP: hasContent?.featureFlag?.enableLoyaltyFlowCRP,
    } as SingleRegistration
}

/**
 * Async determines SSO condition based on values from enableCLP in global props and clp parameter in page url
 * @returns {Promise}
 */
export const enableSingleSignOnAsync = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const { commonContentAvailable: contentAvailable, commonContentLoadStatus: loadStatus } = getCommonContent()
        if (checkDataLength(contentAvailable) || loadStatus !== '') {
            const result = clpStatus(contentAvailable, loadStatus)
            result === Status.error ? reject(new Error(commonContentMessages.LoadFailed)) : resolve(result)
        } else {
            const unsubscribe = store.subscribe(() => {
                const { commonContentAvailable, commonContentLoadStatus } = getCommonContent()
                if (checkDataLength(commonContentAvailable) || commonContentLoadStatus !== '') {
                    unsubscribe()
                    const result = clpStatus(contentAvailable, loadStatus)
                    result === Status.error ? reject(new Error(commonContentMessages.LoadFailed)) : resolve(result)
                }
            })
        }
    })
}

/**
 * Calls gigya login method and passes context info from originating page to CLP (Central Login Page)
 * @param {string} redirectURL originating page from which sign-in was initiated
 * @param {ContextType} additionalContext carried to the CLP and can be used to customize user experience
 * @returns {void}
 */
export const ssoLoginHandler = (redirectURL: string, additionalContext?: Record<string, unknown>): void => {
    const globalProps = new GlobalPropsHelper()
    const configs = globalProps.init()

    return window.gigya.sso.login({
        authFlow: 'redirect',
        useChildContext: true,
        redirectURL,
        context: {
            refClient: configs['serviceClient'],
            lang: language,
            clp: 'true',
            sourceURL: redirectURL,
            ...additionalContext,
        },
    })
}

// Function to check for "gig_" query parameters
export const checkForGigQueryParams = (removeParam = false): void => {
    if (removeParam && !window.gigya.getUrlParam(gigyaParams.sequence)) {
        updateUrlHistory(undefined, true)
    }
}

/**
 * Function to return profile name if remember is true in localstorage
 * @returns {Record<string, unknown>}
 */
export const profileName = (): Record<string, unknown> => {
    return localStorageService.getItem('remember') === 'true'
        ? { status: globalPartialAuthCode, firstName: localStorageService.getItem('firstName') }
        : {}
}

/**
 * Passes loginContext to ssoLoginHandler function which calls gigya's login method with provided context
 * @param {string} pageUrl
 * @returns {void}
 */
export const ssoHandler = (pageUrl: string): void => {
    const url = new URL(pageUrl)
    const redirectUrl = url.origin + url.pathname
    return ssoLoginHandler(redirectUrl)
}

/**
 * Passes registrationContext to ssoLoginHandler function which calls gigya's registration method with provided context
 * @param {string} pageUrl
 * @returns {void}
 */
export const ssoRegistrationHandler = (pageUrl: string): void => {
    const { enableLoyaltyFlowCRP } = enableSingleRegistration()
    const additionalContext = {
        flow: 'registration',
    }

    if (!enableLoyaltyFlowCRP) {
        additionalContext.loyalty = 'none'
    }

    return ssoLoginHandler(pageUrl, additionalContext)
}

/**
 * Passes specific pathname and context to ssoLoginHandler function which calls gigya's login method with provided context
 * @param pathName - specific pathname to update redirect url
 * @returns {void}
 */
export const ssoHandlerWithPathName = (pathName: string, additionalContext?: Record<string, unknown>): void => {
    const url = new URL(window.location.href)
    const redirectUrl = url.origin + pathName
    return ssoLoginHandler(redirectUrl, additionalContext)
}
