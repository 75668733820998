import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/reducers'
import { Price } from '../redux/models/cart.interface'
import { isArrayNotEmpty, SaleMessagesRule } from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import { SaleCut } from '../components/BuyBox/BuyBox.type'
import { CUT, LESS, PERCENT } from '../globalConstants/global.constant'

/**
 * Hook to calculate save message
 * @param {SaleCut} saleCut
 * @param {SaleMessagesRule[]} saleMessagesRules
 * @param {Price} originalPrice
 * @param {Price} currentPrice
 * @return {string}
 */

export const useGenerateSaveMessage = (saleCut?: SaleCut, originalPrice?: Price, currentPrice?: Price): string => {
    const { saleMessagesRules } = useSelector((state: RootState) => state.commonContent?.commonContentAvailable)

    const [saveMessage, setSaveMessage] = useState('')

    const showSaveMessage = isArrayNotEmpty(saleMessagesRules) && !!saleCut?.value

    useEffect(() => {
        if (showSaveMessage) {
            setSaveMessage(generateSaveMessage(saleCut, saleMessagesRules, originalPrice, currentPrice))
        } else {
            setSaveMessage('')
        }
    }, [showSaveMessage, originalPrice, saleCut, saleMessagesRules, currentPrice])

    return saveMessage
}

const generateSaveMessage = (
    saleCut?: SaleCut,
    saleMessagesRules?: SaleMessagesRule[],
    originalPrice?: Price,
    currentPrice?: Price,
): string => {
    const roundedPercentage = Math.round(Number(saleCut?.percentage))
    const saleCutValue = Number(saleCut?.value)
    const roundedSaleCutValue = saleCut?.value?.toFixed(MagicNumber.TWO) ?? ''
    const messageReplacer = (message: string) =>
        message?.replace(PERCENT, `${roundedPercentage}`)?.replace(CUT, `${roundedSaleCutValue}`)

    const saleMessagesRule = saleMessagesRules?.find(rule => {
        const percentage = Number(saleCut?.percentage?.toFixed(MagicNumber.TWO))
        return (
            percentage <= rule.maxPercent &&
            percentage >= rule.minPercent &&
            (rule.absoluteCutType === LESS
                ? rule.absoluteValueThreshold > saleCutValue
                : rule.absoluteValueThreshold < saleCutValue)
        )
    }) as SaleMessagesRule

    return !!originalPrice?.minPrice || !!currentPrice?.minPrice
        ? messageReplacer(saleMessagesRule?.upToSaveMessage)
        : messageReplacer(saleMessagesRule?.saveMessage)
}
