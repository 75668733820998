export enum SearchRequestHeaderParams {
    X_RINGFENCE = 'x-ringfence',
    X_BR_UID = 'X-BR-UID',
    X_BR_REF_URL = 'X-BR-REF-URL',
    X_BR_REF = 'X-BR-REF',
    LANG = 'lang',
    COUNT = 'count',
    HIDE_FACETS = 'hideFacets',
    WIDGET_ID = 'widgetId',
    WIDGET_TYPE = 'widgetType',
    EXPERIENCE = 'experience',
    CATEGORY_LEVEL = 'categoryLevel',
    CATEGORY_CODE = 'categoryCode',
    Q = 'q',
    BRAND = 'brand',
    PAGE_TYPE = 'pageType',
    LIGHT = 'light',
    SPONSORED_PRODUCTS = 'sponsoredProducts',
    DEVICE_TYPE = 'deviceType',
}
