import { createAction } from 'typesafe-actions'
import { CartResponseErrorData } from '../models/cart.interface'
import {
    CheckoutContactInfo,
    CheckoutPickupInfo,
    CheckoutShippingInfo,
    CheckoutPaymentInfo,
    DrawerLevelStatus,
    CheckoutLoyaltyInfo,
    CheckoutAppointmentInfo,
} from '../models/checkout.interface'

import {
    SET_CHECKOUT_CONTACT,
    SET_CHECKOUT_PICKUP,
    SET_CHECKOUT_SHIPPING,
    SET_CHECKOUT_APPOINTMENT,
    SET_CHECKOUT_PAYMENT,
    SAVED_PAYMENT_TO_CART,
    SET_PAYMENTFAILURE_ERROR,
    SET_CHECKOUT_CONTACT_STATUS,
    RESET_CHECKOUT_VALIDATIONS,
    SET_CHECKOUT_SHIPPING_STATUS,
    SET_PAYMENT_SUCCESS,
    SET_PROFILE_BILLING_SUCCESS,
    SET_IS_SAVE_BILLING_ADDRESS,
    SET_CHECKOUT_LOYALTY_CARD,
    RESET_PROFILE_BILLING_STATUS,
} from '../types/checkout/checkout.actionTypes.constant'

export const setCheckoutContact = createAction(
    SET_CHECKOUT_CONTACT,
    (payload: CheckoutContactInfo) => payload,
)<CheckoutContactInfo>()
export const setCheckoutPickup = createAction(
    SET_CHECKOUT_PICKUP,
    (payload: CheckoutPickupInfo) => payload,
)<CheckoutPickupInfo>()
export const setCheckoutShipping = createAction(
    SET_CHECKOUT_SHIPPING,
    (payload: CheckoutShippingInfo) => payload,
)<CheckoutShippingInfo>()
export const setCheckoutAppointment = createAction(
    SET_CHECKOUT_APPOINTMENT,
    (payload: CheckoutAppointmentInfo) => payload,
)<CheckoutAppointmentInfo>()
export const setCheckoutPayment = createAction(
    SET_CHECKOUT_PAYMENT,
    (payload: CheckoutPaymentInfo) => payload,
)<CheckoutPaymentInfo>()
export const savedPaymentToCart = createAction(SAVED_PAYMENT_TO_CART)()
export const setPaymentFailureError = createAction(SET_PAYMENTFAILURE_ERROR, (payload: string) => payload)<string>()
export const setCheckoutContactUpdateStatus = createAction(
    SET_CHECKOUT_CONTACT_STATUS,
    (payload: CartResponseErrorData) => payload,
)<CartResponseErrorData>()

export const resetCheckoutValidationsAction = createAction(RESET_CHECKOUT_VALIDATIONS)()
export const setCheckoutShippingStatus = createAction(
    SET_CHECKOUT_SHIPPING_STATUS,
    (payload: DrawerLevelStatus) => payload,
)<DrawerLevelStatus>()
export const setPaymentSuccess = createAction(SET_PAYMENT_SUCCESS, (payload: boolean) => payload)<boolean>()
export const setCheckoutLoyaltyInfo = createAction(
    SET_CHECKOUT_LOYALTY_CARD,
    (payload: CheckoutLoyaltyInfo) => payload,
)<CheckoutLoyaltyInfo>()
export const setProfileBillingSuccess = createAction(
    SET_PROFILE_BILLING_SUCCESS,
    (payload: boolean) => payload,
)<boolean>()
export const resetProfileBillingStatus = createAction(RESET_PROFILE_BILLING_STATUS)()
export const setIsSaveBillingAddress = createAction(
    SET_IS_SAVE_BILLING_ADDRESS,
    (payload: boolean) => payload,
)<boolean>()
