import { createSwiper } from '../../utils/createSwiper'
import { PREFIX } from '../../config'
import { SwiperOptions, SwiperMethods, SliderDataProps } from './HeadbandBannerComponent.type'

/**
 * HeadbandBannerComponent - Initializes a swiper component based on the provided element and parameters.
 */
const HeadbandBannerComponent = (): void => {
    const element = document.querySelector('.nl-headband-banner')
    const numberOfSlides = document.querySelectorAll(`.${PREFIX}-headband-banner__slide-content`).length

    // Safely parse the JSON data and type it as SliderDataProps | undefined
    const props = (element as HTMLElement)?.dataset.props
    const sliderDataProps: SliderDataProps | undefined = props ? (JSON.parse(props) as SliderDataProps) : undefined

    const swiperOptions: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        autoplay: {
            enabled: Boolean(numberOfSlides > 1), // Autoplay enabled/disabled
            delay: sliderDataProps ? sliderDataProps.autoplaySpeed * 1000 : 0, // Autoplay delay in seconds
            disableOnInteraction: false, // Autoplay continues even when user interacts with slides
        },
        navigation: {
            nextEl: '.nl-headband-banner--nextArrow',
            prevEl: '.nl-headband-banner--prevArrow',
        },
    }

    const swiper: SwiperMethods = createSwiper('.nl-headband-banner-swiper-container', swiperOptions)

    const pauseButton = document.querySelector('.nl-headband-banner--pause')
    const playButton = document.querySelector('.nl-headband-banner--play')
    const nextArrow = document.querySelector(`.${PREFIX}-headband-banner--nextArrow`)
    const prevArrow = document.querySelector(`.${PREFIX}-headband-banner--prevArrow`)

    /**
     * Displays the play/pause and chevron icons if there is more than one slide.
     */
    const displayIcons = () => {
        if (numberOfSlides > 1) {
            ;(nextArrow as HTMLElement).style.display = 'block'
            ;(prevArrow as HTMLElement).style.display = 'block'
            if (sliderDataProps && sliderDataProps.isAutoplay) {
                swiper.autoplay.start()
                if (pauseButton) {
                    ;(pauseButton as HTMLElement).style.display = 'block'
                }
                if (playButton) {
                    ;(playButton as HTMLElement).style.display = 'none'
                }
            } else {
                swiper.autoplay.stop()
                if (playButton) {
                    ;(playButton as HTMLElement).style.display = 'block'
                }
                if (pauseButton) {
                    ;(pauseButton as HTMLElement).style.display = 'none'
                }
            }
        }
    }

    /**
     * Toggles the autoplay feature of the swiper instance.
     * If autoplay is running, it will be stopped.
     * If autoplay is stopped, it will be started.
     */
    const toggleAutoplay = () => {
        if (swiper.autoplay.running) {
            swiper.autoplay.stop() // Pause autoplay if running
            ;(playButton as HTMLElement).style.display = 'block'
            ;(pauseButton as HTMLElement).style.display = 'none'
        } else {
            swiper.autoplay.start() // Start autoplay if paused
            ;(pauseButton as HTMLElement).style.display = 'block'
            ;(playButton as HTMLElement).style.display = 'none'
        }
    }

    displayIcons()
    ;(pauseButton as HTMLElement).addEventListener('click', toggleAutoplay)
    ;(playButton as HTMLElement).addEventListener('click', toggleAutoplay)
}

HeadbandBannerComponent.displayName = 'HeadbandBannerComponent'

export { HeadbandBannerComponent }
