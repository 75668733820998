import { createAction } from 'typesafe-actions'

import {
    AOA_SAVE_DROP_OFF_OPTION_INFO,
    AOA_SAVE_CONTACT_INFO,
    AOA_SAVE_DATE_INFO,
    AOA_SAVE_TIME_INFO,
    AOA_SAVE_BOOKING_RESPONSE,
    AOA_SAVE_LOCATION_INFO,
    AOA_CLEAR_BOOKING_DATA,
    AOA_SAVE_SERVICE_RESPONSE,
    AOA_CURRENT_BOOKING_FORM_STEP,
    AOA_UPDATE_AUTOMOTIVE_SERVICES,
    AOA_SAVE_PREVIOUSLY_SELECTED_SERVICES,
    AOA_SAVE_OPENED_STORE_LOCATOR_MODAL,
    AOA_CLEAR_DATE_TIME_DATA,
    AOA_CLEAR_SERVICES_DATA,
    AOA_SAVE_CONTACT_INFO_EDIT_MODE,
    AOA_CLEAR_PREVIOUSLY_SELECTED_SERVICES,
    AOA_SAVE_ETIRE_ORDER_INFO,
    SET_SHOW_SKELETON,
    AOA_SAVE_HAS_STARTED_AOA_FLOW,
    SET_AOA_ERROR,
    SET_AOA_IS_LOADING,
    AOA_SAVE_ADDITIONAL_COMMENTS,
    AOA_RESET_DATE_TIME_INFO,
    AOA_SAVE_VEHICLE_ORDER_INFO,
} from '../types/aoaBookingForm/aoaBookingForm.actionTypes.constant'
import {
    AOAContactInfoState,
    AOABookingResponseState,
    AOALocationInfoState,
    AOAServiceResponseState,
    AOAEtireInfoState,
    AOAErrorState,
    AOAEtireOrderVehicleInfo,
} from '../models/aoaBookingForm.interface'
import { AOASelectedService } from '../../components/AOABookingForm/AOAServiceView/AOAServiceView.type'

// Action creator for saving Contact Info
export const saveAOAContactInfoAction = createAction(
    AOA_SAVE_CONTACT_INFO,
    (payload: Partial<AOAContactInfoState>): Partial<AOAContactInfoState> => payload,
)<AOAContactInfoState>()

// Date 'n' Time Step action creators
export const saveAOADateInfoAction = createAction(
    AOA_SAVE_DATE_INFO,
    (payload?: string): string | undefined => payload,
)<string>()
export const saveAOATimeInfoAction = createAction(
    AOA_SAVE_TIME_INFO,
    (payload?: string): string | undefined => payload,
)<string>()
export const saveAOADropOffOptionInfoAction = createAction(
    AOA_SAVE_DROP_OFF_OPTION_INFO,
    (payload?: string): string | undefined => payload,
)<string>()

// Action creator for Saving Booking Response
export const saveAOABookingResponseAction = createAction(
    AOA_SAVE_BOOKING_RESPONSE,
    (payload: AOABookingResponseState): AOABookingResponseState => payload,
)<AOABookingResponseState>()

// Action creator for saving Location info
export const saveAOALocationInfoAction = createAction(
    AOA_SAVE_LOCATION_INFO,
    (payload: AOALocationInfoState): AOALocationInfoState => payload,
)<AOALocationInfoState>()

// Action creator for clearing AOABookingFormData from redux store
export const clearAOABookingData = createAction(AOA_CLEAR_BOOKING_DATA)()
// Action creator for clearing DateTime step data from redux store
export const clearAOADateTimeData = createAction(AOA_CLEAR_DATE_TIME_DATA)()
// Action creator for clearing Service step data from redux store
export const clearAOAServicesData = createAction(AOA_CLEAR_SERVICES_DATA)()

// Action creator for saving automotive services response
export const saveAutomotiveServiceResponseAction = createAction(
    AOA_SAVE_SERVICE_RESPONSE,
    (payload: AOAServiceResponseState): AOAServiceResponseState => payload,
)<AOAServiceResponseState>()

// Action creator for adding and removing selected automotive services
export const updateAutomotiveServicesAction = createAction(
    AOA_UPDATE_AUTOMOTIVE_SERVICES,
    (payload: Array<AOASelectedService>): Array<AOASelectedService> => payload,
)<Array<AOASelectedService>>()

// Action creator to save previously selected services
export const savePreviouslySelectedServicesAction = createAction(
    AOA_SAVE_PREVIOUSLY_SELECTED_SERVICES,
    (payload: Array<AOASelectedService>): Array<AOASelectedService> => payload,
)<Array<AOASelectedService>>()

// Action creator to clear previously selected services
export const clearPreviouslySelectedServicesAction = createAction(AOA_CLEAR_PREVIOUSLY_SELECTED_SERVICES)()

// Action creator for saving current Booking form step index
export const saveCurrentBookingFormStep = createAction(
    AOA_CURRENT_BOOKING_FORM_STEP,
    (payload: number): number => payload,
)<number>()

// Action creator for saving Store Locator Modal open state
export const saveOpenedStoreLocatorModal = createAction(AOA_SAVE_OPENED_STORE_LOCATOR_MODAL)<boolean>()

// Action creator for saving Contact Info edit mode state
export const saveContactInfoEditMode = createAction(AOA_SAVE_CONTACT_INFO_EDIT_MODE)<boolean>()

// Action creator for saving AOA Flow has started state
export const saveHasStartedAOAFlow = createAction(AOA_SAVE_HAS_STARTED_AOA_FLOW)<boolean>()

// Action creator for saving eTire order details
export const saveAOAeTireOrderInfo = createAction(
    AOA_SAVE_ETIRE_ORDER_INFO,
    (payload: AOAEtireInfoState): AOAEtireInfoState => payload,
)<AOAEtireInfoState>()

export const saveVehicleOrderInfo = createAction(
    AOA_SAVE_VEHICLE_ORDER_INFO,
    (payload: AOAEtireOrderVehicleInfo): AOAEtireOrderVehicleInfo => payload,
)<AOAEtireOrderVehicleInfo>()

// Action creator for hide and display the Skeleton component
export const setShowSkeleton = createAction(SET_SHOW_SKELETON)<boolean>()

export const setAOAError = createAction(SET_AOA_ERROR)<AOAErrorState | null>()

export const setAOAIsLoading = createAction(SET_AOA_IS_LOADING)<boolean>()

export const saveAdditionalComments = createAction(
    AOA_SAVE_ADDITIONAL_COMMENTS,
    (payload: string): string => payload,
)<string>()

export const resetAOADateTimeInfoAction = createAction(AOA_RESET_DATE_TIME_INFO)()
