import React, { useEffect, useRef, useState } from 'react'
import { string, bool } from 'prop-types'
import {
    Button,
    FlyoutModalComponent,
    Icon,
    SanitizeStringContentWrapper,
    StoreTooltip,
    Tag,
    getPageType,
    replaceMultipleStrWithDynamicVal,
    replaceStrWithDynamicVal,
} from '@nl/lib'
import { PREFIX, pageTypes, previousElementName } from '../../../config'
import { FindInStoreType } from './BuyOnline.type'
import { checkNearbyStoresButtonDataTestId } from './FulfillmentOptions.constant'
import { useDispatch, useSelector } from 'react-redux'
import { analyticsInteraction } from '../../../redux/actions'
import { analyticsAttributes } from '../../../globalConstants'
import { IStoreLocator } from '../../../redux/models/commonContent.interface'
import { commonContentAvailableSelector, commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { rendersStoreSelectorFlyoutComp, rendersTooltipComp } from '../FulfillmentOptions/BuyOnline.helper'

const FindInStore: React.FC<FindInStoreType> = ({ ...props }): JSX.Element => {
    const {
        alsoFindItInStoreHeader,
        checkOtherStoresLabel,
        isProductLevel,
        sendRequestByClickNearByStores,
        isShowNotInStock,
        isNearbyStoreListApiDone,
        selectedStoreQty,
        inStockAtStore,
        outOfStockAtStoreff,
        a11yStoreInfoTooltip,
        selectedStoreShelfLocation,
        isAutomotive,
        isAutoPartsPdpPage,
        isSKUPresentInUrl,
        isOutOfStockInAllStore,
        inStockInSelectedStore,
        isProductUrgentLowStock,
        ltdStockUrgencyMsg,
        isShippingPostalCodeHide,
        setIsNearByStoresAvailable,
        isNearbyStoresListEmpty,
        isModalPresent,
        isInMiniPDPFlyout,
    } = props

    const componentClassName = `${PREFIX}-buy-box__find-in-store`
    const aisleInfoClassName = !isProductUrgentLowStock ? `${componentClassName}__aisle-info` : ''
    const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>
    const dispatch = useDispatch()

    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { nearByStoreListForSelectedSku, preferredStoreDetails } = useSelector(storeDetailsDataSelector)
    const { storeLocator: commonContentStoreLocator = {} as IStoreLocator } =
        useSelector(commonContentAvailableSelector)
    const { pdpOnlyTitle, openingAtLabel, closeLabel, closesAtLabel, openLabel, storeDetailsCTALabel } =
        commonContentStoreLocator
    const closeIconLabel = commonContentAvailable.accessibility?.a11yCloseIconLabel

    const [shouldOpen, setShouldOpen] = useState(false)
    const [tooltipvisibility, setTooltipVisibility] = useState(false)
    const {
        eventParameters: {
            location: { buyBoxLocation },
            action: { storeLocator },
        },
        event: { interaction },
    } = analyticsAttributes

    // useEffect to setIsNearByStoresAvailable variable to true when oos at selected store
    useEffect(() => {
        if (selectedStoreQty === 0) {
            setIsNearByStoresAvailable(true)
        }
    }, [selectedStoreQty])

    /**
     * Function to open store selector modal and send analytics.
     * @param {MouseEvent} event - click event
     * @returns {void} - returns nothing
     */
    const checkNearbyStoreCTAHandler = (event: React.MouseEvent): void => {
        sendRequestByClickNearByStores()
        const button = event.currentTarget as HTMLElement
        button.setAttribute(previousElementName, 'true')
        setShouldOpen(true)
        dispatch(analyticsInteraction(checkOtherStoresLabel as string, buyBoxLocation, interaction, storeLocator))
        selectedStoreQty > 0 && isNearbyStoresListEmpty && setIsNearByStoresAvailable(false)
    }

    /**
     * Function used to set state variable to open the flyout modal
     * @returns {void} - returns nothing
     */
    const closeFlyout = (): void => {
        setShouldOpen(false)
    }

    // Props required for flyout modal
    const FlyOutModalComponentProps = {
        isOpen: shouldOpen,
        closeFlyout,
        title: pdpOnlyTitle,
        isStoreSelectorModalUsed: true,
    }

    /**
     * Renders store flyout component text
     * @returns {JSX.Element | null} returns STH component text
     */
    const rendersStoreFlyoutComp = (): JSX.Element | null => {
        return shouldOpen ? (
            <FlyoutModalComponent {...FlyOutModalComponentProps}>
                {rendersStoreSelectorFlyoutComp(
                    shouldOpen,
                    closeFlyout,
                    nearByStoreListForSelectedSku,
                    isProductLevel,
                    isShowNotInStock,
                    isNearbyStoreListApiDone,
                    isInMiniPDPFlyout,
                )}
            </FlyoutModalComponent>
        ) : null
    }
    const storeName = preferredStoreDetails.displayName
    const stockInStoreLabel = replaceMultipleStrWithDynamicVal(inStockAtStore, [selectedStoreQty, storeName])
    const limitedStockInStoreLabel = replaceMultipleStrWithDynamicVal(ltdStockUrgencyMsg, [selectedStoreQty, storeName])
    const outOfStockLabel = replaceStrWithDynamicVal(outOfStockAtStoreff, storeName)
    const isPDPPage = getPageType() === pageTypes.pdpPage

    /**
     * Renders a button with an icon to display store detail tooltip.
     * @returns {JSX.Element} The JSX element with the store detail tooltip icon button.
     */
    const renderStoreDetailTooltipIcon = (): JSX.Element => {
        return (
            <Button
                modifierClass={`${componentClassName}__stock-info-detail`}
                ref={buttonRef}
                id={'store-info'}
                onClick={() => {
                    setTooltipVisibility(!tooltipvisibility)
                }}
                quantumMetricAttribute={{ type: 'allow', value: 'true' }}
                ariaLabel={a11yStoreInfoTooltip}>
                <Icon size="md" type="ct-information-details"></Icon>
            </Button>
        )
    }

    /**
     * Renders the store shelf location info
     * @returns {JSX.Element} A JSX element showing the store shelf location.
     */
    const renderStoreShelfLocation = (): JSX.Element => {
        return (
            <span className={`${componentClassName}__aisle-label ${aisleInfoClassName}`}>
                {selectedStoreShelfLocation}
            </span>
        )
    }

    /**
     * Renders Aisle Lable
     * @returns {JSX.Element | null} returns aisle label
     */
    const renderAisleLabel = (): JSX.Element | null => {
        if (isAutomotive) {
            if (isAutoPartsPdpPage && isSKUPresentInUrl && !isOutOfStockInAllStore && selectedStoreShelfLocation) {
                return renderStoreShelfLocation()
            } else {
                return null
            }
        } else if (inStockInSelectedStore() && selectedStoreShelfLocation) {
            return renderStoreShelfLocation()
        } else {
            return null
        }
    }

    /**
     * This function to render find in store for new box fulfillment
     * @returns {JSX.Element} - returns JSX element
     */
    const renderFindInStore = (): JSX.Element => {
        return (
            <div className={`${componentClassName}`}>
                <span className={`${componentClassName}__store-label`}>{alsoFindItInStoreHeader}</span>

                <div className={`${componentClassName}__stock-info`}>
                    {selectedStoreQty > 0 ? (
                        <>
                            <div className={`${componentClassName}__stock-and-store-info`}>
                                {isProductUrgentLowStock && !isShippingPostalCodeHide ? (
                                    <SanitizeStringContentWrapper stringContent={limitedStockInStoreLabel}>
                                        {memoizedStringContent => (
                                            <span
                                                className={`${componentClassName}__limited-Stock-At-Store`}
                                                dangerouslySetInnerHTML={{ __html: memoizedStringContent }}
                                            />
                                        )}
                                    </SanitizeStringContentWrapper>
                                ) : (
                                    <Tag type="availability-bullet" subType="availability-bullet--in-stock">
                                        {stockInStoreLabel}
                                    </Tag>
                                )}
                                {renderStoreDetailTooltipIcon()}
                            </div>
                            {!isShippingPostalCodeHide && renderAisleLabel()}
                        </>
                    ) : (
                        <div className={`${componentClassName}__display-out-of-stock-warning`}>
                            <div
                                className={`${PREFIX}-warning-toast ${PREFIX}-warning-toast__white`}
                                role="alert"
                                aria-atomic="true">
                                <div className={`${PREFIX}-warning-toast__text-container`}>
                                    <span className={`${PREFIX}-warning-toast__icon`}>
                                        <Icon type="ct-warning-orange" size="lg" />
                                    </span>
                                    <div>
                                        <p className={`${PREFIX}-warning-toast__label`}>{outOfStockLabel}</p>
                                        {renderStoreDetailTooltipIcon()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Button
                    modifierClass={`${componentClassName}__check-other-stores-btn`}
                    id={checkNearbyStoresButtonDataTestId}
                    type="tertiary"
                    onClick={(e: React.MouseEvent) => checkNearbyStoreCTAHandler(e)}>
                    {checkOtherStoresLabel}
                </Button>
                {rendersTooltipComp(
                    tooltipvisibility,
                    setTooltipVisibility,
                    preferredStoreDetails?.displayName,
                    <StoreTooltip
                        storeData={preferredStoreDetails}
                        openLabel={openLabel}
                        opensLabel={openingAtLabel}
                        closesLabel={closesAtLabel}
                        closeLabel={closeLabel}
                        storeDetailsCTALabel={storeDetailsCTALabel}
                        onlineOrdering={preferredStoreDetails.onlineOrdering}
                        isPDPPage={isPDPPage || isInMiniPDPFlyout}
                    />,
                    closeIconLabel,
                    buttonRef,
                    isModalPresent,
                )}
                {rendersStoreFlyoutComp()}
            </div>
        )
    }
    return <>{renderFindInStore()}</>
}

FindInStore.propTypes = {
    alsoFindItInStoreHeader: string,
    storeName: string,
    checkOtherStoresLabel: string,
    isShowNotInStock: bool,
    isNearbyStoreListApiDone: bool,
    availableOnlinePurchaseLabel: string,
    isInStockOnline: bool,
    a11yStoreInfoTooltip: string,
}

export default FindInStore
