import { wishListError } from '../../redux/models/wishlist.interface'

// This file contains ProductCard constants
export const constantValues = {
    currentPageInitialValue: 1,
    incrementValue: 1,
    positionIncrementValue: 1,
    minPrice: 'minPrice',
    pageToFetchValues: 2,
    plpUrl: 'loc=plp',
    packageFlowParamValue: 'fromPackage',
    productOptionLength: 1,
    initialSkeletonItems: 10,
    minSkuLength: 1,
    minTireWheelSizeOptionsForFilterDisplaying: 2,
    productPerPageForMobileAndTabletViewport: 24,
    productPerPageForDesktopViewport: 40,
} as const

export const addToCartSuccessMsg = 'addToCartSuccessMsg'
export const errorRichTextId = 'no-match-title'
export const errorDescriptionId = 'no-match-description'
export const errorScreenClearCTAId = 'error-screen-clear-filter-cta'

export const wishlistErrorPayload: wishListError = {
    code: 0,
    errorCode: '0',
    message: '',
    success: true,
    type: '',
}
